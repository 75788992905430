.select-overwrites-1{
  .crs{
    &__placeholder{
      @include ptype(-1);
      color: $grey-40;
      margin: 0;
    }
    &__control{
      background-color:$black;
      border: 1px solid $grey-80;
      height: 48px;
      box-shadow: none;
      //border:none;
      border-radius: 4px;
      min-height:auto;
      padding:0;
      &.crs__control--menu-is-open{
        border: 1px solid $accent-2;
      }
    }
    &__indicator{
      padding: 0;
      padding-right: 4px;
      // border: 1px solid $grey-80;
      // border-left: none;
      svg{
        margin-right: inherit;
        width: 32px;
        height: 32px;
        path{
          stroke:$white;
        }
      }
      &-separator{
        margin: 12px 0;
      }
    }
    &__clear-indicator{
      svg{
        path{
          fill:none;
          &:first-child{
            display: none;
          }
        }
      }
    }
    &__single-value{
      color: $white;
    }
    &__value-container{
      height: 48px;
      width: 100%;
      padding-left: 16px;
      border-radius: 4px 0 0 4px;
      // border: 1px solid $grey-80;
      // border-right: none;
      &--has-value{
        margin: -1px 0 0 -1px;
        @include ptype(-1);
        background: $accent-3;
        + .crs__indicators{
          margin: -1px -1px 0 0;
          background: $accent-3;
          border-radius:0 4px 4px 0 ;
        }
      }

      &--is-multi{
        >div {
          color: white;
        }

        > .crs__placeholder{
          color: $grey-40;
          margin: 0;
        }
      }
    }
    &__menu{
      margin: 3px 0 0;
      border: 1px solid $grey-80;
    	border-radius: 4px;
			padding: 0;
			background: $black;
      width: 100%;
      color: white;
      // @include vertical-scrollbar("auto");
      // &::-webkit-scrollbar-track {
      //   background-color:$grey-75;
      // }
      // &-list{
      //   padding: 0;
      // }
      .option-list{
        background: $black !important; 
        padding: 0;
        border-radius: 3px;
        border: none;
        @include vertical-scrollbar(auto);
        > div + div{
          border-top-color:$grey-70;
        }

        .single-option{
          background: $black;
          border: none;
          border-bottom: 1px solid $grey-70;
          height: 40px;
          display: flex;
          align-items: center;
          padding: 0 16px;
          @include ptype(-1);
          &:hover {
            background: $grey-90;
            background-color: $grey-90;
            border-color: transparent;
            border-image: $grad-good;
            border-image-slice: 1;
          }
          
          &.selected {
            background: $grad-good;
            fill: white;
            stroke: none;

            &:hover {
              background: $grad-good;
              border-color: $grey-70;
              border-image: none;
            }
          }
    
          &:last-of-type() {
            border-bottom: none;
          }
        }
      }
    }
    
  }
  // &.filter{
  //   .single-option{
  //     padding:11px 16px;
  //     .label{
  //       @include ptype;
  //     }
  //     &.selected{
  //       background-color: $black;
  //     }
  //   }
  //   .filter-buttons{
  //     button{
  //       color:$white;
  //       border-radius: 8px;
  //       @include ptype;
  //       cursor: pointer;
  //       &:hover{
  //         background-color: $grey-85;
  //       }
  //       + button{
  //         background: $grad-good;
  //         &:hover{
  //           background: $grad-btn;
  //         }
  //       }
  //     }
  //   }
  // }
}