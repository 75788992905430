&.registered-user{
  .success-header{
    display: flex;
    img{
      margin-right: 8px;
    } 
  }

  .header-section{
    padding:24px;
    .heading-5{
      @include etype(3);
    }
  }

  .with-table .row > [class^="col-"]{
    align-items: flex-start
  }

  .card-type{
    .heading-5{
      @include etype(3);
    }
    .content{
      p{
        @include ptype-1("base");
        font-size: 14px;
        line-height: 18px;
        &:last-child{
          margin-bottom: 0;
        }
        strong{
          @include ptype-1("base-strong");
          font-size: 14px;
          line-height: 18px;
        }
      }
      a{
        color: $white;
        &:hover{
          color: $white;
          text-decoration: underline;
        }
      }
    }
    &.card-2{
      justify-content: center;
      padding-top: 64px;
      padding-bottom: 64px;
    }

    .top-line{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .date{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 0;
        > span{
          &:first-child{
            color: $white;
          }
          + span{
            margin-top: 2px;
          }
        }
      }
    }

    .top-section{
      padding: 24px 24px 16px;
      border-bottom: 1px dashed $grey-75;
      min-height: 135px;
    }
    .middle{
      &-1,
      &-2{
        padding:16px 24px;
        display: flex;
        flex-direction: column;
        min-height: 97px;
        .subtitle{
          font-weight: 700;
        }
        .tag{
          margin-top: 4px;
        }
      }
      &-1{
        margin-bottom: auto;
        font-size: 16px;
        line-height: 19px;

        .subtitle{
          margin-bottom: 8px;
        }
        .tranzaction{
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .right{
            >div:not(.tag){
              text-align: right;
              padding-right: 2px;
              margin-right: 0;
            }
          }
        }
      }
      &-2{
        background-color: $black;
        .name{
          display: flex;
          align-items: center;
          .initials{
            margin-right: 8px;
            margin-top: 10px;
          }
        }
      }
    }
    &.order,
    &.invoice{
      justify-content: space-between;
      .heading-5{
        @include etype(2);
      }
    }
    &.invoice{
      .middle{
        &-1{
          .price-vat{
            span{
              + span{
                color:#999999;
              }
            }
          }
        }
        &-2{
          >.content{
            display: flex;
            align-items: center;
            margin-top: 17px;
          }
          svg{
            margin-right: 4px;
            path{
              stroke:$white;
            }
          }
        }
      }
    }
  }

  .table-container{
    margin-bottom: 2rem;
  }
}

.capsule{
  background:$grey-75;
  height: 35px;
  display: flex;
  border-radius: 17px;
  padding:1px;
  >div{
    height: 33px;
    border-radius: 15px;
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0 16px;
    @include ptype-1("medium")
  }
  &.secondary{
    background: $grad-good;
  }
}