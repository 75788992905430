$borderRadius:2px;
$Grotesque          : 'Basis Grotesque Pro';
$prometo            : 'prometo',sans-serif; 

$ptypeFontSizes : (
  -4 : 8px,
  -3 : 10px,
  -2 : 12px,
  -1 : 14px,
  0 : 16px,
  1 : 20px,
  2 : 24px,
  3 : 28px,
  4 : 32px,
);
$etypeFontSizes : (

  1 : 20px,
  2 : 24px,
  3 : 28px,
  4 : 32px,
  5 : 32px,
  6 : 32px,
  7 : 32px,
  8 : 32px,
  9 : 32px,
  10 : 32px,
);
@mixin fontWeights($variant:"regular") {
  @if $variant == "light" {
    font-weight: 300;
  } @else if $variant == "regular" {
    font-weight: 400;
  } @else if $variant == "mono" {
    font-family: Basis Grotesque Mono Pro;
  }  @else if $variant == "italic" {
    font-style: italic;
  } @else if $variant == "medium" {
    font-weight: 500;
  } @else if $variant == "bold" {
    font-weight: 700;
  } @else if $variant == "strong" {
    font-weight: 700;
  } @else if $variant == "base-strong" {
    font-weight: 700;
  } @else if $variant == "black" {
    font-weight: 900;
  } @else if $variant == "base" {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  } @else {
    @error "Use variant:['light', 'mono' ,'italic' ,regular','medium','strong','bold','black','base'], not: " + $variant;
  }
}

@mixin vertical-scrollbar($auto:auto) {
  &::-webkit-scrollbar {
    width:4px;
    border-radius:2px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: $black;
    border-radius:2px;
  } 
  &::-webkit-scrollbar-thumb {
    background-color: #333333;
    outline: none;
    border-radius:2px;
    cursor: drag;
  }
  overflow-x: hidden;
  @if $auto == auto {
    overflow-y: auto;
  } @else {
    overflow-y: scroll;
  }
}

@mixin vertical-scrollbar-colored($auto:auto, $color:$grad-btn) {
  &::-webkit-scrollbar {
    width:4px;
    border-radius:2px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: $grey-80;
    border-radius:2px;
  } 
  &::-webkit-scrollbar-thumb {
    background: $color;
    outline: none;
    border-radius:2px;
    cursor: drag;
  }
  overflow-x: hidden;
  @if $auto == auto {
    overflow-y: auto;
  } @else {
    overflow-y: scroll;
  }
}

@mixin horizontal-scrollbar($auto:auto) {
  &::-webkit-scrollbar {
    height: 8px;
    border-radius:4px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: $black;
    border-radius:4px;
  } 
  &::-webkit-scrollbar-thumb {
    background-color: $grey-75;
    outline: none;
    border-radius:4px;
  }
  overflow-y: hidden;
  @if $auto == auto {
    overflow-x: auto;
  } @else {
    overflow-x: scroll;
  }
}


@mixin horizontal-scrollbar-purple($auto:auto, $color:grad-btn) {
  &::-webkit-scrollbar {
    height: 8px;
    border-radius:4px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: $grey-80;
    border-radius:4px;
  } 
  &::-webkit-scrollbar-thumb {
    @if $color == grad-good {
      background: $grad-good;
    } @else {
      background: linear-gradient(90deg, #B010FF 0%, #F537B9 100%);
    }
    outline: none;
    border-radius:4px;
  }
  overflow-y: hidden;
  @if $auto == auto {
    overflow-x: auto;
  } @else {
    overflow-x: scroll;
  }
}

@mixin main-transition {
  transition: all 0.25s ease-in-out;
}

// Ptypes
@mixin ptype-core{
  font-family: $Grotesque;
  // font-size: 16px;
  font-style:normal;
  // line-height: 20px;
  letter-spacing: 0em;
}

@mixin ptype($size:0, $variant:"regular"){
  @include ptype-core;
  font-size: map-get($ptypeFontSizes, $size);
  @include fontWeights(#{$variant});
}


// Color : your color code
// Variant : light, bold, black, medium,regular
// Style: normal,italic
@mixin ptype-1-core($style:"normal"){
  font-family: $Grotesque;
  font-size: 14px;
  font-style: $style;
  line-height: 17px;
  letter-spacing: 0em;
}

@mixin ptype-1($variant,$style:"normal"){
  @include ptype-1-core;
  @if $variant == "light" {
    font-weight: 300;
  } @else if $variant == "regular" {
    font-weight: 400;
  }  @else if $variant == "medium" {
    font-weight: 500;
  } @else if $variant == "bold" {
    font-weight: 700;
  } @else if $variant == "strong" {
    font-weight: 700;
  } @else if $variant == "black" {
    font-weight: 900;
  } @else if $variant == "base" {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  } @else if $variant == "base-strong" {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  } @else {
    @error "Use variant:['light',regular','medium','strong','bold','black','base','base-strong']";
  }
}




@mixin ptype-2-core{
  font-family: $Grotesque;
  font-size: 12px;
  font-style:"normal";
  line-height: 14px;
  letter-spacing: 0em;
}

@mixin ptype-2($variant){
  @include ptype-2-core;
  @if $variant == "base" {
    font-weight: 400;
  } @else if $variant == "base-strong" {
    font-weight: 700;
  } @else {
    @error "Use variant:['base','base-strong']";
  }
}





// Etypes
@mixin etype-core{
  font-family: $prometo;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}

@mixin etype($variant){
  @include etype-core;
  @if $variant == 1 {
    font-size: 16px;
    line-height: 19px;
  } @else if $variant == 2 {
    font-size: 20px;
    line-height: 24px;
  } @else if $variant == 3 {
    font-size: 24px;
    line-height: 29px;
  } @else if $variant == 4 {
    font-size: 28px;
    line-height: 32px;
  } @else if $variant == 5 {
    font-size: 32px;
    line-height: 36px;
  } @else if $variant == 6 {
    font-size: 38px;
    line-height: 42px;
  } @else if $variant == 7 {
    font-size: 44px;
    line-height: 48px;
  } @else if $variant == 8 {
    font-size: 50px;
    line-height: 52px;
  } @else if $variant == 9 {
    font-size: 56px;
    line-height: 59px;
  } @else if $variant == 10 {
    font-size: 64px;
    line-height: 64px;
  } @else {
    @error "Use variant:[1,2,3,4,5,6,7,8,9,10]";
  }
}

@mixin placeholder($color:white,$opacity:1){
  &::-webkit-input-placeholder {
    color: $color;
    opacity:$opacity;
  }
  &::-moz-placeholder {
    color:$color;
    opacity:$opacity;
  }
}

@mixin truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin line-clamp($font-size,$line-height,$lines-to-show) {
  display: block; // Fallback for non-webkit browsers
  display: -webkit-box;
  font-size: $font-size;
  max-height: calc(#{$font-size} * #{$line-height} * #{$lines-to-show}); // Fallback for non-webkit browsers
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
}

@mixin ptype-1-plus-strong{
  font-family: $Grotesque;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}


@mixin responsiveFormGrid($field-width:300px) {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(#{$field-width},1fr));
  grid-gap: 24px;
  .check-box-switch{
    margin-top: 24px;
  }
  .full-line{
    grid-column: -1/1;
  }
  .first-col{
    grid-column: 1/2;
  }
}


@mixin circle($size) {
  width: $size;
  height: $size;
  flex: 0 0 $size; //this one is for safety, maybe you are in a flex container (that will ignore one of width / height)
  border-radius: 50%;
  line-height: $size;
  text-align: center;
}
