&.register-page{
  padding-right: 96px;
  background-position: calc(50vw - 12px) bottom;
  background-size: 34%;
  background-repeat: no-repeat;
  padding-bottom: $gutter*2;
  min-height: calc(100vh - 80px);
  @include max-sm{
    padding: 0 $gutter $gutter*2;
  }
  .header-section{
    padding:40px 0 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    .heading{
      &-1{
        @include etype(5);
      }
      &-2{
        font-family:$Grotesque;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
      }
      &-1,
      &-2{
        margin: 0 auto;
        display: flex;
        color:$white;
      }
    }
  }
  .container-fluid{
    max-width: 1140px;
    .row{
      margin-right:-$gutter;
      margin-left:-$gutter;
      width: calc(100% + #{$gutter*2});
      .col-12{
        padding: 0 12px;  
      }
    }
    .wrapper{
      @include max-sm{
        padding: 0;
      }
    }
    &.interest{
      max-width: 1080px;
      .wrapper{
        >p{
          font-family:  $Grotesque;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          margin: 0 0 8px;
        }
        .info-group{
          display: flex;
          flex-direction: row;
          margin:0 0 $gutter*2;
          color: #B3B3B3;
          svg{
            margin-right: 4px;
          }
          p{
            display: flex;
            margin: 0;
            font-family: $Grotesque;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            min-height: 16px;
          }
        }
      }
    }
  }
  .card.register-content{
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    &.new-pin{
      max-width: 456px;

      &.success {
        border: 1px solid #24A148;
      } 
      &.error {
        border: 1px solid #DA1E28;
      }

      .step {
        width: 16px; 
        height: 16px;
        border-radius: 50%;
        background: #4D4D4D;
        &.active {
          background: linear-gradient(265.67deg, #4A7DDF -1.98%, #49B595 99.58%);
        }
        &.success {
          background: #24A148;
        }
        &.error {
          background: #DA1E28;
        }      
      }
    }
    .heading{
      &-3{
        display: flex;
        align-items: center;
        justify-content: center;
        @include ptype(0,"base");
        margin: 0 0 $gutter*2;
        &.strong{
          @include etype(2);
          margin: 0 0 40px;
        }
        &.text-left{
          justify-content:flex-start;
        }
      }
    }
    .magic-input{
      // margin-bottom: $gutter*2;
    }
    .separator{
      height: 1px;
      margin-bottom: 20px;
    }
    .pin-text{
      margin:28px auto 16px;
      max-width: 400px;
      @include ptype(0,"base");
        a{
          font-family: "Basis Grotesque Pro";
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
          color:#6DB4C4;
        }
    }

    +.btn-holder{
      &.btn-center{
        .btn{
          max-width: 456px;
          min-height: 64px;
          >.left{
            height: 62px;
            @include etype(2);
          }
          &.disabled{
            opacity: 1;
            background: $grey-65;
            >.left{
              background: $grey-65;
            }
          }
        }
        &.with-timer{
          padding-bottom: 74px;
          .btn{
            margin-bottom: 16px;
          }
          p{
            margin: 0 0 16px;
          }
        }
      }
      
    }
    .custom-check-slide{
      &:not(.slide){
        margin-left: 6px;
        margin-right: 22px;
      }
      .slide{
        margin-right: 12px;
      }
      
    }
  }
  .btn-holder{
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    width: 100%;
    &.btn-center{
      justify-content: center;
      align-items: center;
      .btn{
        margin-right: auto;
        margin-bottom: 0;
        margin-left: auto;
        width: 100%;
        max-width: 484px;
        >.left{
          width: 100%;
        }
      }
    }
  }
  .skip{
    font-family: $Grotesque;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: $gutter;
    strong{
      cursor: pointer;
      font-weight: 700;
      color:$accent-3;
    }
  }
  .user-pass-wrap{
    max-width: 532px;
    margin: auto;
    .register-confirm{
      background: $grad-good;
      margin-bottom: $gutter*2;
      padding: 40px;
      align-items: center;
      justify-content: center; 
      flex-direction: column; 
      justify-content: center;  
      display: flex;
      .wrapper{
        align-items: center;
        justify-content: center; 
        flex-direction: column; 
        justify-content: center;  
        display: flex;
      }
      svg{
        margin: 0 auto 8px;
      }
      .heading-2{
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }
      p{
        font-family: $Grotesque;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: center;
        margin: $gutter 0 0;
        max-width: 320px;
      }
    }
    &.account{
      max-width: 660px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .account-wrapper{
        display: flex;
        flex-direction: column;
        max-width: 456px;
        align-items: center;
        .img-wrapper{
          width: 456px;
          height: 480px;
          margin-bottom: 24px;
          img {
            width: inherit;
            height: inherit;
            object-fit: cover;
            border-radius: 8px;
          }
        }
        p{
          margin-bottom: 40px;
        }
        .btn-holder{
          margin-bottom: 40px;
        }
        .dash{
          margin-bottom: 40px;
          color:$accent-3;
        }
      }
    }
  }
  .job-wrap{
    .heading-1{
      text-align: center;
    }
  }
  .voucher-section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 105px);
    padding-bottom: $gutter*4;
    @include min-sm{
      padding-bottom: 100px;
    }
    >div{
      display: flex;
      flex-direction: column;
    }
    .card.add-voucher{
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
      
      .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

       
      }
      &.valid{
        background: $grad-good;
        .input-info{
          margin-bottom:0;
          .magic-input{
            .label{
              display: none;
            }
          }
        }
      }
      &.error{
        .input-info{
          .magic-input{
            .label{
              color:$alert;
            }
            .inputContainer{
              border-color: $alert;
            }
          }
        }
      }
      .wrapper{   
        .title{
          display: flex;

          // margin: 0 0 20px;
          svg{
            margin-right:10px 
          }
          .heading-4{
            @include etype(3);
            margin: 0;
          }
        }
        .input-info{
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          position: relative;
          .info-icon-wrapper{
            position: absolute;
            z-index: 3;
            bottom:14px;
            right:16px;
          }
        }
      }
      &.login-input{
        margin-bottom: 40px;
        max-width: 532px;
        &.reset-password-email-code{
          max-width: 640px;
        }
      &.pin-input {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
        .pass-form-wrapper .pass-wrap{
          justify-content: flex-start;
          align-items: 'center';
          align-items: flex-start;
          padding:0 $gutter;
        }


      }
    }
    .create-section{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: $gutter*4;
      .heading-2{
        @include etype(3);
        margin: 0 0 20px;
      }
    }
    &.login{
      .magic-input{
        .label{
          @include ptype(0,"strong");
          margin-bottom: $gutter;
        }
      }
      .label-line{
        .label{
          margin-top:$gutter*2;
          text-align: center;
          @include ptype(0,"strong");
        }
      }
      .btn-holder{
        margin-bottom: 27px;
        .btn{
          width: 100%;
          max-width: 532px;
          height: 64px;
          @include etype(2);


          > div{
            height: 64px;
          }
          &.disabled{
            background: $grey-65;
            opacity: 1;
            >div{
              background: $grey-65;
            }
          }
        }
      }
      .last-content{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .create-account{
          width: 100%;
          max-width: 532px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          font-family: $Grotesque ;
          font-size: 16px;
          font-weight: 350;
          line-height: 19px;
          a{
            font-weight: 700;
            display: inline-flex;
            margin: 0 8px;
            color: $white;
          }

        }
      }
    }
  }
  .digit-wrap{
    max-width: 684px;
    margin-right: auto;
    margin-left: auto;
    .card{
      &.pass-digit-content{
        padding-top: 16px;
        .wrapper{
          p{
            max-width: 200px;
            text-align: center;
            margin: 0 auto $gutter*2 auto;
          }
          .message-bottom{
            color: #fff8;
            cursor: default;
            font-weight: 400;
            strong{
              cursor: pointer;
              color: $white;
              font-weight: 700;
            }
          }
          .resend{
            margin: $gutter*2 0 0;
            &.btn-link{
              min-height: auto;
              height: auto;
              .left{
                padding: 0;
                min-height: auto;
                min-width:auto;
              }
              
            }
          }
        }
      }
    }
    
  }


  .card{
    &.card-interests{
      margin-bottom: 40px;
      .heading-3{
        @include etype(2);
        color: $white;
        margin-bottom:16px;
      }
    }
  }
  .info-group{
    background-size: 12px;
    background-repeat: repeat;
    padding: 1px;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 0 $gutter*2;
    >.inner{
      background:$black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      .left{
        display: flex;
        svg{
          margin-right: 12px;
        }
      }
      p{
        margin: 0;
        @include ptype(0,"base");
      }
    }
  }
  .interest-legend{
    .title{
      @include ptype(0,"base");
      color:$white;
      display: flex;
      align-items: center;
      svg{
        margin-right: 8px;
      }
    }
    .legend-line{
      display:flex;
      flex-wrap: wrap;
      flex-direction: row;
      .legend{
        &:not(:last-child){
          margin-right: 30px;
        }
        display: flex;
        flex-direction: column;
        .dots{
          display: flex;
          >span{
            width: 8px;
            height: 8px;
            display: inline-flex;
            margin:10px 4px 6px 0;
            border-radius: 50%;
            background: #fff2;
          }
        }
        .label{
          @include ptype(0,"base");
          color:#C2C7CC;
        }
        &.l{
          &-1{
            .dots >span:first-child{
              background: $white;  
            }
          }
          &-2{
            .dots >span:first-child{
              background: $white; 
              + span{
                background: $white;
              } 
            }
          }
          &-3{
            .dots > span{
              background: $white; 
            }
          }
        }
      }

    }
  }
  // .btn{
  //   &.large{
  //     margin-bottom: $gutter*2;
  //     max-width: 456px;
  //     height: auto;
  //     >div{
  //       min-height: 62px;
  //       @include etype(2);

  //     }
  //   }
  // }
  .reset-password{
    display: inline-flex;
    margin: $gutter*2 auto 0;
    color:$white;
  }
}
