
// Media-UP 

// Used mixins - just here !!!
// don't use a mixin for only one line! type it in!
@mixin df{ display: flex !important;}
@mixin dif{ display: inline-flex !important;}
@mixin db{ display: block !important;}
@mixin dib{ display: inline-block !important;}
@mixin dn{ display: none !important;}

@mixin f-wrap{ flex-wrap: wrap !important; }
@mixin f-nowrap{ flex-wrap: nowrap !important; }

@mixin frow{ flex-direction: row !important;}
@mixin fcol{ flex-direction: column !important;}

@mixin jc-start{ justify-content: flex-start!important;}
@mixin jc-center{ justify-content: center !important;}
@mixin jc-end{ justify-content: flex-end !important;}
@mixin jc-between{ justify-content: space-between !important;}
@mixin jc-around{ justify-content: space-around !important;}

@mixin ai-start{ align-items: flex-start !important;}
@mixin ai-center{ align-items: center !important;}
@mixin ai-end{ align-items: flex-end !important;}
@mixin ai-stretch{ align-items: stretch !important;}
@mixin ai-baseline{ align-items: baseline !important;}

@mixin as-start{ align-self: flex-start !important;}
@mixin as-center{ align-self: center !important;}
@mixin as-end{ align-self: flex-end !important;}
@mixin as-stretch{ align-self: stretch !important;}
@mixin as-baseline{ align-self: baseline !important;}


.df{ display: flex !important;}
.dif{ display: inline-flex !important;}
.db{ display: block !important;}
.dib{ display: inline-block !important;}
.dn{ display: none !important;}

.f-wrap{ flex-wrap: wrap !important; }
.f-nowrap{ flex-wrap: nowrap !important; }

.frow{ flex-direction: row !important;}
.fcol{ flex-direction: column !important;}

.jc-start{ justify-content: flex-start!important;}
.jc-center{ justify-content: center !important;}
.jc-end{ justify-content: flex-end !important;}
.jc-between{ justify-content: space-between !important;}
.jc-around{ justify-content: space-around !important;}

.ai-start{ align-items: flex-start !important;}
.ai-center{ align-items: center !important;}
.ai-end{ align-items: flex-end !important;}
.ai-stretch{ align-items: stretch !important;}
.ai-baseline{ align-items: baseline !important;}

.as-start{ align-self: flex-start !important;}
.as-center{ align-self: center !important;}
.as-end{ align-self: flex-end !important;}
.as-stretch{ align-self: stretch !important;}
.as-baseline{ align-self: baseline !important;}


// Displays
.d{
  &-flex{
    @include df;
    @include min-sm{ &-sm { @include df; } }
    @include min-md{ &-md { @include df; } }
    @include min-lg{ &-lg { @include df; } }
    @include min-xl{ &-xl { @include df; } }
    /* 
    Renders:
    [ .d-flex, d-flex-sm, d-flex-md, d-flex-lg, d-flex-xl] 
    */
  }
  &-inline-flex{
    @include dif;
    @include min-sm{ &-sm { @include dif; } }
    @include min-md{ &-md { @include dif; } }
    @include min-lg{ &-lg { @include dif; } }
    @include min-xl{ &-xl { @include dif; } }
    /* 
    Renders:
    [ .d-inline-flex, d-inline-flex-sm, d-inline-flex-md, d-inline-flex-lg, d-inline-flex-xl] 
    */
  }
  &-block{
    @include db;
    @include min-sm{ &-sm { @include db; } }
    @include min-md{ &-md { @include db; } }
    @include min-lg{ &-lg { @include db; } }
    @include min-xl{ &-xl { @include db; } }
    /* 
    Renders:
    [ .d-block, d-block-sm, d-block-md, d-block-lg, d-block-xl] 
    */
  }
  &-inline-block{
    @include dib;
    @include min-sm{ &-sm { @include dib; } }
    @include min-md{ &-md { @include dib; } }
    @include min-lg{ &-lg { @include dib; } }
    @include min-xl{ &-xl { @include dib; } }
    /* 
    Renders:
    [ .d-inline-block, d-inline-block-sm, d-inline-block-md, d-inline-block-lg, d-inline-block-xl] 
    */
  }
  &-none{
    @include dn;
    @include min-sm{ &-sm { @include dn; } }
    @include min-md{ &-md { @include dn; } }
    @include min-lg{ &-lg { @include dn; } }
    @include min-xl{ &-xl { @include dn; } }
    /* 
    Renders:
    [ .d-none, d-none-sm, d-none-md, d-none-lg, d-none-xl] 
    */
  }
}

// Alignment
.flex{
  &-row{
    @include frow;
    @include min-sm{ &-sm { @include frow; } }
    @include min-md{ &-md { @include frow; } }
    @include min-lg{ &-lg { @include frow; } }
    @include min-xl{ &-xl { @include frow; } }
    /* 
    Renders:
    [ .flex-row, flex-row-sm, flex-row-md, flex-row-lg, flex-row-xl] 
    */
  }
  &-column{
    @include fcol;
    @include min-sm{ &-sm { @include fcol; } }
    @include min-md{ &-md { @include fcol; } }
    @include min-lg{ &-lg { @include fcol; } }
    @include min-xl{ &-xl { @include fcol; } }
    /* 
    Renders:
    [ .flex-column, flex-column-sm, flex-column-md, flex-column-lg, flex-column-xl] 
    */
  }
  &-center{
    @include jc-center;@include ai-center;
    @include min-sm{ &-sm { @include jc-center; @include ai-center;}}
    @include min-md{ &-md { @include jc-center; @include ai-center;}}
    @include min-lg{ &-lg { @include jc-center; @include ai-center;}}
    @include min-xl{ &-xl { @include jc-center; @include ai-center;}}
    /* 
    Renders:
    [ .flex-center, flex-center-sm, flex-center-md, flex-center-lg, flex-center-xl] 
    */
  }
  &-wrap{
    @include f-wrap;
    @include min-sm{ &-sm { @include f-wrap; }}
    @include min-md{ &-md { @include f-wrap; }}
    @include min-lg{ &-lg { @include f-wrap; }}
    @include min-xl{ &-xl { @include f-wrap; }}
    /* 
    Renders:
    [ .flex-wrap, flex-wrap-sm, flex-wrap-md, flex-wrap-lg, flex-wrap-xl] 
    */
  }
  &-nowrap{
    @include f-nowrap;
    @include min-sm{ &-sm { @include f-nowrap; }}
    @include min-md{ &-md { @include f-nowrap; }}
    @include min-lg{ &-lg { @include f-nowrap; }}
    @include min-xl{ &-xl { @include f-nowrap; }}
    /* 
    Renders:
    [ .flex-nowrap, flex-nowrap-sm, flex-nowrap-md, flex-nowrap-lg, flex-nowrap-xl] 
    */
  }
}

.justify-content{
  &-start{
    @include jc-start;
    @include min-sm{ &-sm { @include jc-start; }}
    @include min-md{ &-md { @include jc-start; }}
    @include min-lg{ &-lg { @include jc-start; }}
    @include min-xl{ &-xl { @include jc-start; }}
    /* 
    Renders:[ 
      justify-content-start, 
      justify-content-start-sm, 
      justify-content-start-md, 
      justify-content-start-lg, 
      justify-content-start-xl
    ] 
    */
  }
  &-center{
    @include jc-center;
    @include min-sm{ &-sm { @include jc-center; }}
    @include min-md{ &-md { @include jc-center; }}
    @include min-lg{ &-lg { @include jc-center; }}
    @include min-xl{ &-xl { @include jc-center; }}
    /* 
    Renders:[ 
      justify-content-center, 
      justify-content-center-sm, 
      justify-content-center-md, 
      justify-content-center-lg, 
      justify-content-center-xl
    ] 
    */
  }
  &-end{
    @include jc-end;
    @include min-sm{ &-sm { @include jc-end; }}
    @include min-md{ &-md { @include jc-end; }}
    @include min-lg{ &-lg { @include jc-end; }}
    @include min-xl{ &-xl { @include jc-end; }}
    /* 
    Renders:[ 
      justify-content-flex-end, 
      justify-content-flex-end-sm, 
      justify-content-flex-end-md, 
      justify-content-flex-end-lg, 
      justify-content-flex-end-xl
    ] 
    */
  }
  &-between{
    @include jc-between;
    @include min-sm{ &-sm { @include jc-between; }}
    @include min-md{ &-md { @include jc-between; }}
    @include min-lg{ &-lg { @include jc-between; }}
    @include min-xl{ &-xl { @include jc-between; }}
    /* 
    Renders:[ 
      justify-content-between, 
      justify-content-between-sm, 
      justify-content-between-md, 
      justify-content-between-lg, 
      justify-content-between-xl
    ] 
    */
  }
  &-around{
    @include jc-around;
    @include min-sm{ &-sm { @include jc-around; }}
    @include min-md{ &-md { @include jc-around; }}
    @include min-lg{ &-lg { @include jc-around; }}
    @include min-xl{ &-xl { @include jc-around; }}
    /* 
    Renders:[ 
      justify-content-around, 
      justify-content-around-sm, 
      justify-content-around-md, 
      justify-content-around-lg, 
      justify-content-around-xl
    ] 
    */
  }
}

.align-items{
  &-start{
    @include ai-start;
    @include min-sm{ &-sm { @include ai-start; }}
    @include min-md{ &-md { @include ai-start; }}
    @include min-lg{ &-lg { @include ai-start; }}
    @include min-xl{ &-xl { @include ai-start; }}
    /* 
    Renders:[ 
      align-items-start, 
      align-items-start-sm, 
      align-items-start-md, 
      align-items-start-lg, 
      align-items-start-xl
    ] 
    */
  }
  &-center{
    @include ai-center;
    @include min-sm{ &-sm { @include ai-center; }}
    @include min-md{ &-md { @include ai-center; }}
    @include min-lg{ &-lg { @include ai-center; }}
    @include min-xl{ &-xl { @include ai-center; }}
    /* 
    Renders:[ 
      align-items-center, 
      align-items-center-sm, 
      align-items-center-md, 
      align-items-center-lg, 
      align-items-center-xl
    ] 
    */
  }
  &-end{
    @include ai-end;
    @include min-sm{ &-sm { @include ai-end; }}
    @include min-md{ &-md { @include ai-end; }}
    @include min-lg{ &-lg { @include ai-end; }}
    @include min-xl{ &-xl { @include ai-end; }}
    /* 
    Renders:[ 
      align-items-end, 
      align-items-end-sm, 
      align-items-end-md, 
      align-items-end-lg, 
      align-items-end-xl
    ] 
    */
  }
  &-stretch{
    @include ai-stretch;
    @include min-sm{ &-sm { @include ai-stretch; }}
    @include min-md{ &-md { @include ai-stretch; }}
    @include min-lg{ &-lg { @include ai-stretch; }}
    @include min-xl{ &-xl { @include ai-stretch; }}
    /* 
    Renders:[ 
      align-items-stretch, 
      align-items-stretch-sm, 
      align-items-stretch-md, 
      align-items-stretch-lg, 
      align-items-stretch-xl
    ] 
    */
  }
  &-baseline{
    @include ai-baseline;
    @include min-sm{ &-sm { @include ai-baseline; }}
    @include min-md{ &-md { @include ai-baseline; }}
    @include min-lg{ &-lg { @include ai-baseline; }}
    @include min-xl{ &-xl { @include ai-baseline; }}
    /* 
    Renders:[ 
      align-items-baseline, 
      align-items-baseline-sm, 
      align-items-baseline-md, 
      align-items-baseline-lg, 
      align-items-baseline-xl
    ] 
    */
  }
}

.align-self{
  &-start{
    @include as-start;
    @include min-sm{ &-sm { @include as-start; }}
    @include min-md{ &-md { @include as-start; }}
    @include min-lg{ &-lg { @include as-start; }}
    @include min-xl{ &-xl { @include as-start; }}
    /* 
    Renders:[ 
      align-self-start, 
      align-self-start-sm, 
      align-self-start-md, 
      align-self-start-lg, 
      align-self-start-xl
    ] 
    */
  }
  &-center{
    @include as-center;
    @include min-sm{ &-sm { @include as-center; }}
    @include min-md{ &-md { @include as-center; }}
    @include min-lg{ &-lg { @include as-center; }}
    @include min-xl{ &-xl { @include as-center; }}
    /* 
    Renders:[ 
      align-self-center, 
      align-self-center-sm, 
      align-self-center-md, 
      align-self-center-lg, 
      align-self-center-xl
    ] 
    */
  }
  &-end{
    @include as-end;
    @include min-sm{ &-sm { @include as-end; }}
    @include min-md{ &-md { @include as-end; }}
    @include min-lg{ &-lg { @include as-end; }}
    @include min-xl{ &-xl { @include as-end; }}
    /* 
    Renders:[ 
      align-self-end, 
      align-self-end-sm, 
      align-self-end-md, 
      align-self-end-lg, 
      align-self-end-xl
    ] 
    */
  }
  &-stretch{
    @include as-stretch;
    @include min-sm{ &-sm { @include as-stretch; }}
    @include min-md{ &-md { @include as-stretch; }}
    @include min-lg{ &-lg { @include as-stretch; }}
    @include min-xl{ &-xl { @include as-stretch; }}
    /* 
    Renders:[ 
      align-self-stretch, 
      align-self-stretch-sm, 
      align-self-stretch-md, 
      align-self-stretch-lg, 
      align-self-stretch-xl
    ] 
    */
  }
  &-baseline{
    @include as-baseline;
    @include min-sm{ &-sm { @include as-baseline; }}
    @include min-md{ &-md { @include as-baseline; }}
    @include min-lg{ &-lg { @include as-baseline; }}
    @include min-xl{ &-xl { @include as-baseline; }}
    /* 
    Renders:[ 
      align-self-baseline, 
      align-self-baseline-sm, 
      align-self-baseline-md, 
      align-self-baseline-lg, 
      align-self-baseline-xl
    ] 
    */
  }
}

// Orders
$orderValues:1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20;
@each $order in $orderValues{ 
  .order-#{$order}{ 
    order:#{$order} !important;
    @include min-sm{ &-sm { order:#{$order} !important; }}
    @include min-md{ &-md { order:#{$order} !important; }}
    @include min-lg{ &-lg { order:#{$order} !important; }}
    @include min-xl{ &-xl { order:#{$order} !important; }}
  }
}