.leftmenu{
  background-color: $grey-85;
  width: 96px;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:not(.not-fixed){
    position: fixed;
    top:80px;
    left:0;
    bottom:0;
    z-index:5;
  }
  @include vertical-scrollbar(auto);
  transition: all 0.25s ease-out;
  transform: translateX(0);
  &.closed{
    transform: translateX(-100%);
  }
  .top,
  .bottom{
    display: flex;
    flex-direction: column;
    width: 96px;
    .navlink{
      background-color: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 96px;
      height: 96px;
      border:0;
      outline: none;
      color:$white;
      @include ptype-2("base-strong");
      transition:all 0.5s ease-out;
      cursor:pointer;
      &:hover{
        background:$black;
      }
      &.active{
        background: $grad-btn;
      }
      >svg{
        path{
          stroke: $white;
        }
      }
    }
  }
}