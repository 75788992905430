&.billing-page{
  position: relative;
  padding-bottom: 170px;
  @include min-lg{
    padding-right:560px;
    padding-bottom: 220px;
  }
  .header-section{
    &.billing-process{
      padding-top: 40px;
      padding-bottom: 20px;
      .heading{
        &-2{
          font-family:$Grotesque;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
        }
        &-5{
          @include etype(5);
          text-transform: uppercase;
          margin: 0 0 10px;
        }
      }
    }
  }
  .payment-content{
    margin-bottom: $gutter*2;
    .wrapper{
      @include max-sm{
        padding: 0;
      }
    }
    .custom-check-slide{
      &:not(.slide){
        margin-left: 6px;
        margin-right: 22px;
      }
      .slide{
        margin-right: 12px;
      }
    }
  }
  .heading{
    &-3{
      @include etype(3);
      margin: 0 0 $gutter*2;
    }
  }
  .billing-tab{
    &-head{
      margin-bottom: $gutter*2;
      >.container-fluid.no-gutters > .row{
        display: flex;
        align-items: center;
      }
      .login-block{
        display: flex;
        align-items: center;
        padding:0 8px;
        box-sizing: border-box;
        p{
          margin: 0;
        }
        .btn{
          &.btn{
            &-link{
              margin: 0;
              .left{
                padding: 0 8px;
                min-width: auto;
                color:$accent-3;
              } 
            }
          }
        }
      }
     
      .container-fluid{
        &.no-gutters{
          >.row{
            margin-right: -8px;
            margin-left: -8px;
            width: calc(100% + 16px);
          }
        }
      }
      .tab-head{
        padding: 0 8px !important;
        display: flex;
        flex-direction: column;
        .pay-btn{
          width: 100%;
          border:0;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 64px;
          border-radius: 8px;
          cursor: pointer;
          color: $white;
          padding: 0 $gutter*2;
          font-family:$Grotesque;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          svg{
            margin-right: 10px;
            path{
              stroke: $white;
            }
          }
        }
      }

      &.active{
        &-1{
          .tab-head{
            .pay-btn{
              background: $grad-good;
              border: 1px solid transparent;
            }
            ~ .tab-head{
              .pay-btn{
                background: $black;
                border: 1px solid $grey-75;
              }
            }
          }
        }
        &-2{
          .tab-head{
            .pay-btn{
              background: $black;
              border: 1px solid $grey-75;
            }
            + .tab-head{
              .pay-btn{
                background: $grad-good;
              }
            }
          }
        }
      }
    }
  }

  .form-wrapper{
    > .row{
      width: calc(100% + 16px);
      margin-left: -8px;
    }
    &.pins{
      > .row{
        width: 100%;
        margin-left: 0;
        .pass-form-wrapper .pass-wrap{
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .magic-input .inputContainer .form-control input{
      padding-right: 10px;
    }
    .custom-select-brand{
      margin-bottom: $gutter*2;
      padding: 0 8px;
      width: 100%;
      .custom-select-bm{
        width: 100%;
      }
    }
    
    .magic-input{
      margin-bottom: $gutter*2;
      padding: 0 8px;
    }
    + .data-company{
      > .row{
        margin:0 -7px;
        > [class^="col-"]{
          padding: 0;
        }
      }
      .magic-input{
        margin-bottom: $gutter*2;
        padding: 0 8px;
      }
    }
  }

  .separator{
    width: calc(100% + #{$gutter*4});
    height: 1px;
    position: relative;
    margin-top:$gutter*2;
    margin-left: -$gutter*2;
    margin-bottom: $gutter;
  }
  .pass-form-wrapper.new-ui .pass-wrap {
    justify-content: flex-start;
  }
}