.experience{
  flex-direction: column;
  width: 410px;
  margin-right: 1rem;
  padding:24px 0 40px;
  user-select: none;
  @media (max-width:520px) {
    width: 274px;
  }
  .heading-5{
    color:$white;
    @include ptype-1("base","normal");
    margin-bottom: 8px;
  }
  .exp-wrap{
    width: 410px;
    height: 256px;
    border-radius: 8px;
    overflow:hidden;
    background-size: cover;
    background-position: center;
    margin-bottom: $gutter;
    position: relative;
    @media (max-width:520px) {
      width: 274px;
      height:171px;
    }
    &::before{
      content:"";
      position: absolute;
      z-index: 1;
      top:0;
      left:0;
      bottom:0; 
      width: 240px;
      background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 97%);
      pointer-events: none;
      @media (max-width:520px) {
        width: 120px;
      }
    }
    &::after{
      content:"";
      position: absolute;
      z-index: 1;
      bottom:0;
      left:0;
      right:0; 
      height: 117px;
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 97%);
      pointer-events: none;
      @media (max-width:520px) {
        height: 60px;
      }
    }
    >div{
      width: 410px !important;
      height:calc( (410px / 16) * 10)!important;
      @media (max-width:520px) {
        width: 274px !important;
        height:calc( (274px / 16) * 10)!important;
      }
      .react-player__preview{
        background-size: cover !important;
        background-position: center;
        svg{
          pointer-events: none;
        }
      }
    }
    .btn{
      margin-right: auto;
    }
  }
}