.btn-interest{
  &:not(.disabled)  {
    cursor: pointer;
  }

  user-select: none;  
  width: auto;
  height: 40px;
  display:inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px 2px;
  border-radius: 120px;
  background: #4D4D4D;
  
  border: 1px solid $white;
  color: $grey-30;
  @include ptype(0,"strong");
  line-height:1;
  transition: all 0.25s ease-out;
  &.int{
    &-1,
    &-2,
    &-3{
      background: $interest-na;
      border-color: $grey-50;
      color: $white;
    }
    &-1{
      .dots{
        >span{
          background: $white;
          ~ span{
            background: rgba(255,255,255,0.2);
          }
        }
      }
    }
    &-2{
      .dots{
        >span{
          background: $white;
          + span{
            background: $white;
            + span{
              background: rgba(255,255,255,0.2);
            }
          }
        }
      }
    }
    &-3{
      .dots{
        >span{
          background: $white;
        }
      }
    }
  }
  &.matched {
    background: $grad-secondary;
  }
  .dots{
    display: inline-flex;
    margin-left: 13px;
    transform: translateY(1px);
    > span{ 
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;
      background: rgba(255,255,255,0.2);
      &:last-child{
        margin-right: 0;
      }
    }
  }
}