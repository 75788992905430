.progress{
  width: 100%;
  height: 4px;
  display: flex;
  background-color: $black;
  border-radius: 2px;

  .bar{
    height: 4px;
    width: 0;
    display: flex;
    border-radius: 2px;
    background-color: $alert;
    &.purple{
      background: $grad-btn;
    }
  }
  &.h8{
    height: 8px;
    border-radius: 4px;
    .bar{
      height: 8px;
      border-radius: 4px;
    }
  }
}