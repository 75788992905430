.single-slide{
	background-size:cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: all 0s ease-out;
	min-height: 768px;
	position: relative;
	color:$white;
	padding: 40px;
	width: 100%;
	border-radius: 40px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	&::before,
	&::after{
		content:"";
		position:absolute;
		left:0;
		right:0;
		z-index: 1;
	}
	&::before{
		top:0;
		background: linear-gradient(189deg, #171B3A 6%, rgba(23,27,58,0) 55%);
		height: 380px;
	}
	&::after{
		bottom:0;
		background: linear-gradient(10.75deg, rgba(0,0,0,.7) 45%, rgba(0,0,0,0) 94%);
		height: 100%;
	}

	&.center-bottom{
		justify-content: flex-end;
		.content-wrap{
			justify-content: center;
			align-items: center;
			.main-title{
				text-align: center;
			}
			.subtitle{
				&-1,&-2{
					justify-content: center;
				}
			}
			.action-buttons{
				display: flex;
				justify-content: center;
				flex-wrap:wrap;
				.bm-btn{
					margin: 0 12px 12px;
					.inner{
						min-width: 236px;
					}	
				}
			}
		}
	}


	.content-wrap{
		margin-bottom: 48px;
		position: relative;
		z-index: 3;
		.subtitle{
			&-1,
			&-2{
				display: flex;
				font-family: $Grotesque;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0em;
				margin: 0 0 40px;
			}
		}
		.main-title{
			@include etype(8);
			margin: 0 0 8px;
		}
		.bm-btn{
			width: auto;
			max-width: 300px;
			margin: 0 0 inherit;
			margin-right: auto;
		}
	}


}