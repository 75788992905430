.animated-logo{
	width: 243px;
	height: 48px;
	display: flex;
	position: relative;
	svg{
		width: 107px;
		height: 47px;
		min-width: 107px;
	}
	.text{
		display: flex;
		position: relative;
		overflow: hidden;
		width: 136px;
		.bm-line{
			position: absolute;
			left:10px;
			top:50%;
			width: 136px;
			overflow: hidden;
			text-transform: uppercase;
			flex-direction: column;
			display: flex;
			&.group{
				&-1,
				&-2{
					font-family:$prometo;
					font-size: 0.8rem;
					line-height: 14px;
					transition: all 0.5s ease-out;
				}
				&-1{
					transform: translate(0,-50%);
					animation:10s linear 10s infinite one-in;
					span{
						&.one{
							animation:10s linear 10s infinite slip-in1;
							transform: translateX(0);
						}
						&.two{
							animation:10s linear 10s infinite slip-in2;
							//transform: translateX(-146px);
						}		
					}
				}
				&-2{
					transform: translate(-146px,-50%);
					animation:10s linear 10s infinite two-in;
					span{
						&.one{

						}
						&.two{

						}
					}
				}
			}
		}
	}

}

@keyframes one-in{
	0% {
		transform: translate(0,-50%);
	}
	27% {
		transform: translate(0,-50%);
	}
	30% {
		transform: translate(-146px,-50%);
	}
	67% {
		transform: translate(-146px,-50%);
	}
	70% {
		transform: translate(0,-50%);
	}
	100% {
		transform: translate(0,-50%);
	}
}


// @keyframes slip-in1{
// 	0% {
// 		transform: translate(-146px);
// 	}
// 	5% {
// 		transform: translate(0);
// 	}
// 	10% {
// 		transform: translate(0);
// 	}
// 	97% {
// 		transform: translate(0);
// 	}
// 	100% {
// 		transform: translate(-146px);
// 	}
// }


// @keyframes slip-in2{
// 	0% {
// 		transform: translate(-146px);
// 	}
// 	5% {
// 		transform: translate(0);
// 	}
// 	10% {
// 		transform: translate(0);
// 	}
// 	94% {
// 		transform: translate(0);
// 	}
// 	97% {
// 		transform: translate(-146px);
// 	}
// 	100% {
// 		transform: translate(-146px);
// 	}
// }



@keyframes two-in{
	0% {
		transform: translate(-146px,-50%);
	}
	30% {
		transform: translate(-146px,-50%);
	}
	33% {
		transform: translate(0,-50%);
	}
	64% {
		transform: translate(0,-50%);
	}
	67% {
		transform: translate(-146px,-50%);
	}
	100% {
		transform: translate(-146px,-50%);
	}
}