&.events-page{
  .header-section{
    padding-top:35px;
    .btn{
      &.btn-grey{
        color:$white;
        @include etype(3);
        text-transform:initial;
        min-height: 60px;
        margin-bottom: 45px;
        svg{
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  .section-title{
    @include etype(5);
    color: $white;
    margin:0;
    padding:20px 0;
  }
  .grid{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 24px 24px; 
    .left{
      grid-row: 1 / 3;
    }
  }
  .section{
    &-1,
    &-2,
    &-3{
      margin-bottom: 24px;
    }
  }
  .ticket{
    width: 100%;
    padding:16px 16px 0;
    .ticket-head.with-image{
      margin-bottom: 14px;
    }
  }
}
.pill-buttons{
  display: flex;
  .btn-pill{
    margin:0 8px 8px 0;
  }
}