.search{
  .inputContainer {    
    background-color: $black;
    border: 1px solid $grey-80;
    border-radius: 4px;
    height: 48px !important;
    input{
      color:$white;
      @include ptype-1("regular","normal");
    }
    &:hover,
    &:focus,
    &:focus-within,
    &:active{
      border-color:$accent-2;
    }
    @include placeholder($grey-30,1);
  }
}