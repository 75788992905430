.profile-status{
  display: flex;
  flex-grow: 1;
  padding: $gutter*2;
  flex-direction: column;
  justify-content: space-between;
  .profile-type{
    width: auto;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding:1px;
    background: linear-gradient( 90deg, #529bb5 0%, #e24aa3 100%);
    margin: 0 auto 16px 0;
    .inner{
      background: $black;
      border-radius: 9px;
      padding: 0 12px 2px;
      height: 18px;
      font-family: $Grotesque;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .full-name{
    @include etype(4);
    margin-bottom: 16px;
  }
  .job-name{
    @include ptype(0,"strong");
    margin-bottom: $gutter*2;
    + .company-location{
      margin-top: -$gutter*2;
    }
  }
  .company-location{
    @include ptype(0,"base");
    margin-bottom: $gutter*2;
  }
  .progress{
    + .bottom-line{
      margin-top: 8px;
    }
  }
  .bottom-line{
    display: flex;
    justify-content: space-between;
    .left{
      display: flex;
      .info{
        margin-right: 6px;
        svg{
          path{
            fill:#4D4D4D;
          }
        }
        .tooltip{
          margin-top: -4px;
        }
        &.opened{
          .tooltip{
            right: 30px;
          }
        }
      }
    }
  }
}