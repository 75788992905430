@import 'styleBM/abstracts/spacing.scss';

.page-wrap-mobile-page {
  @extend .py6; 
  @extend .px10; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header-wrapper {      
      width: fit-content;
      min-width: 200px;
      max-width: 340px;
      max-height: 400px;
      min-height: 300px;
      margin-left: auto;
      margin-right: auto;
    .header-img {
      max-width: 100%;
      max-height: 100%;

      object-fit: cover;
     
      border-radius: 8px;
    }
  }

  .txt-wrapper {
    max-width: 366px;
    display: flex;
    flex-direction: column;
  }

  img.btn {
    cursor: pointer;
  }
}