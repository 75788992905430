.ticket-wrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $grey-90;
  border:solid 1px $grey-80;
  margin-bottom: #{$gutter * 2};
  border-radius: 8px;
  overflow:hidden;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 40px;
  padding-right: 40px;

  // border-top-color: $grey-80;
  // border-bottom-color: $grey-80;
  transition: all 0.25s ease-out;
  position: relative;
  z-index: 1;
  &:hover{
    box-shadow: 0 0 40px #df4ab785;
    .separator > span{
      background-color:#df4ab732;
      transition: all 0.25s ease-out;
    }
    +.additional{
      box-shadow: 0 0 20px #df4ab785;
      position: relative;
      z-index: 0;
    }
  }
  &.sold{
    padding: 32px 0;
  }
  &__header{
    display: flex;
  }
  .ticket-price-zone{
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    >div:nth-child(2){
      flex-basis: 180px;
      flex-shrink: 0;
    }
    >div:last-child{
      flex-shrink: 0;
      flex-basis: 300px;
    }
  }
  .ticket-card{
    .heading-5{
      @include etype(3);
      text-transform: uppercase;
      color:$white;
      margin-bottom: 8px;
    }
    @include max-xl {
      margin-bottom: 30px;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding-right: 40px;
      display: flex;
      flex-wrap: wrap;
      >.heading-5{
        width: auto;
        display: inline-flex;
        margin-right: 20px;
        + .pill{
          margin-left: 0;
          margin-right: auto;
          display: inline-flex;
          align-self: flex-end;
        }
      }
    }
  }
  >.container{
    >.row{
      >.col-12{
        // padding:0 40px;
      }
    }
  }
  .separator{
    margin-left: -40px;
    width: calc(100% + 2 * 40px);
    display: flex;
    position: relative;
    height: 24px;
    margin-top: 12px;
    align-items: center;
    >svg{
      margin-left: 12px;
      margin-right: 12px;
    }
    // background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAYCAYAAADOMhxqAAAAJElEQVQ4jWNgGAWjACtgtLCw+I8scOLECUZ8Gpho655RMIIAANCkBATyjSlkAAAAAElFTkSuQmCC') 0 0 repeat-x;
    > span{
      position: absolute;
      z-index: 1;
      top:0;
      left:0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $black;
      border:solid 1px $grey-75;
      transform: translateX(-12px);
      + span{
        left:auto;
        right: 0;
        transform: translateX(12px);
      }
    }
  }
  &.has-additional{
    margin-bottom: #{$gutter / 3 * 2};
  }
 
  + .additional{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: $grey-85;
    border:0;
    margin-bottom: #{$gutter * 2};
    border-radius: 8px;
    overflow:hidden;
    padding: 32px 40px;
    border:solid 1px transparent;
    border-top-color: $grey-75;
    border-bottom-color: $grey-75;
    transition: all 0.25s ease-out;
    &:hover{
      box-shadow: 0 0 40px #df4ab785;
      position: relative;
      z-index: 1;
    }
  }
  .pill{
    width: auto;
    height: 43px;
    display: inline-flex;
    align-self: flex-start;
    margin: auto;
    padding: 12px;
    border-radius: 21px;
    //styleName: Utilitarian/Basis - Base/$ptype-base;

    @include ptype(0,"base");
    color:$white;
    svg{
      margin-right: 8px;
    }
  }
  &.type{
    &-general{
      .pill{
        background-color: $all-good;
      }
    }
    &-vip{
      .pill{
        background-color: $alert;
      }
    }
    &-business{
      .pill{
        background-color: $danger;
      }
    }
    &-president{
      .pill{
        background-color:$dark-purple-80;
        text-transform: uppercase;
      }
    }
    &-general,
    &-vip,
    &-business,
    &-president{
      &.sold{
        background-color: $grey-75;
      }
    }
  }
}

.payments{
  .btn{
    border-radius: 24px;
    >div{
      border-radius: 23px;
      background-color: transparent;
    }
  }
}

.benefits-list{
  padding-left: 0;
  list-style: none;
  outline: none;
  width: 100%;
  @include responsiveFormGrid(400px);
  row-gap: 12px;
  column-gap: 32px;
  margin-top: 12px;
  margin-bottom: 0;
  &.with-offer{
    >li{
      &:nth-child(even){
        margin-right: 33.3333%;
      }
    }
  }
  >li{
    display: flex;
    align-items: flex-start;
    @include ptype(0);
    line-height: 20px;
    
    .like{
      margin-top: 3px;
      margin-right: 12px;
    }
    .text-inline {
      display: inline-block;
      white-space: nowrap;
    }
    .benefit-name {
      white-space: pre-wrap;
      padding-right: 36px;
    }
    .info{
      display: inline-block;
      // margin-top: 4px;
      vertical-align:bottom;
      height: 18px;
      margin-left: -24px;
      .tooltip{
        background-color: $white;
        color: $black;
        font-size: 14px;
        &::after{
          border-color: transparent transparent transparent $white;
        }
      }
    }
  }
}

.limited{
  background: $grad-secondary;
  margin: auto;
  width: 100%;
  // position: absolute;
  right:0;
  bottom:0;
  white-space: nowrap;
  padding:14px 24px 14px 16px;
  border-radius: 8px;
  // min-width: 148px;
  user-select: none;
  font-family: $Grotesque;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
  .tx{
    text-transform: uppercase;
    font-family: $Grotesque;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.additional{
  display: flex;
  .message{
    display: flex;
    img{
      margin-right: 16px;
    }
    .select{
      &-seat{
        .heading{
          @include etype(3);
          margin: 0;
        }
      }
      &-message{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        p{
          margin: 0;
          @include ptype(0);
          + .info{
            margin-top: 3;
            margin-left: 12px;
          }
        }
      }
    }
  }
}