&.frameworks-article-page{
  padding-bottom: 76px;
  [class^="col-"].article-holder{
    @include min-lg{
      padding:0 68px;
    } 
  }
  .article{
    &-time-management{
      .card{
        &.card-article{
          margin-bottom: 24px;
          .heading-6{
            @include etype(3);
          }
          p{
            margin-bottom: $gutter*2;
          }
        }
      }
    }
    &-holder{
      .row{
        &-1{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 24px;
        }
        &-2{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 40px;
        }
      }
      
      .content-wrap{
        display: flex;
        .avatar{
          width: 56px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 12px;
          >img{
            max-height: 56px;
            display: flex;
          }
        }
        .text{
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p{
            margin: 0;
            font-family:$Grotesque;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            + P{
              font-weight: 400;
              color: $grey-45;
            }
          }
        }
      }
      .social-wrap{
        .share-item{
          border:0;
          outline: none;
          border-radius: 4px;
          overflow: hidden;
          ~ .share-item{
            margin-left: 12px;
          }
        }
      }
      .bottom-content{
        display: flex;
        flex-direction: column;
      }
    }
    &-categories{
      .categorie-card{
        .heading-2{
          @include etype(3);
          margin-bottom: $gutter *2 ;
        }
      }
      .wrapper{
        display: flex;
        flex-direction: column;
        .item{
          &:last-child{
            svg{
              margin-bottom: 0;
            }
          }
        }
        svg{
          max-width: 48px;
          max-height: 56px;
          margin-bottom: 8px;
        }
        .leadership-content{
          flex-grow: 1;
          .title{
            justify-content: space-between;
          }
          .article{
            @include ptype-1("regular");
          }
        }
      }
    }
  }
  .btn{
    &.btn{
      &-appreciate{
        background:$accent-3-80;
        height: 36px;
        border-radius: 18px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
        &:hover{
          background:$accent-3;
        }
        >svg{
          margin-right: 8px;
        }
      }
    }
  }
  @import "browse-card";
  .card{
    &.browse-card-type{
      &.article-card{
        width: 390px;
        margin-right:32px;
        margin-bottom: 40px;
        margin-left: 0;
        &:last-child{
          margin-right: 0;
        }
      }
    } 
  }
  .separator{
    width: 100%;
    height: 1px;
    margin-bottom: 64px;
  }
  .more-article-heading{
    @include etype(5);
    margin-top: 0;
    margin-bottom: 32px;
  }
  .more-article-row{
    display: flex;
    flex-wrap: nowrap;

    &::-webkit-scrollbar {
      height:8px;
      border-radius:4px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: none;
      background-color:$grey-85;
      border-radius:4px;
    } 
    &::-webkit-scrollbar-thumb {
      background-color: $grey-65;
      outline: none;
      border-radius:4px;
    }
    overflow-y: hidden;
    overflow-x: auto;
  }
  .tags-holder{
    margin-bottom:40px;
    @include min-sm{
      max-width: 70%;
    }
  }
}

.multi-select-wrapper{
  .options-container{
    &.buttons{
      .option-item{
        height: 40px;
        padding: 0 16px;
        background: $grey-75;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $Grotesque;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color:#cccccc;
        &.selected{
          background: $grad-good;
          color:$white;
        }
      }
    }
  }
}



.helpful{
  background: $grad-btn;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 64px;
  padding:4px;
  overflow: hidden;
  &-inner{
    background: $grey-85;
    border-radius: 8px;
    overflow: hidden;
    padding: 40px;
    .heading{
      margin-top: 0;
      margin-bottom: 12px;
      @include etype(5);
    }
    .content{
      font-family:$Grotesque;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      p{
        margin: 0 0 24px;
      }
    }
  }
}

.btn.btn-plain.btn-load{
  margin-top: 8px;
  background: $grey-75;
  border-radius: 4px;
  >div{
    background: $grey-75;
    padding: 0 45px;
    .label{
      @include ptype(0,"base");
    }
  }
}

.connections{
  display: inline-flex;
  background: $black;
  border:solid 1px $grey-75;
  padding: 13px 16px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 16px;
  .items{
    display: flex;
    flex-direction: row-reverse;
    width: 60px;
    margin-right: 16px;
    .item{
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
      margin-left: -8px;
    }
  }
  .content{
    font-family: $Grotesque;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: left;
    color:$white;
  }
}