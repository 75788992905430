
.card.fullwidth{
  .wrapper{
    flex-direction: column;
  }
}
.experince-line{
  display: flex; 
  @include horizontal-scrollbar("auto");
  margin-bottom: $gutter*2;
  @media max-md{
    scroll-snap-type: x mandatory;
    .experience{
      scroll-snap-align: start;
      scroll-snap-stop: normal;
    }
  }
  >.inner{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}