.table-page{
  width: 100%;
  background-color: $black;
  display: flow-root;
  .bread-crumb{
    padding-left: $gutter * 2;
    padding-top: 0;
  }
  .header-section{
    padding: 0 $gutter * 2 $gutter;
    .heading-5{
      font-family: "prometo", sans-serif;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0em;
      text-align: left;
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 0;
    }
  }

  .bg-white.filter-row{
    width: calc(100% + 24px );
    margin: 0 -$gutter;
  }
  .table-wrapper{
    >div:not(.multy-select-modal){
      >div{
        background-color: $black;
        color: $white;
        border-bottom: 1px solid $grey-75;
        height: auto !important;
        padding: $gutter * 2 0;
        @include ptype-1("base-strong");
        color: $white;
        &.teable-header{
          border-bottom: 1px solid $grey-65;
          @include ptype-1("base-strong");
          color: $white;
          &:empty{
            padding:0;
          }
          &.select,
          &:first-of-type {
            padding-left: $gutter * 2;
          }
          &.pill,
          &.tag{
            margin: 0;
            border-radius: 0;
            padding: 0 10px;
            text-align: left;
            justify-content: flex-start;
          }
          &.tag{
            padding:0;
          }

          .stroke-text-light-01{
            stroke:$grey-65;
            &.selected{
              svg{
                path{
                  fill:$accent-3;
                  stroke: $black;
                }
              }
            }
          }

          &.buttonAction {
            justify-content: center;
          }
        }

        &.cell{
          &-0{
            padding-left: $gutter * 2;
            .stroke-text-light-01{
              stroke: $grey-65;
              &.selected{
                svg{
                  path{
                    fill:$accent-3;
                    stroke: $black;
                  }
                }
                
              }
            }
          }
          &-0,
          &-1,
          &-2,
          &-3,
          &-4,
          &-5{
            .avatar{
              border: 1px solid #3DDBD9;
            }
          }
          &-2,
          &-7 {
            padding: 0 8px 0 0;
          }
          &-6{
            padding:0;
            .avatar{
              border: 1px solid #3DDBD9;
              &.bg-black{
                border: 1px solid #3DDBD9;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            &:nth-child(even){
              .avatar{
                &.bg-black{
                  border: 1px solid red;
                }
              }
            }
          }
          &-7{
            .table-default-cell{
              .subtitle{
                color:$grey-45;
              }
            }
          }
          &-8{
            padding:0;
          }
        }

        .pill{
          height: 32px;
          border-radius: 17px;          
          background: $grad-good;
          box-sizing: border-box;
          padding: 1px;
          &.filled {
            background: transparent;
          }
          >div{
            //background: $black;
            height: 30px;
            border:0;
            .color-purple-1{
              color: $white;
            }
          }
        }

        .table-default-cell{

          .title{
            color:$white;
          }
          .subtitle{
            color:$white;
          }
          &.bg-alert-danger{
            .title{
              color:$white;
            } 
          }
          .tag-keeper{
            height: 23px;
            padding: 1px;
            border-radius: 4px;
            background: $grad-good;
            display: flex;
            align-items: center;
            justify-content: center;
            .tag-wrapper{
              //@at-root: $grey-75;
              height: 21px;
              width: 100%;
              padding:4px 8px 5px; 
              display: flex;
              align-items: center;
              justify-content: center;
              .tag-color-text-light-01{
                color: $white;
                //styleName: Utilitarian/Hero New - Base -2/$ptype - base -2;

                @include ptype-2("base")


              }
            }
          }
        }

        .tag-wrapper{
          //background: $dark-purple-80;
          height: auto;
          padding:7px 8px;
          @include ptype-1("base");
          font-size: 16px;
        }

        .cell-more-actions{
          .clickable{
            background: $black;
          }
          svg{
            path{
              stroke:#808080;
              fill: none;
            }
          }
        }

      }
    }
  }


  .multy-select-modal{
    background: $black;
    border-color: $grey-45;
    .line{
      background-color: $grey-75;
    }
    .icon{
      background-color: $black;
      border: solid 1px $grey-80;
    }
    svg{
      rect{
        fill: none;
      }
      path{
        stroke: $grey-45;
      }
    }
  }
}