// Margins
// we want to replace this with what we have in spacing. 

@mixin margin-top{
  &-auto{ margin-top: auto    !important;}
  &-0{ margin-top: 0          !important;}
  &-1{ margin-top: 1rem       !important;}
  &-2{ margin-top: 2rem       !important;}
  &-3{ margin-top: 3rem       !important;}
  &-4{ margin-top: 4rem       !important;}
  &-5{ margin-top: 5rem       !important;}
}

@mixin margin-right{
  &-auto{ margin-right: auto  !important;}
  &-0{ margin-right: 0        !important;}
  &-1{ margin-right: 1rem     !important;}
  &-2{ margin-right: 2rem     !important;}
  &-3{ margin-right: 3rem     !important;}
  &-4{ margin-right: 4rem     !important;}
  &-5{ margin-right: 5rem     !important;}
}

@mixin margin-bottom{
  &-auto{ margin-bottom: auto !important;}
  &-0{ margin-bottom: 0       !important;}
  &-1{ margin-bottom: 1rem    !important;}
  &-2{ margin-bottom: 2rem    !important;}
  &-3{ margin-bottom: 3rem    !important;}
  &-4{ margin-bottom: 4rem    !important;}
  &-5{ margin-bottom: 5rem    !important;}
}

@mixin margin-left{
  &-auto{ margin-left: auto   !important;}
  &-0{ margin-left: 0         !important;}
  &-1{ margin-left: 1rem      !important;}
  &-2{ margin-left: 2rem      !important;}
  &-3{ margin-left: 3rem      !important;}
  &-4{ margin-left: 4rem      !important;}
  &-5{ margin-left: 5rem      !important;}
}


@mixin margin-x{
  &-auto{ 
    margin-right: auto !important;
    margin-left: auto !important;
  }
  &-0{ 
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  &-1{ 
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  &-2{ 
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  &-3{ 
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  &-4{ 
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  &-5{ 
    margin-left: 5rem !important;
    margin-right:5rem !important;
  }
}

@mixin margin-y{
  &-auto{ 
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  &-0{ 
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &-1{ 
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  &-2{ 
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  &-3{ 
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  &-4{ 
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  &-5{ 
    margin-top: 5rem !important;
    margin-bottom:5rem !important;
  }
}


// Paddings
@mixin padding-top{
  &-auto{ padding-top: auto    !important;}
  &-0{ padding-top: 0          !important;}
  &-1{ padding-top: 1rem       !important;}
  &-2{ padding-top: 2rem       !important;}
  &-3{ padding-top: 3rem       !important;}
  &-4{ padding-top: 4rem       !important;}
  &-5{ padding-top: 5rem       !important;}
}

@mixin padding-right{
  &-auto{ padding-right: auto  !important;}
  &-0{ padding-right: 0        !important;}
  &-1{ padding-right: 1rem     !important;}
  &-2{ padding-right: 2rem     !important;}
  &-3{ padding-right: 3rem     !important;}
  &-4{ padding-right: 4rem     !important;}
  &-5{ padding-right: 5rem     !important;}
}

@mixin padding-bottom{
  &-auto{ padding-bottom: auto !important;}
  &-0{ padding-bottom: 0       !important;}
  &-1{ padding-bottom: 1rem    !important;}
  &-2{ padding-bottom: 2rem    !important;}
  &-3{ padding-bottom: 3rem    !important;}
  &-4{ padding-bottom: 4rem    !important;}
  &-5{ padding-bottom: 5rem    !important;}
}

@mixin padding-left{
  &-auto{ padding-left: auto   !important;}
  &-0{ padding-left: 0         !important;}
  &-1{ padding-left: 1rem      !important;}
  &-2{ padding-left: 2rem      !important;}
  &-3{ padding-left: 3rem      !important;}
  &-4{ padding-left: 4rem      !important;}
  &-5{ padding-left: 5rem      !important;}
}


@mixin padding-x{
  &-auto{ 
    padding-right: auto !important;
    padding-left: auto !important;
  }
  &-0{ 
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  &-1{ 
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  &-2{ 
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  &-3{ 
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  &-4{ 
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  &-5{ 
    padding-left: 5rem !important;
    padding-right:5rem !important;
  }
}

@mixin padding-y{
  &-auto{ 
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  &-0{ 
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &-1{ 
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  &-2{ 
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  &-3{ 
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  &-4{ 
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  &-5{ 
    padding-top: 5rem !important;
    padding-bottom:5rem !important;
  }
}