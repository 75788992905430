@import "styleBM/abstracts.scss";
@import "styleBM/abstracts/spacing.scss";

$content-top-margin: 40px;
$footer-height: 112px;

.bmbigmodalv2-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  &.fullScreen {
    height: 100vh;
  }
  overflow: hidden;
  z-index: 2;
  backdrop-filter: blur(4px);

  transition: stroke-width 0.3s ease-in;

  button.modal-close {
    padding: 0;
    position: absolute;
    z-index: 10;
    right: 40px;
    top: calc(40px + #{$content-top-margin});
    margin-left: auto;
    background: none;
    border: none;
    cursor: pointer;
    :hover {
      stroke-width: 2;
      transition: stroke-width 0.3s ease-out;
    }

    @include max-md {
      right: 20px;
      top: calc(20px + #{$content-top-margin});
    }
  }
  .content {
    width: 100%;
    height: calc(100% - #{$content-top-margin});
    margin-top: $content-top-margin;
    padding: 80px 65px 0px 65px;
    border-radius: 40px 40px 0px 0px;
    border: 1px solid #6668;
    border-bottom-width: 0;
    background: rgba(0, 0, 0, 0.9);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    animation-name: example;
    animation-duration: 400ms;
    animation-timing-function: ease-in-out;

    @include max-md {
      padding: 40px 45px 0 45px;
    }

    &.no-margin {
      padding: 0;
      margin: 0;
      height: 100%;
      border-radius: 0;
    }

    .modal-body {   
      height: 100%;  
      @extend .pt12;
      @extend .pb12;
      padding-left: 15%;
      padding-right: 15%;
      @include vertical-scrollbar-colored;
     
      @include max-lg {
        padding-left: 10%;
        padding-right: 10%;
      }
      @include max-md {
        padding-left: 5%;
        padding-right: 5%;
      }

      margin-bottom: $footer-height;
      &.no-footer {
        margin-bottom: 0;
      }

      .modal-footer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: $footer-height;
        @extend .p6;
        background: $grey-90;
        box-shadow: 0px 0px 20px 0px #888888;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend .gap4;

        > button {
          min-width: 200px;
          width: 200px;
        }
      }      
    }
  }
}

@keyframes example {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY();
  }
}