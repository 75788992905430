&.card-interests{
  justify-content: flex-start;
  .heading-2{
    @include etype(3);
    color: $white;
    margin-bottom: $gutter*2;
  }
  .btn-interest{
    margin: 0 10px 14px 0;
  }
  .interest-legend{
    margin-top: 80px;
    .title{
      @include ptype(0,"base");
      color:$white;
    }
    .legend-line{
      display:flex;
      flex-wrap: wrap;
      flex-direction: row;
      .legend{
        &:not(:last-child){
          margin-right: 30px;
        }
        display: flex;
        flex-direction: column;
        .dots{
          display: flex;
          >span{
            width: 8px;
            height: 8px;
            display: inline-flex;
            margin:10px 4px 6px 0;
            border-radius: 50%;
            background: #fff2;
          }
        }
        .label{
          @include ptype(0,"base");
          color:#C2C7CC;
        }
        &.l{
          &-1{
            .dots >span:first-child{
              background: $white;  
            }
          }
          &-2{
            .dots >span:first-child{
              background: $white; 
              + span{
                background: $white;
              } 
            }
          }
          &-3{
            .dots > span{
              background: $white; 
            }
          }
        }
      }

    }
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    @include max-sm{
      padding: 0;
    }
  }
}