@import "styleBM/abstracts.scss";
@import "styleBM/abstracts/spacing.scss";

.my-profile-page{
  .header-section{
    &.with-edit{
      padding-bottom: $gutter;
      @include min-sm{
        flex-direction: row;
        justify-content: space-between;
      }
      .right{
        margin: $gutter 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @include min-sm{
          width: auto;
          transform: translateY(-$gutter*2);
          margin: 0;
        }
      }
    }
  } 
  .card{
    // margin-bottom: $gutter*2;
    @import "card-types/user-card";
    @import "card-types/stat-card";
    @import "card-types/interest-card";
    @import "card-types/achievment-card";
    @import "card-types/personal-experience-card";
    @import "card-types/education-card";
    @import "card-types/personal-quote-card";
    @import "card-types/personal-book-card";
    @import "card-types/form";
  }
  .magic-input .inputContainer textarea {
    height: auto;
    max-height: 20vh;
  }
  .col-xl-6.col-offset {
    margin-right: 50%;
  }
}

.my-profile-content {
  display: grid;
  grid-template-columns: [col1-start] 2fr [col2-start] 1fr;
  grid-template-rows: [row1-start] auto [row2-start] auto;
  grid-column-gap: 24px;
  grid-row-gap: 60px;
  width: 100%;

  .card.user-profile {
    grid-column: col1-start;
		grid-row: row1-start ;
  }

  .card.personal-quote {
    grid-column: col2-start;
		grid-row: row1-start ;
  }

  .user-profile-tabs-wrapper {
    grid-column: col1-start / col2-end;
		grid-row: row2-start;
  }
}