$min-height:320px;

&.success-page{
	.success-header{
    display: flex;
    img{
      margin-right: 8px;
    } 
  }
	.header-section{
		padding-top: 24px;
    padding-bottom: 12px;
		.subtitle{
			font-family:$Grotesque;
			font-size: 20px;
			font-weight: 700;
			line-height: 24px;
			margin: 16px 0 0 0;
		}
		.title{
			margin:4px 0 $gutter*2 0;

		}
	}
  .card{
    &.before-go{
			min-height:$min-height;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .wrapper{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
        p{
          margin-top: $gutter*2;
          margin-bottom: $gutter*2;
        }
        .btn{
          &.large{
            width: 100%;
            margin: 0;
            height: auto;
            >div{
              width: 100%;
              @include etype(2);
              min-height: 62px;            
              .label{
                text-align: center;
              }

            }
          }
        }
      }
		}
  }
	.card-type{
		&.card-1{
			min-height:$min-height;
      .signature{
        margin-top: $gutter*2;
      }
		}
	
    .heading-5{
      @include etype(3);
    }
    .content{
      p{
        @include ptype-1("base");
        font-size: 14px;
        line-height: 18px;
        &:last-child{
          margin-bottom: 0;
        }
        strong{
          @include ptype-1("base-strong");
          font-size: 14px;
          line-height: 18px;
        }
      }
      a{
        color: $white;
        &:hover{
          color: $white;
          text-decoration: underline;
        }
      }
    }

    .top-line{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .date{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 0;
        > span{
          &:first-child{
            color: $white;
          }
          + span{
            margin-top: 2px;
          }
        }
      }
    }

    .top-section{
      padding: 24px 24px 16px;
      border-bottom: 1px dashed $grey-75;
      min-height: 135px;
    }
    .middle{
      &-1,
      &-2{
        padding:16px 24px;
        display: flex;
        flex-direction: column;
        min-height: 97px;
        .subtitle{
          font-weight: 700;
        }
        .tag{
          margin-top: 4px;
        }
      }
      &-1{
        margin-bottom: auto;
        font-size: 16px;
        line-height: 19px;

        .subtitle{
          margin-bottom: 8px;
        }
        .tranzaction{
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .right{
            >div:not(.tag){
              text-align: right;
              padding-right: 2px;
              margin-right: 0;
            }
          }
        }
      }
      &-2{
        background-color: $black;
        .name{
          display: flex;
          align-items: center;
          .initials{
            margin-right: 8px;
            margin-top: 10px;
          }
        }
      }
    }
    &.order,
    &.invoice{
      justify-content: space-between;
      .heading-5{
        @include etype(2);
      }
    }
    &.invoice{
      .middle{
        &-1{
          .price-vat{
            span{
              + span{
                color:#999999;
              }
            }
          }
        }
        &-2{
          >.content{
            display: flex;
            align-items: center;
            margin-top: 17px;
          }
          svg{
            margin-right: 4px;
            path{
              stroke:$white;
            }
          }
        }
      }
    }
  }
	.ticket.reserved{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    min-height: 80px;
    align-items: center;
    padding:$gutter * 2;
    background-color: $black;
    .pill{
      margin-left: 0;
      margin-top: 4px;
      text-transform: capitalize;
    }
    .amount{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      padding-left:$gutter;
      text-align: center;
    }
  }
  .invoice-detail{
    .heading-2{
      &.title {
        margin-bottom: 32px;
      }
		}
		.heading-3{
			@include etype(2);
			margin-bottom:16px;
      &.total {
        margin-top: 16px;
      }
		}
    .detail{
      display: flex;
      flex-direction: column;
    }
    .buttons{
      margin-top: 8px;
      .btn.btn-secondary-active.large{
        width: 100%;
        height: auto;
        margin-bottom: 0;
        >div{
          min-height: 62px;
          width: 100%;
          .label{
            @include etype(1);
          }
        }
      }
    }
    .counter-wrapper{
      display: flex;
      flex-wrap: nowrap;
      justify-content:space-between;
      align-items: center;
      background: $grey-75;
      margin-bottom:10px;
      border-radius: 8px;
      padding: 16px $gutter*2;
      border:0;
      box-shadow: 0 0 0 1px #fff2 inset;
      overflow: hidden;
      position: relative;
      &::before,
      &::after{
        content:"";
        position:absolute;
        z-index: 1;
        background: $grey-85;
        width: 10px;
        height: 10px;
        display: flex;
        box-shadow: 0 0 0 1px #fff4 inset;
        border-radius: 50%;
        top:50%;
      }
      &::before{
        left:0;
        transform: translate(-4px,-4px);
      }
      &::after{
        right:0;
        transform: translate(4px,-4px);
      }
      &:last-child{
        margin-bottom: 8px;
      } 
      .ticket-counter {
        justify-content: flex-end;
        .c-btn {
          display: none;
        }
      }
      .title {
        @include etype(2);
      }
      .prices {
        align-items: flex-start;
      }
    }
  }
	.bank-transfer{
    display: flex;
		margin-bottom: $gutter*2;
		min-height:$min-height;
		justify-content: flex-start;
    .transfer-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $gutter * 2;
      .heading{
        &-3{
          @include etype(3);   
        }
      }
      .info{
        .info-btn{
          svg{
            path{
              fill:$white;
            }
          }
        }
        &.opened{
          .tooltip{
            right: 30px;
          }
        }
      }
    }
    .heading{
      &-5{
        @include etype(2);
        margin-bottom: 8px;
      }
    }
    .content{
      margin: 0 0 $gutter*2;
      @include ptype(0,"base");
      color:$white;

    }
  }
  @import './order-status.scss';
}