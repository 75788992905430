.expanded-text{
  position: relative;
  .text{
    margin:0 0 $gutter*2;
    @include ptype(0,"base");
    &.active{
      margin:0 0 $gutter*5;
    }
  }
  button{
    background:$grey-75;
    border:0;
    outline:none;
    color: $white;
    position: absolute;
    z-index: 1;
    right:0;
    bottom:0;
    @include ptype(0,"strong");
    margin:0 0 $gutter*2;
    cursor: pointer;
  }
}