&.bad-move-page{
	padding-bottom: 0;	
	@include min-sm{
		width: calc(100% + 96PX);
		margin-left: -96px;
	}
	
	display: flex;
	background-image: none !important;
	background-color: $grey-80;
	.locked-grad{
		background: $grad-bad;
		width: 100%;
		min-height: calc(100vh - 80px);
		display: flex;
		justify-content: center;
		.container-fluid{
			display: flex;
			justify-content: center;
		}
		.main-content{
			display: flex;
			flex-direction: column;
			margin:0 auto auto;
			max-width: 410px;
			text-align: center;
			padding: 40px 0;
			.subtitle{
				font-family: $Grotesque;
				font-size: 20px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0em;
				text-align: center;
				margin: 0;
			}
			.title{
				@include etype(5);
				text-align: center;
				margin:0 0 $gutter*2;
				@include min-sm{
					margin: 0 0 72px;
				}
			}
			.center-image{
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: $gutter*2;
				.img-holder{
					width: 120px;
					height: 120px;
					background: $grey-75;
					border-radius: 50%;
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			p{
				font-family: $Grotesque;
				font-size: 16px;
				font-weight:400;
				line-height: 19px;
				text-align: center;
				margin:0 0 $gutter*4;
			}
			.help{
				font-family: $Grotesque;
				font-size: 16px;
				font-weight: 400;
				line-height: 19px;
				text-align: center;
				margin:0 0 $gutter*2;
				a{
					font-weight: 700;
					margin: 0 8px;
					color: $accent-3;
				}
			}
		}
	}
}