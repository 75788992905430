@import "styleBM/abstracts.scss";


.bm-btn, .bm-tag {
	position: relative; //used for loading-indicator

	cursor: pointer;
	width: fit-content;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding:2px;
	border-radius: 8px;
	background: $grad-good;
	border:0;
	outline: none;
	transition: all 0.25s ease-out;
	// align-self: baseline;
	white-space: nowrap;

	.loading-indicator{
    position: absolute;
		left: 3px;
    right: 3px;
    margin-left: -50%;
    margin-right: -50%;
		// transform: translateX(-50%) translateY(-50%) scale(0.4);
		//transform: translateX(-50%) translateY(-50%); //used to position it on the center of the button
    backdrop-filter: blur(5px);
		transform: scale(0.5);
    cursor: inherit;
  }

	>.inner{
		flex: 1;
		width: 100%;
		padding: 0 24px;
		border-radius: 6px;
		height: 52px;
		background: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		background:$grey-90;
		.icon{
			width:auto;
			height:32px;
			display: flex;
			align-items: center;
			&-left{
				margin-right: 8px;
			}
			&-right{
				margin-left: 8px;
			}
		}
	}
	.label{
		color:$white;
		@include etype(1);
	}
	svg{
		path{
			stroke: $white;
		}
	}

	&.left-icon{
		>.inner{
			padding-left:14px;
		}
	}
	&.right-icon{
		>.inner{ 
			padding-right:14px;
		}
	}

	&.only-icon{
		>.inner{ 
			padding-left:14px;
			padding-right:14px;
		}
	}

	&.small{
		>.inner{
			height: 44px;
		}
		.label{
			@include ptype-1("strong");
		}
		&.only-icon{
			>.inner{ 
				padding-left:8px;
				padding-right:8px;
			}
		}
	}
	&.large{
		>.inner{
			height: 60px;
		}
		.label{
			@include etype(2);
		}
	}

	&.w-100{
		width: 100%;
		>.inner{
			width: 100%;
		}
	}

	&.disabled{
		cursor: not-allowed;
		.label{
			@include ptype-1("strong");
			color: $grey-70;
		}
	}

	&:not(:disabled):hover{
		background: linear-gradient(266deg, lighten(#4A7CDF,10%) 0%, lighten(#49B595,10%) 100%);
	}
	&:not(:disabled):active{
		background: linear-gradient(266deg, darken(#4A7CDF,10%) 0%, darken(#49B595,10%) 100%);
	}

	&.primary{
		>.inner{
			background: transparent;
		}
		.label{
			@include etype(1);
		}
		&.small{
			.label{
				@include ptype-1("strong");
			}
		}
		&.large{
			.label{
				@include etype(2);
			}
		}

		&.low{
			background: $grey-75;
			>.inner{
				background: transparent;
			}
			.label{
				@include ptype-1("regular");
			}
			&.large{
				.label{
					@include etype(2);
				}
			}
			&:not(:disabled):hover{
				background: lighten($grey-75,10%);
			}
			&:not(:disabled):active{
				background: darken($grey-75,10%);
			}
		}

		&.high{
			background:$grad-btn;
			>.inner{
				background: transparent;
			}
			&:not(:disabled):hover{
				background: linear-gradient( 90deg, lighten(#B010FF,10%) 0%, lighten(#F537B9,10%) 100%);
			}
			&:not(:disabled):active{
				background: linear-gradient( 90deg, darken(#B010FF,10%) 0%, darken(#F537B9,10%) 100%);
			}
			&.small{
				.label{
					@include ptype-1("strong");
				}
			}
			&.large{
				.label{
					@include etype(2);
				}
			}
		}

		&.disabled{
			background: $grey-45;
			user-select: none;
			pointer-events: none;
			>.inner{
				background: transparent;
			}
			// .label{
			// 	@include ptype-1("strong");
			// }
		}
	}

	&.secondary{
		background: $grad-good;
		.pill{
			svg{
				path{
					stroke: $black !important;
				}
			}
		}
		>.inner{
			background:$grey-90;
		}
		.label{
			@include ptype-1("strong");
		}
		&.small{
			.label{
				@include ptype-1("strong");
			}
		}
		&.large{
			.label{    
				@include ptype-1-plus-strong;
			}
		}

		&.low{

			background: $grad-warn;
			>.inner{
				background: $grey-90;
			}

			.label{
				@include ptype-1("regular");
			}
			&.small{
				.label{
					@include ptype-1("regular");
				}
			}
			&.large{
				.label{    
					@include ptype-1-plus-strong;
				}
			}
			&:not(:disabled):hover{
				>.inner{
					background: lighten($grey-90,10%);
				}
				
			}
			&:not(:disabled):active{
				>.inner{
					background: darken($grey-90,10%);
				}
			}
		}
		&.high{
			background: $grad-btn;
			>.inner{
				background: $grey-90;
			}
			.label{
				@include ptype-1("strong");
			}
			&.small{
				.label{
					@include ptype-1("strong");
				}
			}
			&.large{
				.label{    
					@include ptype-1-plus-strong;
				}
			}
			&:not(:disabled):hover{
				>.inner{
					background: lighten($grey-90,10%);
				}
				
			}
			&:not(:disabled):active{
				>.inner{
					background: darken($grey-90,10%);
				}
			}
		}

		&:not(:disabled):hover{
			>.inner{
				background: lighten($grey-90,10%);
			}
			
		}
		&:not(:disabled):active{
			>.inner{
				background: darken($grey-90,10%);
			}
		}

		&.disabled{
			background: $grey-55;
			user-select: none;
			pointer-events: none;
			>.inner{
				background:$grey-75;
			}
			.label{
				color:$grey-55;
			}
			svg{
				path{
					stroke:$grey-55;
				}
			}
		} 
	}

	&.special{
		&.left-icon{
			>.inner{
				padding-left: 24px;
			}
		}
		&.right-icon{
			>.inner{
				padding-right: 24px;
			}
		}
		&.only-icon{
			>.inner{
				padding-left: 24px;
				padding-right: 24px;
			}
		}

		.label{
			@include ptype-1("strong");
		}
		&.special{
			&-alert{
				background: $alert;
				>.inner{
					background: transparent;
				}
				&:not(:disabled):hover{
					background: lighten($alert,10%);
				}
				&:not(:disabled):active{
					background: darken($alert,10%);
				} 
			}
			&-warning{
				background: $danger;
				>.inner{
					background: transparent;
				}
				&:not(:disabled):hover{
					background: lighten($danger,10%);
				}
				&:not(:disabled):active{
					background: darken($danger,10%);
				} 
			}
			&-success{
				background: $all-good;
				>.inner{
					background: transparent;
				}
				&:not(:disabled):hover{
					background: lighten($all-good,10%);
				}
				&:not(:disabled):active{
					background: darken($all-good,10%);
				} 
			}
			&-yellow{
				background: $accent-4-80;
				>.inner{
					background: transparent;
					.label{
						color: $black;
					}
					.pill{
						&:not(.no-static-bg){
							background-color: $black;
							color:$white;

							svg {								
								path {
									stroke: none;
									fill: white;
								}
							}
						}
					}
				}
				&:not(:disabled):hover{
					background: lighten($accent-4-80,10%);
				}
				&:not(:disabled):active{
					background: darken($accent-4-80,10%);
				} 
			}
		}
	}
} 