.book-item{
  user-select: none;
  width: 248px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0 $gutter*2 $gutter*2 0;
  padding: $gutter*2;
  border-radius: 16px;
  overflow: hidden;
  background:$grey-75;
  &:last-child{
    margin-right: 100px;
  }
  .cover{
    position: relative;
    width:100%;
    height: 300px;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background:#fff1; 
    box-shadow: 0 5px 3px rgba(0,0,0,.25);
    margin: 0 0 16px;
    >img{
      display: flex;
      object-fit: cover;
    }
  }
  .about-book{
    .title{
      @include ptype(0,"strong");
      margin: 0;
    }
    .author{
      @include ptype(0,"base");
    }
  }
  .experiences-buttons{
    margin-top: 16px;
  }
}