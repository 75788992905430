&.frameworks-index-page{
  .section-title{
    margin: 0;
    padding: 16px 0 $gutter * 2;
  }
  .video-holder{
    width: 100%;
    > div{
      width: 100% !important;
      height:0 !important;
      padding-top: 56.25%;
      position: relative;
      overflow:hidden;
      border-radius:6px;
      >div,
      .react-player__preview{
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
      }
    }
  }
  .card{
    margin-bottom: $gutter * 2;
    &.video-card{
      width: 100%;
      display: flex;
      padding: 8px;
      justify-content: flex-start;
    }
    &.mind-tools{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .card-head{
        &:empty{
          + .wrapper{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }
      }
      .mind-tool{
        .heading{
          &-6{
            @include ptype(0,"base");
            font-weight: 700;
          }
          &-2{
            @include etype(5);
            margin-bottom: 30px;
          }
        }
        .brand-tool--item{
          &:last-child{
            padding-bottom: 20px;
            &::after{
              content: none;
            }
          }
        }
      }
    }
  }
  .motivation-row{
    width: 100%;
    .wrapper{
      @include horizontal-scrollbar(auto);
      .inner{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

   

    .card.motivation{
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

.brand-tool--item{
  display: flex;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  &::after{
      content:'';
      background:transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAgAgMAAABFvEGKAAAACVBMVEUaGhp+fn6MjIzzZdBUAAAAFUlEQVQIHWNYwLCAIYABAgKAbBL5AEpPCgEfBnT3AAAAAElFTkSuQmCC') 0 0 repeat-y;
      width: 1px;
      height: 100%;
      display: flex;
      position: absolute;
      top:0;
      left:24px;
      z-index: -1;
    }
  .circle{
    background: $grad-warn;
    width: 48px;
    min-width: 48px;
    height: 48px;
    display: flex;
    border-radius: 50%;
    overflow:hidden;
    padding: 1px;
    margin-right: 16px; 
    >div{
      width: 46px;
      height: 46px;
      display: flex;
      justify-content:center;
      align-items: center;
      overflow: hidden;
      border-radius: 50%;
      background: $black;
    }
    
  }
  .text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    @include ptype(0,"base");
    line-height:0.7;
    strong{
      font-weight:700;
    }
  }
}

.card.leadership{
  width: 100%;
  min-height: 160px;
  justify-content: center;
  .wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    
  }
}

.leadership-content.content{
  display: flex;
  flex-direction: column;
  padding: 0 0 0 22px;
  .article{
    display: flex;
    padding: 0;
    @include ptype-1("base");
    color:#808080;
    + .title{
      margin-top: 4px;
    }
  }
  .title{
    @include etype(2);
    color:$white;
    display: flex;
    align-items: center;
    + .article{
      margin-top: 4px;
    }
    svg{
      transform: translateY(1px);
      margin-left: 10px;
      path{
        stroke:$white
      }
    }
  }
}

.card.motivation{
  margin-right:$gutter * 2;
  margin-bottom:$gutter * 2;
  background: $black;
  border-color: #353A3E;
  padding: 8px;
  width: 100%;
  max-width: 362px;
  min-height: 335px;
  .wrapper{
    flex-direction: column;
    overflow: hidden;
  }
  
}

.browse-card{
  display: flex;
  flex-direction: column;
  .img-wrap{
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 6px;
    overflow:hidden;
    margin-bottom: 8px;
    img{
      object-fit: cover;
    }
    .read{
      position: absolute;
      top:0;
      right:0;
      width: auto;
      height: 32px;
      display: flex;
      border-radius: 16px;
      background-color: $black;
      align-items: center;
      justify-content: center;
      margin: 8px;
      padding:0 18px;
      @include ptype-2("base");
      &.completed{
        background-color: $all-good;
      }
    }
    .new-release{
      position: absolute;
      right:0;
      bottom:0;
      background-color: $alert;
      min-height: 29px;
      border-radius: 6px 0;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include ptype-1("medium")
    }
  }
  .content-wrap{
    padding: 8px;
    display: flex;
    .avatar{
      margin-right: 16px;
    }
    .text-title{
      @include etype(2);
    }
    p{
      margin:8px 0 0;
      @include ptype(0,"base");
    }
  }
}