&.table-page.my-orders.invoice-table{
  .table-wrapper > div:not(.multy-select-modal) {
    > .cell{
      // &-3{
      //   .pill{
      //     background: transparent;
      //   }
      // }
      &-5{
        .table-default-cell .tag-keeper{
          background: transparent;
        }
      }
      &-6{
        .pill{
          background: transparent;
          .pill-content{
            background-color: transparent;
          }
        }
      }
      &-7{
        padding: 0;
      }
    }
  }
}