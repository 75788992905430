@import "profile/my-profile";

.brand-minds{
  display: flex;
  flex-direction: column;
  background-color: $black;
  flex-grow: 1;
  padding:0;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 220px;
  background-position: center;
  background-size: 100%;
  min-height: calc(100vh - 80px);
  background-attachment: fixed;
  
  @import "page-brand-minds";
  @import "page-events";
  @import "page-icons";
  @import "page-invoices";
  @import "page-registered-user";
  @import "page-ticket-type";
  @import "page-invoice-detail";
  @import "page-single-event";
  @import "page-vouchers";
  @import "page-orders";
  @import "page-invitations";
  @import "page-tickets";
  @import "tickets-detail-page";

  @import "page-payment-method";
  @import "page-billing";

  @import "frameworks/index";
  @import "frameworks/browse";
  @import "frameworks/article";

  @import "certificates/my-certificates";
  
  @import "profile/networking-contacts";

  @import "page-chats";

  @import "page-register";
  @import "page-bad-move";
  @import "page-success";
  @import "page-fail";

  @import "example";

  @import "ticket-variations";

  


  // Just sample
  &.link-page{
    .btn{
      margin: 0 16px 16px 0;
      ~ .btn{
        margin: 0 16px 16px 0;
      }
    }
  }

  + .auth-wrap {
    .pass-form-wrapper{
      .label{
        display: flex;
        margin-bottom: 8px;
      }
    }
  } 

}

