.profile-image{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff1;
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  @include min-sm{
    width: 320px;
    height: 320px;
    min-width:320px;
    margin: 0 auto;
    padding: 0;
  }
  @include min-md{
    margin: 0;
  }
  .inner{
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    z-index: 1;
    top:0;
    right:0;
    bottom:0;
    left:0;
    align-items: center;
    justify-content: center;
    @include min-sm{
      position: relative;
      width: 320px;
      height: 320px;
    }
  }
}
.file-picker-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  @include min-sm{
    width: 320px;
    height: 320px;
  }
  .filte-item{
    &::before,
    &::after{
      content: "";
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
    &::before{
      background: linear-gradient(270deg, #1A1A1A 0%, rgba(26, 26, 26, 0) 100%);
      width: 56px;
      height: 100%;
      right: 0;
    }
    &::after{
      background: linear-gradient(0deg, #1A1A1A 0%, rgba(26, 26, 26, 0) 100%);
      width:100%;
      height: 32px;
      right: 0;
    }
  }
  .filte-img,
  .img-holder,
  .img-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    @include min-sm{
      width: 320px;
      height: 320px;
    }
  }
  .img-wrap{
    justify-content: center;
    position: absolute;
    @include min-sm{
      position: relative;
    }
    .extension-div{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    >img{
      object-fit:cover;
    }
  }

  .btn-edit-only{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border:0;
    outline: none;
    cursor: pointer;
    position: absolute;
    z-index:5;
    bottom: 24px;
    margin: auto;
    padding: 0;
    transition: all 0.25s ease-out;
    &.uploader{
      left:24px;
      transform: translate(0,0);
      background-color:$white;
      .magic-input.input-type-file{
        position: absolute;
        top:0;
        right:0;
        bottom: 0;
        left: 0;
        width: 48px;
        height: 48px;
        opacity: 0;
        transition: all 0.25s ease-out;
      }
      svg{
        width: 48px;
        height: 48px;
        border-radius: 50%;
        transition: all 0.25s ease-out;
        transform-origin: center;
      }
      &.add-new{
        left:50%;
        bottom:50%;
        transform: translate(-50%,50%);
        width: 120px;
        height: 120px;
        background-color:$white;
        .magic-input.input-type-file{
          width: 120px;
          height: 120px;
        }
        svg{
          width: 120px;
          height: 120px;
          border-radius: 50%;
          transition-delay: 0.15s;
        }
      }
    }
    &.del{
      right: 24px;
    }
  }
}