.messages{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	flex-grow: 1;
	@include vertical-scrollbar;
	overflow-x: hidden;
	>.inner{
		display: flex;
		flex-direction: column;
		padding: 0 20px;
		flex-grow: 1;
		@include max-xs{
			padding: 0 $gutter;
		}
		.new-message{
			display: flex;
			flex-grow: 1;
			align-items: center;
			justify-content: center;
			font-family:$Grotesque;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
			.txt{
				margin-bottom: $gutter*2;
				color:#B3B3B350;
			}
		}
	}
	.no-chat{
		display: flex;
		flex-grow: 1;
		align-items: center;
		justify-content: center;
		padding-bottom: 100px;
		font-family:$Grotesque;
		font-size: 14px;
		font-weight: 400;
		line-height: 17px;
		color:#B3B3B3;
	}
	.date-separator{
		width: 100%;
		display: flex;
		margin: 0;
		padding: 8px 0 24px;
		align-items: center;
		justify-content: center;
		font-family:$Grotesque;
		font-size: 14px;
		font-weight: 400;
		line-height: 17px;
		white-space: nowrap;
		&::before{
			content: "";
			width:10vw;
			height: 1px;
			display: flex;
			background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 100%);
			transform: translateX(-15px);
			@include min-xl{
				width:20vw;
			}
			
		}
		&::after{
			content: "";
			width:10vw;
			height: 1px;
			display: flex;
			background: linear-gradient(to right,  rgba(255,255,255,0.1) 0%,rgba(255,255,255,0) 100%);
			transform: translateX(15px);
			@include min-xl{
				width:20vw;
			}
		}
	}
	.message{
		margin-bottom: 16px;
		width: auto;
		display: flex;
		flex-direction: row;
		@include min-lg{
			max-width: 75%;
		}
		.avatar{
			width: 44px;
			height: 44px;
			min-width: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 8px;
			@include max-xs{
				width: 36px;
				height: 36px;
				min-width: 36px;
			}
			img{
				object-fit: cover;
				max-width: 44px;
				max-height:44px;
				min-width: 46px;
				@include max-xs{
					max-width: 36px;
					max-height: 36px;
				}
			}
		}
		.content-wrap{
			padding: $gutter;
			border-radius: 5px;
			@include max-xs{
				padding: 8px 10px;
			};
			p{
				margin: 0 0 8px;
				font-family:$Grotesque;
				font-size: 16px;
				font-weight: 400;
				line-height: 19px;
				@include max-xs{
					font-size: 14px;
					line-height: 21px;
					margin:0 0 3px;
				}
			}
			.time{
				display: flex;
				justify-content: flex-end;
				@include max-xs{
					font-size: 10px;
					opacity: 0.6;
				}
			}
		}
		&.received{
			.content-wrap{
				background: $grey-65;
			}
		}
		&.sent{
			margin-left: auto;
			.content-wrap{
				background: $grey-75;
			}
		}
	}
}