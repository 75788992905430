@import "../abstracts/breakpoints";
&.ticket-type{
  .header-section{
    .subtitle{
      font-family: $Grotesque;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.02em;
      text-align: left;
      margin: 20px 0 0;
    }
    .heading{
      &-5{
        @include etype(7);
        text-transform: uppercase;
        margin-bottom: 15px;
      }
    }
    .content{
      p{
        @include ptype-1("base");
        font-size: 18px;
        line-height: 22px;
        strong{
          @include ptype-1("base-strong");
          font-size: 18px;
          line-height: 22px;
        }
        a{
          @include ptype-1("base");
          font-size: 18px;
          line-height: 22px;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }

  .ticket-counter{
    margin: 0 0 0 40px;
    + .prices {
      margin-left: 20px;
    }
    @include max-lg {
      margin: 0 0 0 15px;
      + .prices {
        margin-left: 5px;
      }
    }
  }

  .payments{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin:68px 0 60px;
    .btn{
      &.btn-primary{
        align-items: center;
        .left{
          padding: 0 24px;
          .label{
            @include etype(3);
            line-height: 100%;
          }
          .pill{
            background-color: transparent;
            @include ptype-1("base-strong");
            color: $white;
            svg{
              display: none;
              color:$white;
            }
          }
        }
      }
      &.btn-grey{
        > div.left{
          .label{
            text-transform: initial;
          }
          .ico{
            height: 23px;
            svg{
              height: 21px;
              width: 20px;
            }
          }
        }
      }
    } 

  }

  .highlights{
    .subtitle{
      @include ptype-2("base");
      font-size: 24px;
      line-height: 26px;
      margin: 0;
    }
    .heading{
      &-5{
        @include etype(7);
        margin: 0 0 16px;
      }
    }
    .content{
      @include ptype-1("base");
      margin-bottom: 16px;
    }
  }

  .advertising{
    .advert{
      background: #171B3A;
      width: 100%;
      min-height: 400px;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 32px;
      overflow:hidden;
      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
        border-radius: 32px;
      }
      p{
        @include ptype-1("base");
      }
    }
  }

}