.title{
	display: flex;
	min-height: 93px;
	padding: 18px;
	align-items: center;
	justify-content: flex-start;
	@include max-sm{
		padding: $gutter;
		min-height: auto;
	}
	.btn.btn-grey{
		width: 46px;
		height: 46px;
		min-width: 46px;
		overflow:hidden;
		margin-bottom: 0;
		margin-right: 8px;
		border-radius: 50%;
		@include max-xs{
			width: 36px;
			height: 36px;
			min-width: 36px;
		}
		.left{
			.ico{
				margin-right: 0;
				svg{
					height: 24px;
					@include max-xs{
						height: 20px;
					}
				}
			}
			.label{
				display: none;
			}
		}
		@include min-md{
			display: none;
		}
	}
	.content-wrap{
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		.job{
			@include max-xs{
				display: none;
			}
		}
		+ .btn.btn-grey{
			margin: 0;
		}
	}
	.avatar{
		width: 44px;
		height: 44px;
		min-width: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 8px;
		@include max-xs{
			width: 36px;
			height: 36px;
			min-width: 36px;
		}
		img{
			object-fit: cover;
			max-width: 44px;
			max-height: 44px;
			@include max-xs{
				max-width: 36px;
				max-height: 36px;
			}
		}
	}
	.fullname{
		@include etype(2);
		margin: 0 0 4px;
	}
	.job{
		font-family:$Grotesque;
		font-size: 14px;
		font-weight: 400;
		line-height: 17px;
		color:#B3B3B3;
	}
}