
//generate ptype classes
$variants : "regular", "strong", "light", "italic", "mono";

@each $name, $size in $ptypeFontSizes {
  @each $variant in $variants{
    .ptype#{$name}-#{$variant}{
      @include ptype($name, $variant);
    }
  }
}

//without size
@each $variant in $variants{
  .ptype-#{$variant}{
    @include ptype(0, $variant);
  }
}

//without variant
@each $name, $size in $ptypeFontSizes {
  .ptype#{$name}{
    @include ptype($name, "regular");
  }
}

// generate etype classes

@each $name, $size in $etypeFontSizes {
  .etype-#{$name}{
    @include etype($name);
  }
}


.etype-1{
    @include etype-core;
    font-size: 16px;
    line-height: 19px;
  }
  .etype-2{
    @include etype-core;
    font-size: 20px;
    line-height: 24px;
  }
  .etype-3{
    @include etype-core;
    font-size: 24px;
    line-height: 29px;
  }
  .etype-4{
    @include etype-core;
    font-size: 28px;
    line-height: 32px;
  }
  .etype-5{
    @include etype-core;
    font-size: 32px;
    line-height: 36px;
  }
  .etype-6{
    @include etype-core;
    font-size: 38px;
    line-height: 42px;
  }
  .etype-7{
    @include etype-core;
    font-size: 44px;
    line-height: 48px;
  }
  .etype-8{
    @include etype-core;
    font-size: 50px;
    line-height: 52px;
  }
  .etype-9{
    @include etype-core;
    font-family: $prometo;
    font-size: 56px;
    line-height: 59px;
  }
  .etype-10{
    @include etype-core;
    font-size: 64px;
    line-height: 64px;
  }

  .ptype-1-plus-strong{
    font-family: $Grotesque;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }
  
  .ptype-2-400{
    @include ptype-2-core;
    font-weight: 400;
  }
  .ptype-2-700{
    @include ptype-2-core;
    font-weight: 700;
  }
  
  .ptype-1-300{
    @include ptype-1-core;
    font-weight: 300;
  }
  .ptype-1-400{
    @include ptype-1-core;
    font-weight: 400;
  }
  .ptype-1-500{
    @include ptype-1-core;
    font-weight: 500;
  }
  .ptype-1-700{
    @include ptype-1-core;
    font-weight: 700;
  }
  .ptype-1-900{
    @include ptype-1-core;
    font-weight: 900;
  }
  .ptype-1-base{
    @include ptype-1-core;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
  .ptype-1-base-strong{
    @include ptype-1-core;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }

  .ptype-base{
    @include ptype-core;
    font-weight: 400;
  }
  .ptype-base-strong{
    @include ptype-core;
    font-weight: 700;
  }
  