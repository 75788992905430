.header-section{
  padding-top: 24px;
  .subtitle{
    font-family:$Grotesque;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin: 16px 0 0 0;
  }
  .title{
    margin:4px 0 $gutter*2 0;

  }
}
.section-title {
  margin-bottom: 16px;
  &:not(.no-mt-top) {
    margin-top: 40px;
  }
}
.card-type{
  .payed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #24A148;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px;
    margin-top: 40px;
  }
  
  .amount-to-be-paid {
    background: #000000;
    padding: 15px;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .billing-profile {
    background: #000000;
    padding: 15px;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 16px;
    svg{
      margin-right: 4px;
      path{
        stroke:$white;
      }
    }
  }

  .top-section{
    border-bottom: none;
  }

  .middle{
    &-1,
    &-2{
      padding: 0px 24px;
    }
  }
  .invoice-buttons {
    margin-top: 40px;
    padding: 0px 24px;
    svg{
      margin-right: 4px;
      height: 22px;
      width: 22px;
      path{
        stroke:$white;
      }
    }
  }
  .related-to {
    .event-img {
      border-radius: 50%;
      width: 83px;
      height: 83px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #333333;
      margin-right: 14px;
      img {
        object-fit: cover;
        max-height: 85px;
      }
    }
  }
  // .payment {
  //   margin-bottom: 40px;
  //   .payment-method {
  //     display: flex;
  //     padding: 15px;
  //     margin: 16px 0;
  //     align-items: center;
  //     border: 1px solid #808080;
  //     box-sizing: border-box;
  //     border-radius: 8px;
  //     .logo {
  //       margin-right: 15px;
  //     }
  //   }
  // }
  .issues {
    .subtitle {
      margin-top: 24px;
      margin-bottom: 22.5px;
    }
    .email {
      margin-bottom: 21px;
    }
  }
}

.card {
  &.related-to {
    .event-img {
      border-radius: 50%;
      min-width: 83px;
      width: 83px;
      height: 83px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #333333;
      margin-right: 14px;
      img {
        object-fit: cover;
        max-height: 85px;
      }
    }
  }
  &.issues {
    .subtitle {
      margin-top: 24px;
      margin-bottom: 22.5px;
    }
    .email, .phone {
      display: flex;
      align-items: center;
      svg {
        margin-right: 9px;
        path {
          stroke: white;
        }
      }
    }
    .email {
      margin-bottom: 21px;
    }
  }
  &.invoice-detail{
    .title {
      margin-bottom: 24px;
    }
    .reserved-tickets {
      .pass-ticket-wrapper.with-cutouts {
        margin-bottom: 16px;
        &::before, &::after {
          background: #1A1A1A;
        }
      }
    }
  }
}

.reserved-tickets {
  &:not(.dotted-line) {
    padding: 0;
  }
  .pass-ticket-wrapper.with-cutouts {
    margin-bottom: 16px;
    &::before, &::after {
      background: #1A1A1A;
    }
  }
}

.payment {
  margin-bottom: 40px;
  .payment-method {
    display: flex;
    padding: 15px;
    margin: 16px 0;
    align-items: center;
    border: 1px solid $grey-55;
    border-radius: 8px;
    .logo {
      margin-right: 15px;
    }
  }
  .billing-methods {
    display: flex;
    padding: 15px;
    background: $black;
    margin: 16px 0;
    align-items: center;
    border: 1px solid $grey-75;
    border-radius: 8px;
    svg {
      margin-right: 16px;
    }
  }
}