.card.video-card{

  &.title,
  &.user{
    .react-player__preview{ 
      svg{
        transform: translateY(-72px);
        position: absolute;
        z-index: 5;
        opacity: 0;
      }
    }
  }

  .video-holder{
    position: relative;
  }

  &.playing{
    .cover-block{
      background-image: none!important;
      .inner{
        >svg{
            opacity:0;
          }
        &.video-icon{
          >svg{
            opacity:0;
          }
        }
        .heading-2,
        .user-block{
          transform: translateY(20px);
          opacity: 0;
        }
        .btn.btn-active{
          margin: 8px;
          .label{
            display: none;
          }
          &:hover{
            .label{
              display: inline-flex;
            }
          }
        }  
      }
    }   
  }

  .cover-block{
    position: absolute;
    z-index: 1;
    top:0;
    right:0;
    bottom:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    opacity: 1;
    transition: all 0.35s ease-out;
    background-size: cover;
    .inner{
      position: absolute;
      top:50%;
      margin-top: 5px;
      left:0;
      right:0;
      bottom:0;
      text-align: center;
      >svg{
        transform: translateY(-72px);
        opacity: 1;
        transition:opacity 0.35s ease-out;
        transition-delay: 0.4s;
      }
      &.video-icon{
        top:0;
        padding-top:15%;
        >svg{
          transform: translateY(0);
          opacity: 1;
          transition:opacity 0.35s ease-out;
          transition-delay: 0.4s;
          &:hover{
            cursor: hand;
          }
        }
      }
      .heading-2{
        @include etype(6);
        text-align: center;
      }
      .btn{
        font-family:$Grotesque;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        pointer-events: all;
        .pill{
          background: $black;
          border-radius: 4px;
          color: $white;
          svg{
            display: none;
          }
        }
      }
    }

    .user-block{
      display: flex;
      justify-content: center;
      margin-top: 8px;
      .user-circle{
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow:hidden;
        margin-right: 12px;
        img{
          object-fit: cover;
        }
      }
      .name{
        display: flex;
        align-items: center;
        font-family: $Grotesque;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }

    .heading-2,
    .user-block{
      opacity: 1;
      transform: translateY(0);
      transition: all 0.35s ease-out;
      transition-delay: 0.8s;
    }

    .btn{
      position: absolute;
      left:0;
      bottom:0;
      margin: 40px;
    }
  }
}