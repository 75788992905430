
@mixin color-modifier($attribute: 'color') {
  @each $name, $hex in $allSimpleColors {
    &-#{$name} {
      #{$attribute}: $hex;
    }
  }
}

@mixin svg-color-modifier() {
  @each $name, $hex in $allSimpleColors {
    &-#{$name} {
      stroke: $hex;
      fill: $hex;
    }
  }
}

@mixin color-button-modifier($attribute: 'color') {
  @each $name, $hex in $allSimpleColors {
    &-#{$name} {
      #{$attribute}: $hex;
      cursor: pointer;
      transition: all 0.2s ease-out;
      &:hover{
        #{$attribute}: lighten($hex, 20%);
      }
      &:focus{
        #{$attribute}: lighten($hex, 10%);
      }
      &:active{
        #{$attribute}: darken($hex, 10%);
      }
    }
  }
}

@mixin bg-button-modifier($attribute: 'color') {
  @each $name, $hex in $allSimpleColors {
    &-#{$name} {
      #{$attribute}: $hex;
      cursor: pointer;
      transition: all 0.2s ease-out;
      &:hover{
        @if $hex == #ECF5FF or $name == "grey-50" {
          #{$attribute}: lighten($hex, 2%);
        } @else {
          #{$attribute}: lighten($hex, 10%);
        }
      }
      &:focus{
        #{$attribute}: lighten($hex, 10%);
      }
      &:active{
        #{$attribute}: darken($hex, 10%);
      }
    }
  }
}

@mixin border-modifier($width: 1) {
  @each $name, $hex in $allSimpleColors {
    &-#{$name} {
      border: #{$width}px solid $hex;
    }
  }
}

@mixin disabled-color-modifier($attribute: 'color') {
  @each $name, $hex in $allSimpleColors {
    &-#{$name} {
      #{$attribute}: lighten($hex, 20%);
    }
  }
}

@mixin border-radius($value: 4){
  border-radius: #{$value}px;
}

@mixin txt-modifier() {
  @each $name, $hex in $allBmGradients {
    &-#{$name} {
      background: $hex;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

@each $name, $hex in $allSimpleColors {
  .#{$name} {
    color: $hex;
  }
}

.bg{
  @include color-modifier($attribute: 'background-color');
}

.color{
  @include color-modifier($attribute: 'color');
}


.stroke{
  @include svg-color-modifier();
}

.fill{
  @include color-modifier($attribute: 'fill');
}

.btn-txt{
  @include color-button-modifier($attribute: 'color');
  text-decoration: none;
}

.btn-bg{
  @include bg-button-modifier($attribute: 'background-color');
}

// .stroke{
//   @include color-modifier($attribute: 'stroke');
// }

.border{
  @include border-modifier($width: 1);
}

.txt-bg{
  @include txt-modifier();
}

//THE CODE ABOVE WILL GENERATE THE CLASSES BELOW

// .bg-all-good{
//   background-color: $all-good;
// }

// .all-good{
//   color: $all-good;
// }
// .bg-grey-50{
//   background-color: $grey-50;
// }
// .bg-grey-40{
//   background-color: $grey-40;
// }
// .bg-grey-20{
//   background-color: $grey-20;
// }
// .bg-grey-10{
//   background-color: $grey-10;
// }
// .bg-grey-16{
//   background-color: $grey-16;
// }

// .bg-button-prime{
//   background-color: $button-prime;
// }
// .bg-green-40{
//   background-color: $green-40;
// }
// .bg-button-focus{
//   background-color: $button-focus;
// }

// .alert-red{
//   color: $alert-red;
// }

// .alert-danger{
//   color: $alert-danger;
// }

// .grey-50 {
//   color: $grey-50;
// }

// .grey-40 {
//   color: $grey-40;
// }

// .grey-20 {
//   color: $grey-20;
// }

// .grey-10 {
//   color: $grey-10;
// }

// .grey-16 {
//   color: $grey-16;
// }


// .white {
//   color: $white;
// }

// .black {
//   color: $black;
// }

// .red-10 {
//   color: $red-10;
// }

// .steel-grey {
//   color: $steel-grey;
// }

// .text-light-01 {
//   color: $text-light-01;
// }

// .text-light-02 {
//   color: $text-light-02;
// }

// .text-light-03 {
//   color: $text-light-03;
// }


// .button-prime {
//   color: $button-prime;
// }

// .hover-button-prime {
//   color: $hover-button-prime;
// }

// .click-button-prime {
//   color: $click-button-prime;
// }

// .button-secondary {
//   color: $button-secondary;
// }

// .hover-button-secondary {
//   color: $hover-button-secondary;
// }

// .click-button-secondary {
//   color: $click-button-secondary;
// }

// .button-focus {
//   color: $button-focus;
// }

// .hover-button-focus {
//   color: $hover-button-focus;
// }

// .click-button-focus {
//   color: $click-button-focus;
// }

// .placeholderBlue {
//   color: $placeholderBlue;
// }

// .green-40 {
//   color: $green-40;
// }

// .green2 {
//   color: $green2;
// }

// .magenta-60 {
//   color: $magenta-60;
// }

// .teal-60 {
//   color: $teal-60;
// }

// .yellow-60 {
//   color: $yellow-60;
// }

// .purple-60 {
//   color: $purple-60;
// }

