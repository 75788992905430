&.login-modal{
  .top{
    display: flex;
    flex-direction: column;
    align-items: center;
    >svg{
      margin-bottom: $gutter * 2;
    }
    .heading-3{
      @include etype(5);
    }
  }
  .inner{
    overflow:hidden;
    
    >.step-wrap{
      display: flex;
      flex-direction: row;
      width: 300%;
      transition: all 0.15s ease-out;
      .step{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 260px;
        @include vertical-scrollbar(auto);
        .paragraph{
          display: flex;
          justify-content: center;
          align-items: center;
          @include ptype(0,"base");
          margin-bottom: $gutter*2;
        }
        .bottom{
          .space-between{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: $gutter*2 0;
            align-items: center;
          }
          .btn{
            &.btn-secondary-active{
              >div{
                min-width: 120px;
              }
            }
          }
        }
        &.two{
          .login-options{
            .inputBtn{
              width: 100%;
              height: 48px;
              background: $grad-warn;
              display: flex;
              margin-top: 22px;
              border-radius: 4px;
              overflow:hidden;
              align-items: center;
              padding: 1px;
              cursor: pointer;
              >div{
                background: $black;
                width: 100%;
                height: 46px;
                border-radius: 4px;
                overflow:hidden;
                display: flex;
                align-items: center;
                padding: 0 15px;
                line-height: 32px;
                font-size: 16px;
                svg{
                  margin-right: 12px;

                }
              }
            }
          }
        }
        &.tree{
          .form-item{
            margin-top: 14px;
            .label{
              display: flex;
              margin-bottom: 4px;
            }
          }
          .text-input{
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            height: 48px;
            border-radius: 4px;
            overflow: hidden;
            background: $grad-warn;
            padding: 1px;
            >input{
              width: 100%;
              height: 46px;
              display: flex;
              background: $black;
              border-radius: 4px;
              overflow: hidden;
              padding:0 16px 0 60px;
              border:0;
              outline: none;
              color:$white;
              @include ptype(0,"base");
              @include placeholder($white,0.25);
            }
            svg{
              position: absolute;
              z-index: 1;
              top:0;
              left:0;
              pointer-events: none;
              margin:7px 0 0 15px;
            }
          }
          .btn.btn-link{
            .left{
              padding-left:0;
            }
          }
        }
      }
    }
    &.step{
      &-0{
        >.step-wrap{
          transform: translateX(0);
        }
      }
      &-1{
        >.step-wrap{
          transform: translateX(-33.3333%);
        }
      }
      &-2{
        >.step-wrap{
          transform: translateX(-66.6667%);
        }
      }
    }

    
  }
}