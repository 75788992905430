.initials{
  background: $black;
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  overflow:hidden;
  align-items: center;
  justify-content: center;
  padding: 1px;
  @include ptype-1("regular");
  color:$white;
  &.primary{
    background: $grad-btn;
  }
  &.secondary{
    background: $grad-good;
  }
  >div{
    background-color: $black;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow:hidden;
  }
}