.avatar-uploader{
  background-color: $black;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  img{
    height: 175px;
  }
  .type-file{
    position: absolute;
    z-index: 2;
    top:0;
    right: 0;
    bottom:0;
    left:0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .load-btn{
    width:64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $grad-good;
    position: absolute;
    z-index: 1;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
    .inner{
      padding: 2px;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow:hidden;
      background-color: $black;
      svg{
        transform: translate(-2px,-2px);
      }
    }
  }
}