.article-video{
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  transition: all 0.25s ease-out;
  overflow:hidden;
  border-radius: 4px;
  margin-bottom: 30px;
  &.playing{
    .cover-block{
      opacity:0;
    }
  }
  >div{
    position: absolute;
    top:0;left:0;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &.cover-block{
      z-index: 2;
      pointer-events: none;
      opacity:1;
      background-position: center;
      background-size: cover;
      transition: all 0.25s ease-out;
    }
  }
  
}

.top-content{
  .article-title{
    @include etype(7);
    margin-top:-6px;
    margin-bottom: 16px;
  }
  .text-container{
    .content{
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      @include ptype-1("base-strong");
      p,ul{
        margin: 0 0 20px;
      }
    }
  }
}







.editor-content{
  display: flex;
  flex-direction: column;
  .article-title{
    @include etype(5);
    margin-top: 0;
    margin-bottom: 24px;
  }

  .text-container{
    .content{
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      @include ptype(0,"base");
      p,ul{
        margin: 0 0 20px;
      }
    }
  }
  .card{
    &.card-tip{
      padding:40px 40px 16px;
      margin-bottom: 40px;
      .article-title{
        @include etype(2);
        margin: 0 0 16px;
      }
      .content{
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        @include ptype(0,"base");
        p,ul{
          margin: 0 0 24px;
        }
      }
      .document-description{
        display: flex;
        .icon{
         margin-right: 12px; 
        }
        .text{
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0 0 24px;
          >div{
            @include ptype(0,"base");
            font-weight: 700;
            + div{
              font-weight: 400;
            }
          }
        }
      }
    }
  }


  .card-quote-wrap{
    padding-top: 42px;
    position: relative;
    margin-bottom: 40px;
    .quote-icon{
      width: 78px;
      height: 65px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0 0;
      position: absolute;
      top:0;
      left:40px;
    }
    .card-quote{
      border-radius: 8px;
      overflow: hidden;
      background: $grad-warn;
      display: flex;
      padding: 4px;
      .inner{
        background: $grey-85;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 60px 40px 40px;
        .author-name{
          @include ptype(0,"base");
          font-weight: 700;
        }
        .position-held{
          @include ptype(0,"base");
        }
      }
      .content{
        @include etype(2);
        margin-bottom: 40px;
        p{
          margin: 0;
        }
      }
    }
  }
}