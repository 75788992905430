.card.browse-card-type{
  margin-bottom: $gutter * 2;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  justify-content: flex-start;
  .wrapper{
    .browse-card{
      padding: 0 $gutter * 2;
      width: 100%;
      .content-wrap{
        padding-left: 0;
        padding-right: 0;
      }
    }
    .connections{
      background: $black;
      padding: 16px $gutter * 2;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .items{
        display: flex;
        flex-direction: row-reverse;
        width: 60px;
      }
      .item{
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        margin-left: -8px;
        >img{
          display: flex;
          object-fit: cover;
          width: 24px;
        }
      }
      .content{
        padding-left: 10px;
        font-family: $Grotesque;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 11px;
        letter-spacing: 0em;
        text-align: center;
      }
    }
    .read-btn-wrap{
      margin-top: $gutter * 2;
      padding: 0 $gutter * 2;
    }
  }
}