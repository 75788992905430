.savings{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .btn{
    margin-bottom: 24px;
    >div{
      padding: 12px 24px;
    }
    &.btn-primary{
      >div{
        background: $grad-btn;
      }
      .pill{
        background: $black;
        color:$white;
      }
    } 
    &.btn-secondary{
      background: $black;
      .pill{
        background: $grad-btn;
        color:$white;
      }
    }
    .pill{
      border-radius: 3px;
      >svg{
        display: none;
      }
    }
  }
}

.attending-list{
  display: flex;
  height: 48px;
  margin-bottom: 24px;
  background: $grad-btn;
  padding:1px;
  border-radius: 8px;
  .inner{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black;
    border-radius: 8px;
    padding:13px 30px;
    >span{
      text-align: center;
      font-family: $Grotesque;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 11px;
      letter-spacing: 0em;
      color: $white;
    }
  }
  .img-array{
    display: flex;
    flex-direction: row-reverse;
    padding-right: 8px;
    .attend{
      margin-left: -8px;
      width:24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

  }
}