@mixin user-menu {
  .user-menu{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: -8px;
    right: 0;
    background: $grey-90;
    border-radius: 8px;
    width: 308px;
    border: 1px solid $accent-1;
    transform: translateY(100%);
    // stroke: none;
    padding: 8px 32px 8px 32px;
    >div{
      height : 56px;
    }
    .spacer{
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-right: -16px;
    }
    .menu-item{
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $grey-30;
      stroke: $grey-30;
     
      &:hover{
        color:$white;
        stroke: $white;
      }
    }
    z-index: 2000;
    .flex{
      align-items: center;
      justify-content: space-between;
    }
    .logout{
      margin-top: 8px;
      display: flex;
      align-items: center;
      color: $grey-30;
      stroke: $grey-30;
      &:hover{
        color:$white;
        stroke: $white;
      }
    }
  }
}


.simple-top-button{
  height: 56px;
  background-color: $grey-80;
  transition: all ease-in-out 200ms;
  &:hover{
    background-color: lighten($grey-80, 10%);
  }
  &:active{
    background-color: darken($grey-80, 3%);
  }  border-radius: 8px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  >svg + .red-pill{
    margin-left: 4px;
  }
  .red-pill{
    background-color: $alert-red;
    padding: 0 12px;
    height: 23px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    @include ptype(0, "strong");
    color: $white;
  }
}

.bm-header{
  background-color: $grey-90;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-60;
  stroke: white;
  .logged-in-user-wrapper{
    position: relative;
    height: 56px;
    border-radius: 8px;
    background-color: $grey-80;
    display: flex;
    place-items: center ;
    padding-left: 16px;
    @include ptype(0, "strong");
    @include user-menu;
    transition: all ease-in-out 200ms;
    &:hover{
      background-color: lighten($grey-80, 3%);
      .user-coins{
        background-color: lighten($accent-4-80, 12%);
      }
    }
    >svg{
      margin-right: 4px;
    }
    // padding: 8px 16px;
    // .initials{ //element eliminated
    //   background: $black;
    //   flex: 0 0 40px;
    //   height: 40px;
    //   border-radius: 50%;
    //   border: 1px solid $green-3;
    //   display: grid;
    //   place-items: center;
    //   margin-right: 8px;
    // }
    .user-coins{
      display: flex;
      align-items: center;
      align-self: stretch;
      margin-left: 16px;
      padding-left: 16px;
      padding-right: 16px;
      color : #333333;
      background-color: $accent-4-80;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

    }
  }
  &:not(.not-fixed){
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 90;    
  }
  .left,.right{
    display: flex;
  }
  .right{
    .custom-select-brand{
      .wrapper{
        width: 248px;
      }
    }
  }
  .hamburger{
    position: relative;
    width: 96px;
    height: 80px;
    display: flex;
    background-color: transparent;
    border:0;
    padding:0;
    outline: none;
    cursor: pointer;
    &:hover{
      background-color: $black;
    }
    .icoholder{
      position: absolute;
      z-index: 1;
      width: 32px;
      height: 32px;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
    }
    .menu,.cancel{
      position: absolute;
      z-index: 1;
      transform:rotate(0deg);
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.25s ease-out;
      transform-origin: center;
      svg{
        path{
          stroke:$white;
        }
      }
    }
    .menu{
      opacity:1
    }
    .cancel{
      opacity:0;
    }
    &.active{
      .menu{
        opacity:0;
        transform: rotate(-180deg);
      }
      .cancel{
        opacity:1;
        transform: rotate(-180deg);
      }
    }
  }
  .logo{
    cursor: pointer;
    margin: 20px 24px;
  }
  .actions{
    display: flex;
    .action{
      background: transparent;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.25s ease-out;
      &:hover{
        background: $grad-btn;
      }
      svg{
        path{
          stroke: $white;
        }
      }
    }
  }
  .user-related{
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 16px;
    .user-img{
      margin-left: 16px;
      width: 48px;
      height: 48px;
      min-width: 48px;
      display: flex;
      border-radius: 50%;
      align-items:center;
      justify-content: center;
      overflow:hidden;
      img{
        max-width: 48px;
        max-height: 48px;
        display: flex;
      }
    }
    &.unloggged{
      .btn{
        margin-bottom: 0;
        margin-right: 0;
        &:not(:first-of-type){
          margin-left: 16px;
        }
        text-transform: initial;
        svg{
          width: 32px;
          height: 32px;
          margin-left: -10px;
        }
      }
    }
  }
}