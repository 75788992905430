@mixin fill-with-hover($color){
    fill : $color;
    &:hover{
        fill: lighten($color, 10%);
    }
}
.info-icon-wrapper{
  transition: all 0.2s ease-out;
  position: relative;
  display: inline-block;
  align-items: center;
  &.grey{
    @include fill-with-hover(#454961);
  }
  &.yellow{
    @include fill-with-hover(#FFC100);
  }
  &::after{
    min-width: 8rem;
  }
}