&.ticket-variations {
  .counter-wrapper{
      display: flex;
      flex-wrap: nowrap;
      justify-content:space-between;
      align-items: center;
      background: $grey-75;
      margin-bottom:10px;
      border-radius: 8px;
      padding: 16px $gutter*2;
      border:0;
      box-shadow: 0 0 0 1px #fff2 inset;
      overflow: hidden;
      position: relative;
      &::before,
      &::after{
        content:"";
        position:absolute;
        z-index: 1;
        background: $grey-85;
        width: 10px;
        height: 10px;
        display: flex;
        box-shadow: 0 0 0 1px #fff4 inset;
        border-radius: 50%;
        top:50%;
      }
      &::before{
        left:0;
        transform: translate(-4px,-4px);
      }
      &::after{
        right:0;
        transform: translate(4px,-4px);
      }
      &:last-child{
        margin-bottom: 8px;
      } 
      .ticket-counter {
        justify-content: flex-end;
        .c-btn {
          display: none;
        }
      }
      .title {
        @include etype(2);
      }
      .prices {
        align-items: flex-start;
      }
    }
}