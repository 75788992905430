.custom-check{
  &-slide{
    display: flex;
    margin-bottom: 17px;
    .checkbox-label{
      display: flex;
    }
    input[type="checkbox"]{
      display: none;
      &:checked{
        +.ico{
          background: $grad-good-reverse;
          &::before{
            left:20px;
          }
        }
      }
    }
    .ico{
      background: $grey-60;
      width: 40px;
      height: 20px;
      display: flex;
      margin-bottom: auto;
      border-radius: 10px;
      margin-right: $gutter;
      padding: 2px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      transition: all 0.15s ease-out;
      &::before{
        content: "";
        background: $white;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        top:0;
        left:0;
        margin: 2px;
        transition: all 0.15s ease-out;
      }
    }

    p{
      margin: 0;
      font-family: $Grotesque;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }

    &.large {
      .ico {
        width: 48px;
        height: 28px;
        border-radius: 120px;
        &::before{
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}