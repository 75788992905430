html,body{
  margin: 0;
  padding:0;
  @include ptype-1("medium");
  line-height:unset;
  color:$white;
  min-height: 100vh;
  background: $black;
}

*,*::before,*:after{
  box-sizing: border-box;
}

a:not(.btn){
  color: #4a91c3;
  text-decoration: none;
  outline: none;
  &:hover{
    color:#1089dd;
    text-decoration: none;
    outline: none;
  }
}

p{
  a{
    padding: 0 3px;
  }
}

.heading{
  &-1,
  &-2,
  &-3,
  &-4,
  &-5,
  &-6{
    margin: 0;
  }
}

