&.payment-method-page{
  position: relative;
  padding-bottom: 170px;
  @include min-lg{
    padding-right:560px;
    padding-bottom: 220px;
  }

  .header-section{
    &.billing-process{
      padding-top: 40px;
      padding-bottom: 20px;
      .heading{
        &-2{
          font-family:$Grotesque;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
        }
        &-5{
          @include etype(5);
          text-transform: uppercase;
          margin: 0 0 10px;
        }
      }
    }
  }

  .separator{
    width: calc(100% + #{$gutter*4});
    height: 1px;
    position: relative;
    margin-left: -$gutter*2;
    margin-bottom: $gutter;
  }
 
}

.payment-content{
  margin-bottom: $gutter*2;
  .heading{
    &-3{
      @include etype(3);
      margin: 16px 0 28px;
    }
  }
  .wrapper{
    @include max-sm{
      padding: 0;
    }
    .heading-3{
      margin: 0 0 $gutter*2;
      display: flex;
      align-items: center;
      .secure-svg{
        margin-right: 10px;
      }
    }
  }
}

.payment-tab{
  &-head{
    .container-fluid{
      &.no-gutters{
        >.row{
          margin-right: -8px;
          margin-left: -8px;
          width: calc(100% + 16px);
        }
      }
    }
    .tab-head{
      padding: 0 8px !important;
      display: flex;
      flex-direction: column;
      color: $white;
      .pay-btn{
        width: 100%;
        border:0;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 80px;
        border-radius: 8px;
        cursor: pointer;
      }
    }

    &.active{
      &-1{
        .tab-head{
          .pay-btn{
            background: $grad-good;
          }
          ~ .tab-head{
            .pay-btn{
              background: $black;
              border: 1px solid $grey-75;
            }
          }
        }
      }
      &-2{
        .tab-head{
          .pay-btn{
            background: $black;
            border: 1px solid $grey-75;
          }
          + .tab-head{
            .pay-btn{
              background: $grad-good;
            }
            + .tab-head{
              .pay-btn{
                background: $black;
                border: 1px solid $grey-75;
              }
            }
          }
        }
      }
      &-3{
        .tab-head{
          .pay-btn{
            background: $black;
            border: 1px solid $grey-75;
          }
          + .tab-head{
            .pay-btn{
              background:$black;
              border: 1px solid $grey-75;
            }
            + .tab-head{
              .pay-btn{
                background: $grad-good;
              }
            }
          }
        }
      }
    }
  }
  &-content{
    margin-bottom: $gutter*2;
    .wrapper{
      .last{
        p{
          margin:$gutter*2 0 0;
        }
      }
      @include max-sm{
        padding: 0;
      }
      .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        .left{
          .heading{
            &-3{
              @include etype(3);
              margin: 0;
            }
          }
        }
        .right{
          color: $grey-30;
          align-items: flex-end;
          .text{
            display: flex;
            .info{
              margin-left: 8px;
              &.opened .tooltip {
                right: 30px;
              }
              svg{
                path{
                  fill:#4d4d4d;
                }
              }
            }
          }
        }
      }
      .heading-3{
        margin: 0 0 $gutter*2;
        display: flex;
        align-items: center;
        .secure-svg{
          margin-right: 10px;
        }
      }
      
    }
  }
}

.payment-card{
  .form-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin-left: -8px;
    .magic-input .inputContainer .form-control input{
      padding-right: 6px;
    }
    .input-card{
      padding: 0 8px;
      &.card{
        &-nr{

          @include min-sm{
            width: 70%;
          }
          @include min-md{
            width: 50%;
          }
          @include min-xl{
            width: 30%;
          }
        }
        &-expire{
          @media (min-width:500px){
            width: 50%;
          }
          @include min-sm{
            width: 30%;
          }
          @include min-md{
            width: 25%;
          }
          @include min-xl{
            width: 15%;
          }
        }
        &-code{
          @media (min-width:500px){
            width: 50%;
          }
          @include min-sm{
            width: 30%;
          }
          @include min-md{
            width: 25%;
          }
          @include min-xl{
            width: 15%;
          }
        }
        &-name{
          @include min-sm{
            width: 70%;
          }
          @include min-md{
            width: 100%;
          }
          @include min-xl{
            width: 40%;
          }
        }
      }
    }
  }
}