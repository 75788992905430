$brakepoints : (
  xs  : 376px,
  sm  : 768px,
  md  : 1024px,
  lg  : 1200px,
  xl : 1440px
);

$width-xs   : 376px;
$width-sm   : 768px;
$width-md   : 1024px;
$width-lg   : 1200px;
$width-xl   : 1440px;

// MIN
// Min-0
@mixin min-xs {
  @media (min-width: #{$width-xs}) {
    @content;
  }
}

// Min-768
@mixin min-sm {
  @media (min-width: #{$width-sm}) {
    @content;
  }
}

// Min-992
@mixin min-md {
  @media (min-width: #{$width-md}) {
    @content;
  }
}

// Min-1200
@mixin min-lg {
  @media (min-width: #{$width-lg}) {
    @content;
  }
}

// Min-1440
@mixin min-xl {
  @media (min-width: #{$width-xl}) {
    @content;
  }
}

// MAX
// MAX-375
@mixin max-xs {
  @media (max-width: #{$width-xs - 1px}) {
    @content;
  }
}

// MAX-767
@mixin max-sm {
  @media (max-width: #{$width-sm - 1px}) {
    @content;
  }
}

// MAX-1023
@mixin max-md {
  @media (max-width: #{$width-md - 1px}) {
    @content;
  }
}

// MAX-1199
@mixin max-lg {
  @media (max-width: #{$width-lg - 1px}) {
    @content;
  }
}

// MAX-1439
@mixin max-xl {
  @media (max-width: #{$width-xl - 1px}) {
    @content;
  }
}

// EXACT
@mixin only-xs {
  @media (max-width: #{$width-sm - 1px}) {
    @content;
  }
}
@mixin only-sm {
  @media (min-width: #{$width-sm}) and (max-width: #{$width-md - 1px}) {
    @content;
  }
}
@mixin only-md {
  @media (min-width: #{$width-md}) and (max-width: #{$width-lg - 1px}) {
    @content;
  }
}
@mixin only-lg {
  @media (min-width: #{$width-lg}) and (max-width: #{$width-xl - 1px}) {
    @content;
  }
}
@mixin only-xl {
  @media (min-width: #{$width-xl}) {
    @content;
  }
}