.premium-textarea{
  display: flex;
  flex-direction: column;

  &.dark{
    // stroke: $white;
    transition: all 0.2s ease-out;
    width: 100%;
    .label {
      color:$white;
      @include ptype-1("regular","normal");
      margin-bottom: 0.5rem;
    }
    .magic-textarea{
      background-color: $black;
      border: 1px solid $grey-75;
      &:focus,
      &:focus-within {
        border: 1px solid $accent-2;
      }
      
    }
  }
  &.error {
    .magic-textarea{
      border-color: $alert;
    }
  }
  .label {
    color:$white;
    @include ptype-1("regular","normal");
    margin-bottom: 0.5rem;
  }

  .magic-textarea{
    padding:1rem 1rem;
    color: $white;
    background-color: $grey-75;
    border: 1px solid $grey-75;
    border-radius: 8px;
    overflow:hidden;
    resize: vertical;
    @include ptype-1("regular","normal");
    outline: none;
    &:focus,
    &:focus-within {
      border: 1px solid $accent-2;
    }
  }
}