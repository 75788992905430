.lock-bg{
  background: linear-gradient(180deg,#0000 0%, #000 55%);
  position: fixed;
  z-index: 91;
  top:0;
  right:0;
  bottom:0;
  left:0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  user-select: none;
  .lock-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20vh;
    opacity:0;
    transition: all 0.35s ease-out;
    animation: fade-in-screen 0.4s ease-out 0.4s forwards;
    .heading{
      &-3{
        @include etype(9);
        margin: $gutter * 2 0 8px;
      }
    }
    .content{
      @include ptype(0,"base");
      margin: 0 0 16px;
    }
    .btn{
      &.btn-active{
        margin-bottom: 8px;
      }
      ~ .btn{
        margin-left: 0;
        margin-bottom: 0;
      }
    }
  }
}

@keyframes fade-in-screen {
  0%{
    opacity: 0;
    transform: translateY(20px);
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}