&.vouchers-page{
  .header-section{
    padding-bottom: 0;
  }
  .heading-5,
  .section-title{
    @include etype(3);
    margin-bottom: $gutter * 2;
  }
  .card{
    padding-top: 16px;
    width: 100%;
    margin-bottom: $gutter * 2;

    &.add-voucher,
    &.voucher-item{
      .wrapper{
        display: flex;
        flex-direction: column;
        .title{
          @include etype(2);
          margin-bottom: 10px;
        }
        .line{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 20px;
          .terms{
            display: flex;
            position: relative;
            align-items: center;
            .info{
              margin-left: 10px;
              &.opened .tooltip {
                right: 30px;
              }
            }
          }
        }
        .pill{
          margin-left: 0;
          background-color: $all-good;
          color:$white;
          &.expired{
            background-color: $alert;
          }
        }
        label{
          @include ptype-1("regular");
          margin-bottom: 6px;
        }
        .form-control{
          width: 100%;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: $black;
          border:solid 1px #353A3E;
          padding:0 16px;
          color:$white;
          @include ptype(0,"base");
          @include placeholder($white,0.35);
          border-radius: 4px;
          &:active,
          &:focus,
          &:focus-visible{
            border:solid 1px #353A3E;
            outline: none;
          }
        }
        div.form-control{
          text-transform: uppercase;
        }
      }
      &.expired{
        pointer-events: none;
        user-select: none;
        .wrapper{
          .title{
            color: $grey-65;
          }
          .line{
            .terms{
              color:$grey-60
            }
          }
          label{
            color:$grey-60
          }
          div.form-control{
            color:$grey-75;
          }
        }
      }
    }

    &.add-voucher{
      margin-bottom: 0;
      .wrapper{
        display: flex;
        flex-direction: column;
        .title{
          margin-bottom: 22px;
        }
        .form-control{
          margin-bottom: 16px;
        }
        .btn{
          margin-right: auto;
        }
      } 
    }

  }
}