@mixin relative { position: relative; }
@mixin absolute { position: absolute; }
@mixin fixed    { position: fixed;    }
@mixin sticky   { position: sticky;   }
@mixin static   { position: static;   }


// POSITIONS 

.relative{
  @include relative;
  @include min-sm{ &-sm { @include relative; } }
  @include min-md{ &-md { @include relative; } }
  @include min-lg{ &-lg { @include relative; } }
  @include min-xl{ &-xl { @include relative; } }
  /* 
  Renders:
  [ relative, relative-sm, relative-md, relative-lg, relative-xl] 
  */
}

.absolute{
  @include absolute;
  @include min-sm{ &-sm { @include absolute; } }
  @include min-md{ &-md { @include absolute; } }
  @include min-lg{ &-lg { @include absolute; } }
  @include min-xl{ &-xl { @include absolute; } }
  /* 
  Renders:
  [ absolute, absolute-sm, absolute-md, absolute-lg, absolute-xl] 
  */
}

.fixed{
  @include fixed;
  @include min-sm{ &-sm { @include fixed; } }
  @include min-md{ &-md { @include fixed; } }
  @include min-lg{ &-lg { @include fixed; } }
  @include min-xl{ &-xl { @include fixed; } }
  /* 
  Renders:
  [ fixed, fixed-sm, fixed-md, fixed-lg, fixed-xl] 
  */
}

.sticky{
  @include sticky;
  @include min-sm{ &-sm { @include sticky; } }
  @include min-md{ &-md { @include sticky; } }
  @include min-lg{ &-lg { @include sticky; } }
  @include min-xl{ &-xl { @include sticky; } }
  /* 
  Renders:
  [ sticky, sticky-sm, sticky-md, sticky-lg, sticky-xl] 
  */
}

// Z index
$zIndexValues:1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,30,40,50,60,70,80,90;
@each $zIndex in $zIndexValues{ .z-#{$zIndex}{ z-index:#{$zIndex}; }}