.card.sponsor-card{
  padding:24px 4px 0;
  margin-bottom: 40px;
  .wrapper{
    display: flex;
    flex-wrap: wrap;
    .item{
      width: auto;
      height: 104px;
      display: flex;
      margin: 0 19px 30px;
      align-items: center;
      justify-content: center;
      img{
        display: flex;
      }
    }
  }
}

.sponsors {
  .partner-card-wrapper {
    border: 1px solid #353A3E; 
    border-radius: 8px; 
    padding: 20px;
    .image-wrapper {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 97.03%);
      height: 175px;
      display: flex;
      align-items: center;
      padding: 60px;
    }
  }
  .wide-cards-container-wrapper .long-scroll-section:not(.purple-scroll)::-webkit-scrollbar {
    height: 6px;
    border-radius: 2px;
  }
}