.ads{
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  border-radius: 8px;
  >.inner{
    background: transparent;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 48px;
    border-radius: 8px;
    padding:12px;
    margin: 0;
  }
  &.ad-primary{
    border:0;
  }
  .icon{
    margin-right: 8px;
  }
  .counter{
    @include ptype(0,"base");
    min-width: 60px;
    margin: 0 8px 0 0;
    text-align: center
  }
  .offer{
    @include ptype(0,"base");
  }
  &.ad{
    &-primary{
      background: $grad-secondary;
    }
    &-secondary{
      background: $black;
      padding: 1px;
      background-repeat: repeat;
      background-position: 0 0;
      background-size: 12px;
      border: 0;
      >.inner{
        background: $black;
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 48px;
        border-radius: 8px;
        padding:12px;
        margin: 0;
      }
    }
  }
  .offer{
    display: flex;
  }
}