&.tickets-detail-page {
  .header-section {
    padding-top: 35px;
    .btn {
      &.btn-grey {
        color: $white;
        @include etype(3);
        text-transform: initial;
        min-height: 60px;
        margin-bottom: 45px;
        svg {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  .section-title {
    @include etype(5);
    color: $white;
    margin: 0;
    padding: 20px 0;
  }

  .container-fluid.padding-double {
    .invoice-detail.qa {
      .heading-3 {
        margin-bottom: 1rem;
        @include etype(3);
      }
    }
    .qa-wrapper {
      background-color: $grey-75;
      padding: 1rem 1.5rem 1rem 1.5rem;
      border-radius: 8px;

      .heading-2.qa-title {
        @include ptype-1("strong");
        font-weight: bold;
        margin-bottom: 1rem;
      }
    }
    .badge-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "title ."
        "text button";
      background: linear-gradient(90deg, #b010ff 0%, #f537b9 100%);
      border-radius: 5px;
      padding: 32px 24px 32px 24px;
      min-height: 140px;
      margin-bottom: 1.5rem;

      .heading-3.badge {
        @include etype(4);
        grid-area: title;
      }

      .detail.badge {
        align-items: center;
        @include ptype(0,"base");
        grid-area: text;
      }

      .btn-primary {
        min-height: 64px;
        justify-content: flex-end;
        align-items: flex-end;
        grid-row: 1/span 2;
        align-self: center;
        justify-self: end;

        .left {
          grid-area: button;
          height: 64px;
        }
      }
    }
  }

  .card.norbi {
    margin-bottom: 1.5rem;

    .img-wrapper {
      display: flex;
      width: 100%;
      padding-top: 12px;
      margin-bottom: 1.5rem;
      > img {
        width: 100%;
        height: 280px;
        border-radius: 24px;
      }
    }
    .heading-3 {
      @include etype(3);
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    .heading-4 {
      margin-bottom: 6px;
    }

    .brand-minds-app.card {
      justify-content: center;
      margin-bottom: 1.5rem;
      background: black;
      height: 48px;

      .title{
        font-weight: normal;
        @include ptype(0,"base");
      }

      &.blue-card{
        background:$primary;
      }
    }

    .event-card.buttons-wrapper {
      width: 100%;
      .btn {
        width: 100%;
        svg {
          height: 32px;
        }
        > div {
          background-color: #1a1a1a;
          width: 100%;
          .label {
            font-weight: normal;
            @include ptype(0,"base");
          }
        }
      }
    }
  }

  .ticket-card {
    .item.profile {
      display: flex;
      align-items: center;
      .img-wrapper {
        width: auto;
        padding: 0;
        margin-bottom: 0;
        margin-right: 13px;
        > img {
          height: 24px;
        }
      }
    }
    .buttons-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      column-gap: 1.5rem;
      padding: 0;

      svg {
        height: 32px;
      }

      .btn-grey {
        margin-bottom: 1.5rem;
        > div {
          width: 100%;
          background-color: $accent-3;

          .label {
            font-weight: normal;
            @include ptype(0,"base");
            text-transform: none;
          }
        }
      }
      .btn-secondary {
        margin-left: 0;
        margin-bottom: 1.5rem;
        > div {
          width: 100%;
          background-color: $grey-85;
          .label {
            font-weight: normal;
            @include ptype(0,"base");
          }
        }
      }
      :nth-last-child(2) {
        grid-row: 2/3;
        grid-column: 2/3;
      }
      :nth-last-child(1) {
        grid-row: 2/3;
        grid-column: 1/2;
      }
    }
    .buttons-wrapper-title{
      margin-bottom: 1rem;
      @include ptype(0,"base");
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.checkout-page-footer{
  z-index: 1;
  grid-area: footer;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 2px solid $grey-80;
  background: $black;
  padding:40px;
  display: flex;
  align-items: center;
  >svg{
      margin-right: 8px;
      stroke: $white;
      stroke-width: 1.2;
  }
}
