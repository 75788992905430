.btn-pill{
  display: flex;
  padding:0 10px;
  min-height: 25px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  @include ptype-2("base-strong");
  border:0;
  outline:none;
  cursor: pointer;
  &.btn{
    &-gradient{
      background: $grad-good;
      color:$white;
      &:hover{
        color:$white;
      }
    }
    &-white{
      background-color: $white;
      color:#171B3A;
      &:hover{
        color:#171B3A;
      }
    }
  }
  &.uppercase{
    text-transform: uppercase;
  }
}