.connections{
	border:0;
	padding: 12px 16px ;
	border-radius: 24px;
	height: 48px;
	margin-right: 0;
	margin-bottom: 12px;
	.item{
		>img{
			object-fit: cover;
			max-width: 26px;
		}
	}
	.content{
		font-family:$Grotesque;
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
		margin-bottom: 2px;
	}
}