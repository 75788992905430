.hero-section{
  background: $grad-btn;
  padding:7px;
  border-radius: 8px;
  margin-bottom: 40px;
  overflow:hidden;
  .hero-inner{
    width: 100%;
    min-height: 626px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 30px 30px 0;
    background-size: cover;
    background-position: center;
    &::after{
      content: '';
      display: flex;
      position: absolute;
      z-index:1;
      right:0;
      bottom:0;
      left:0;
      width: 100%;
      height: 90%;
      background: linear-gradient(0deg,#000 10%, rgba(0,0,0,0) 100%);
    }
    >div{
      position: relative;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      &.top-line{
        justify-content: flex-end;
      }
      &.centered-titles{
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        .wrapper{
          display: flex;
          flex-direction: column;
          align-items: center;
          .heading{
            &-2{
              @include etype(10);
            }
            &-5{
              font-family: $Grotesque;
              font-size: 32px;
              font-style: normal;
              font-weight: 700;
              line-height: 38px;
              letter-spacing: -0.02em;
            }
          }
        }
      }
      &.bottom-line{
        justify-content: space-between;
        >.left,
        .right{
          display: flex;
          align-items: flex-end;
        }
        .event-status{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 14px;
          margin-right: 24px;
          .img-wrap{
            width: 80px;
            height: 80px;
            display: flex;
            border-radius: 50%;
            overflow:hidden;
            position: relative;
            margin-bottom: 8px;
            .locked{  
              position: absolute;
              top:0;
              right:0;
              bottom:0;
              left:0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 80px;
              height: 80px;
              border-radius: 50%;
              background-color: #0007;
              backdrop-filter: blur(5px);
            }
          }
        }

        .buttons-wrap{
          display: flex;
          align-items: flex-end;
          margin-bottom: 45px;
        }
        .btn{
          &.btn-primary{
            > div{
              background: $grad-btn;
            }
          }
        }
      }
    }
  }
}