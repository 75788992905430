.autocomplete-wrapper {
    position: relative;
    &.with-placeholder{
        .placeholder{
            display: flex;
        }
    }
    .placeholder{
        position: absolute;
        z-index: 1;
        top:0;
        left:0;
        width: 100%;
        padding:0.5rem 1.25rem;
        @include ptype;
        color:$grey-10;
        opacity:0.5;
        pointer-events: none;
        height: 48px;
        display: none;
        align-items: center;
    }
    > div{
        width: 100%;
        display: flex !important;
        input {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 0 1rem;
            background-color: $grey-75;
            border: 1px solid $grey-75;
            border-radius: 8px;
            color:$white;
            @include ptype;
            width: 100%;
            // height: 48px;
            border-radius: 8px;
            @include placeholder($grey-10,0.5);
            &:focus-within {border: 1px solid $accent-2;}
            outline:none;

            + div {
                position: absolute !important;
                top: 49px !important;
                left: 0!important;
                z-index: 6 !important;
                max-height:50vh !important;
                font-size: 100% !important;
                background-color: $grey-75!important;
                border: 1px solid $grey-75!important;
                border-radius:8px !important;
                border:solid 1px $grey-65 !important;
                .item{
                    padding: 0.5rem 1rem;
                    &.active{
                        background-color: $black;
                    }
                }
            }
        }
    }
    

    &.dark{
        > div{
            input {
                background-color: $black;
                border: 1px solid $grey-75;
                &:focus-within {border: 1px solid $accent-2;}
                + div {
                    background-color: $black !important;
                    border: 1px solid $black !important;
                    border:solid 1px $grey-75 !important;
                    .item{
                        &.active{
                            background-color: $grey-75;
                        }
                    }
                }
            }
        }
    }

    &.disabled{
        pointer-events: none;
    }

}