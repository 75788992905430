&.order-page{

  // .bread-crumb + .header-section{
  //   padding-top: 0;
  // }

  .header-section {
    padding: 24px;
  }

  .cell-more-actions .more-modal{
    background-color: $black;
    border-color: #999999;
    .secondary-action{
      &:hover{
        p{
          color: $white;
        }
        
      }
      .clickable{
        svg{
          rect{
            fill:none;
          }
        }
      }
    }

    
  }
  .cell-more-actions .clickable{
    svg{
      path{
        fill:none;
      }
    }
  }
}