&.chat-page{
	padding-bottom: $gutter*2;
	.header-section{
		&.justify{
			justify-content: space-between;
			flex-direction: row;
			align-items: center;
			flex-wrap: wrap;
			padding-bottom: $gutter*2;
			.right{
				@include min-sm{
					width: auto;
					transform: translateY(-24px);
					margin: 0;
				}
				.btn{
					margin: 0;
				}
			}
		}
	}
	.container-fluid{
		&.padding-double{
			padding: 0 $gutter*2;
		}
		&.chat-wrapper{
			>.outer{
				display: flex;
				width: 100%;
				flex-direction: row;
				flex-wrap: nowrap;
				height:calc(100vh - 212px - #{$gutter*2} );
				position: relative;
				@include max-md{
					overflow: hidden;	
				}
				>.inner{
					display: flex;
					height:auto;
					height:calc(100vh - 216px - #{$gutter*2} );
					flex-wrap: nowrap;
					width: 100%;
					@media (min-width:992px) and (min-height:800px){
						height:calc(100vh - 216px - #{$gutter*2} );
						flex-wrap: nowrap;
						flex-direction: row;
					}
					@include max-md{
						flex-wrap: nowrap;
						transition: all 0.25s ease-out;
						transform: translate( -100%,4px);
						&.show-users{
							transform: translate(0,4px);
						}
						&.full-width{
							position: fixed;
							z-index: 99;
							top:0;
							right:0;
							bottom:0;
							left:0;
							height: 100vh;
							.chat{
								&-menu,
								&-window{
									height: 100vh;
									border-color: transparent;
									border-radius: 0;
								}
							}
						}
					}
				}
			}
		}
	}
	.chat{
		&-menu{
			width: 498px;
			min-width: 498px;
			margin-right: $gutter*2;
			display: flex;
			flex-direction: column;
			padding: $gutter*2;
			background: $grey-85;
			border:solid 1px #353A3E;
			height: 100%;
			border-radius: 8px;
			height:calc(100vh - 216px - #{$gutter*2} );
			@media (min-width:992px) and (min-height:800px){
				width: 300px;
				min-width:360px;
				margin-bottom: $gutter*2;
			}
			@media (min-width:1200px) and (min-height:800px){
				width: 498px;
				min-width: 498px;
			}
			@include max-md{
				min-width:100%;
				width:100%;
				margin-right:0;
				
			}
			@include max-sm{
				width:100%;
				max-width: 100%;
				margin-right:0;
				height: auto;
				margin-bottom:0;
			}
			@include max-sm{
				padding: $gutter;
			}
			.magic-input{
				&.search{
					margin-bottom: 16px;
				}
			}
			.search-wrap{
				display: flex;
				.btn.btn-grey.exit{
					margin:0 0 0 10px;
					border-radius: 50%;
					overflow: hidden;
					min-width: 48px;
					.left{
						padding: 0;
						min-width: auto;
						width: 48px;
						display: flex;
						align-items: center;
						justify-content: center;
						.ico{
							margin: 0;
						}
						.label{
							display: none;
						}
					}
				}
			}
		}
		&-rooms{
			@include vertical-scrollbar;
			>.inner{
				padding-right: 20px;
			}
		}
		&-window{	
			background: $grey-85;
			border:solid 1px #353A3E;
			display: flex;
			flex-grow: 1;
			border-radius: 8px;
			padding:0;
			flex-direction: column;
			width: 100%;
			height:calc(100vh - 216px - #{$gutter*2} );
			position: relative;
			@include max-md{
				margin-bottom: 0;
				margin-right: 0;
				height:calc(100vh - 216px - #{$gutter*2});
				min-width: 100%;
				width: 100%;
			}
			@include max-sm{
				margin-right: 0;
			}
			.chat-box{
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
			}
			.separator{
				width: 100%;
				height: 1px;
			}
			@import "chat/title";
			@import "chat/messages";
			@import "chat/form";
		}
	}
}

@import "chat/chat-card";