.auth-wrap{
  background: #00000095;
  backdrop-filter:blur(8px);
  position: fixed;
  z-index: 91;
  top:0;
  right:0;
  bottom:0;
  left:0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  

  .auth-modal{
    opacity: 0;
    transition-delay: 0.4s;
    transition: all 0.25s ease-out;
    transform: translateY(20px);
    background: $grey-85;
    border: 1px solid $grey-80;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 446px;
    min-height: 510px;
    max-height: 80vh;
    position: relative;
    padding: 40px;
    box-shadow:  0 5px 40px #DF4AB750;
    body.auth-in & {
      opacity: 1;
      transform: translateY(0);
    }
    
    .close{
      width: 32px;
      height: 32px;
      display: flex;
      border-radius: 50%;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      border:0;
      outline: none;
      margin: 40px 40px 0 0;
      padding: 0;
      background: transparent;
      position: absolute;
      top:0;
      right: 0;
      z-index: 1;
      cursor: pointer;
      >svg{
        pointer-events: none;
      }
    }

    @import "Login";
    @import "Register";
  }


}