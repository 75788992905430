.intro{
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  .card-head{
    display: flex;
    &.with-image{
      min-height: 256px;
      background-size: cover;
      background-position: center;
      flex-direction: column;
      justify-content: flex-end;
      border-radius: 8px;
      overflow:hidden;
      padding:14px 20px;
      margin-bottom: $gutter*1.5;
    }
    .heading{
      &-6{
        color:$white;
        @include ptype-1("base","normal");
        text-transform: uppercase;
      }
      &-2{
        color:$white;
        @include etype(5);
        text-transform: uppercase;
        margin-bottom: 16px;
      }
    }
    
  }
  .progress{
    margin-bottom: 8px;
  }
  .content{
    display: flex;
    margin: 0 0 1rem;
    color:$white;
    @include ptype-1("strong","normal");
  }
  .wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    @include max-md{
      justify-content: flex-start;
    }
    .btn{
      margin-bottom: 16px;
      @include max-md{
        width: 100%;
        margin-left: 0;
        display:flex;
        .left{
          width: 100%;
        }
      }
      &:first-child{
        margin-right: auto;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}