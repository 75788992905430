.card{
  &.ticket,
  &.invoice,
  &.received{
    padding-bottom: 8px;
    .card-head {
      margin-bottom: 30px;
    }
    .wrapper{
      .btn{
        margin-right: 16px;
        margin-bottom: 16px;
        ~ .btn{
          margin-left: 0;
        }
      }
    }
  }
}

@import "brand-minds/header";
@import "brand-minds/cards";
@import "brand-minds/intro";
@import "brand-minds/user";
@import "brand-minds/experience";