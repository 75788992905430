.ticket-counter{
  width: 200px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:12px 17px;
  background: rgba(255,255,255,.2);
  border-radius: 16px;
  overflow:hidden;
  @include main-transition;
  user-select: none;
  &.counted{
    background: $grad-btn;
    &.small{
      background: transparent;
      width: 144px;
      height: 48px;
      min-width: 144px;
    }
    &.counter-disabled {
      justify-content: flex-end;
    }
  }
  .c-btn{
    background-color: transparent;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    cursor: pointer;
    position: relative;
    &:disabled{
      cursor: default;
      svg{
        path{
          fill:#838383;
        }
      }
    }
    &:not(.disabled){
      &::after{
        content:'';
        position:absolute;
        z-index: 1;
        top:0;
        left:0;
        width: 22px;
        height: 22px;
        display: flex;
        box-shadow: 0 0 0 1px rgba(255,255,255,0);
      }
      &:active{
        &::after{
          box-shadow: 0 0 0 1px rgba(255,255,255,1);
        }
      }
    }
  }
  .text{
    @include etype(7);
    color: $white;
    height: 48px;
  }

  .count{
    height: 48px;
    overflow: hidden;
    .roll{
      transform: translateY(-48px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &.up{
      .roll{
        transform: translateY(-96px);
        transition: all 0.25s ease-out;
      }
    }
    &.down{
      .roll{
        transform: translateY(0px);
        transition: all 0.25s ease-out;
      }
    }
  }

  &.small{
    background: transparent;
    .text{
      @include etype(5);
      line-height: 48px;
    }
  }
}