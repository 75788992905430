.btn-read{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 41px;
  padding: 1px;
  @include ptype-1("regular");
  color: $white;
  background: $grad-warn;
  border:0;
  outline: none;
  border-radius: 8px;
  overflow:hidden;

  .inner{
    min-height: 39px;
    padding: 0 $gutter * 2 ;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius:8px;
  }

  &.btn-unread,
  &.btn-free{
    .inner{
      background: $black;
    }
    &.roll-for-pill{
      background: $accent-4-80;
      .inner{
        background:$accent-4-80;
        .label{
          display: flex;
          align-items: center;
          justify-content: center;
          .tx{
            color: $black;
          }
          .pill{
            background-color: $black;
            color: $white;
            svg{
              path{
                stroke: $white;
              }
            }
          }
        }
      }
    }
  }

  &.btn-progress{
    background: $grad-good;
    .inner{
      background: $grad-good;
    }
    &.with-percentage{
      background: #353A3E;
      position: relative;
      .inner{
        background: $black;
        &::before{
          content: "";
        }
        .percent-line{
          position: absolute;
          z-index: 0;
          top:0;
          left:0;
          height: 100%;
          display: flex;
          background: $grad-btn;
        }
        .label{
          position: relative;
          z-index: 2;
        }
      }
    }
  }

  &.btn-completed{
    background: $all-good;
  }
}