@import url("https://use.typekit.net/bze0vme.css");

// 300
@font-face {
    font-family:$Grotesque;
    src:  url('../fonts/BasisGrotesquePro-Light.woff2')       format('woff2'),
          url('../fonts/BasisGrotesquePro-Light.woff')        format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family:$Grotesque;
    src:  url('../fonts/BasisGrotesquePro-LightIt.woff2')     format('woff2'),
          url('../fonts/BasisGrotesquePro-LightIt.woff')      format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

// 400
@font-face {
    font-family: $Grotesque;
    src:  url('../fonts/BasisGrotesquePro-Regular.woff2')     format('woff2'),
          url('../fonts/BasisGrotesquePro-Regular.woff')      format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family:$Grotesque;
    src:  url('../fonts/BasisGrotesquePro-It.woff2')          format('woff2'),
          url('../fonts/BasisGrotesquePro-It.woff')           format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

// 500
@font-face {
    font-family: $Grotesque;
    src:  url('../fonts/BasisGrotesquePro-Medium.woff2')      format('woff2'),
          url('../fonts/BasisGrotesquePro-Medium.woff')       format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family:$Grotesque;
    src:  url('../fonts/BasisGrotesquePro-MediumIt.woff2')    format('woff2'),
          url('../fonts/BasisGrotesquePro-MediumIt.woff')     format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

// 700
@font-face {
    font-family:$Grotesque;
    src:  url('../fonts/BasisGrotesquePro-Bold.woff2')        format('woff2'),
          url('../fonts/BasisGrotesquePro-Bold.woff')         format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $Grotesque;
    src:  url('../fonts/BasisGrotesquePro-BoldIt.woff2')      format('woff2'),
          url('../fonts/BasisGrotesquePro-BoldIt.woff')       format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

// 900
@font-face {
    font-family:$Grotesque;
    src:  url('../fonts/BasisGrotesquePro-Black.woff2')       format('woff2'),
          url('../fonts/BasisGrotesquePro-Black.woff')        format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $Grotesque;
    src:  url('../fonts/BasisGrotesquePro-BlackIt.woff2')     format('woff2'),
          url('../fonts/BasisGrotesquePro-BlackIt.woff')      format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

// Off-white 400
@font-face {
    font-family:$GrotesqueOffWhite;
    src:    url('../fonts/BasisGrotesquePro-OffWhite.woff2')    format('woff2'),
            url('../fonts/BasisGrotesquePro-OffWhite.woff')     format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $GrotesqueOffWhite;
    src:  url('../fonts/BasisGrotesquePro-OffWhiteIt.woff2')  format('woff2'),
          url('../fonts/BasisGrotesquePro-OffWhiteIt.woff')   format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}