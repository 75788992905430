.info{
  position: relative;
  .info-btn{
    width: 16px;
    height: 16px;
    display: flex;
    background-color: transparent;
    padding: 0;
    border:0;
    outline: none;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 8px;
  }

  .tooltip{
    position: absolute;
    z-index: 1;
    right:60px;
    top:50%;
    transform: translateY(-50%);
    background-color: $black;
    color: $white;
    padding: 10px 16px 12px;
    display: flex;
    flex-direction: row;
    max-width: 240px;
    max-height: 60px;
    border-radius: 8px;
    box-sizing: border-box;
    opacity: 0;
    pointer-events: none;
    @include main-transition;
    &::after{
      content:"";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 8px;
      border-color: transparent transparent transparent $black;
      position: absolute;
      z-index: 1;
      top:50%;
      right: 0;
      transform: translate(8px,-50%);

    }
    >div{
      @include ptype-2("base");
      @include truncate;

    }
  }

  &.opened{
    .tooltip{
      right: 50px;
      opacity: 1;
    }
  }
}