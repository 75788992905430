.follower-card{
	width: 100%;
	background: $grey-85;
	border:solid 1px $grey-75;
	border-radius: 4px;
	overflow: hidden;
	margin: 0 0 $gutter*2;
	padding: $gutter*2;
	position: relative;
	transition: all 0.25s ease-out;
	display: flex;

	.follower-content{
		display: flex;
		flex-direction: column;
		.top{
			display: flex;
			margin-bottom: 4px;
			.h4{
				
				@include etype(1);
				margin: 0;
			}
		}
		.bottom{
			font-family: $Grotesque;
			font-size: 14px;
			font-weight: 400;
			line-height: 17px;
			color:#B3B3B3;
		}
	}

	.follower-img{
		width: 64px;
		min-width: 64px;
		height: 64px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		overflow: hidden;
		background: $grey-90;
		>img{
			display: flex;
			object-fit: cover;
		}
		margin-right: $gutter;
	}

	.percent{
		transition: all 0.25s ease-out;
		background: $grad-secondary;
		display:flex;
		align-items: center;
		justify-content: center;
		height: $gutter*2;
		border-radius: $gutter;
		width: auto;
		margin: auto;
		padding: 0 11px;
	}
	.bottom{
		transition: all 0.25s ease-out;
		padding-bottom: 2px;
	}

	.hover-buttons{
		position: absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		padding:0 $gutter*2 ;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		transform: translateY(100%);
		transition: all 0.25s ease-out;
		.btn{
			transition: all 0.35s ease-out;
			transform: translateY(15px);
			opacity: 0;
			margin-bottom:$gutter*2;
			height: 30px;
			border-radius: 15px;
			>div{
				min-height: 28px;
				border-radius: 14px;
			}
			+ .btn{
				margin: 0;
			}
		}
	}
	&:hover{
		background: $grey-75;
		border-color: #4D4D4D;
		.percent,.bottom{
			opacity: 0;
		}
		.hover-buttons{
			transform: translateY(0);
			.btn{
				transform: translateY(0);
				transition-delay: 0.20s;
				opacity: 1;
				+ .btn{
					transition-delay: 0.25s;	
				}
			}
		}
	}
}