.pill {
    margin-left: 8px;
    width: auto;
    min-height: 23px;
    border-radius: 11px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $black;
    padding: 2px 9px;
    color: $black;
    @include ptype-1('bold', 'mormal');
    &:not(.no-static-bg){
        background-color: $accent-4-80;
    }
    &.vip {
        background-color: #8a3ffc;
        color: white !important;
    }
    &.business {
        background-color: #007d79;
        color: white !important;
    }
    &.large {
        padding: 10px 16px;
        height: 40px;
        border-radius: 20px;
        color: $black;

        @include ptype-1('base-strong', 'mormal');
        svg {
            width: 12px;
            height: 16px;
        }
    }
    svg {
        display: inline-flex;
        margin-top: 1px;
    }
    .txt {
        margin-left: 2px;
        display: inline-flex;
    }
}
