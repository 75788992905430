$toastrMargin: 40px;

.redux-toastr{

  .top-left, 
  .top-right, 
  .top-center, 
  .bottom-left, 
  .bottom-right, 
  .bottom-center{
    float: none;
    position: absolute;
  }

  .bottom-left{
    left:$toastrMargin;
    bottom:$toastrMargin;
  }

  .bottom-right{
    right:$toastrMargin;
    bottom:$toastrMargin;
    flex: 0 0 auto;
    align-items: end;
  }

  .toastr{
    min-height: auto;
    display: block;
    float: none;
    white-space: nowrap;
    min-width:200px;
    width: min-content;
    border-radius: 4px;
    > div{
      display: flex;
    }
    .rrt-left-container{
      display: none;
    }
    .rrt-middle-container{
      margin-left: 0;
      width: auto;
      min-height: auto;
      height: auto;
      padding: 10px;
    }
    .rrt-right-container{
      width: auto;
      position: relative;
      .close-toastr{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        top:6px;
        right:6px;
        opacity: 1;
        span{
          background-color: $white;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          font-size:12px;
          line-height: 24px;
        }
      }
      }
    
    &.rrt{
      &-error{
        background-color: $black;
        color: $white;
      }
    }
  }
}



