&.filter{
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $black;
  .swithcer{
    margin-top: 24px;
    margin-left: 170px;
    margin-right: 12px;
    width: 48px;
    height: 48px;
    background-color: $grey-85;
    border-radius: 4px;
    border:0;
    outline: none;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease-out;
    .wrapper{
      width: 32px;
      height: 32px;
      display: flex;
      position: relative;
      transform: rotate(0deg);
      transition: all 0.25s ease-out;
      svg{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 32px;
        height: 32px;
        display: flex;
      }
    }
    .small{
      opacity: 1;
      transition: all 0.25s ease-out;
    }
    .medium{
      opacity: 0;
      transition: all 0.25s ease-out;
    }
    &.active{
      .wrapper{
        transform: rotate(180deg);
      }
      .small{
        opacity: 0;
      }
      .medium{
        opacity: 1;
      }
    }
    svg{
      path{
        stroke:$white;
      }
    }
  }
}