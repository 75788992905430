.tag{
  width: auto;
  height: 32px;
  margin-right: 4px;
  background: $black;
  border-radius: 4px;
  overflow:hidden;
  padding:1px;
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  &.type{
    &-primary{
      background: $grad-btn;
    }
    &-secondary{
      background: $grad-good;
    }
    &-green{
      background: $all-good;
      >div{
        background: $all-good;
      }
    }
    &-orange{
      background: $danger;
      >div{
        background: $danger;
      }
    }
    &-plain{
      background: $grey-75;
    }
    &-red{
      background: $alert;
      >div{
        background: $alert;
      }
    }
  }

  >div{
    background: $black;
    color: $white;
    border-radius: 4px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}