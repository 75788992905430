.form-wrapper{
  padding-top: $gutter*2;

  .custom-select-bm{
    width: 100%;
    .crs__indicator svg {
      margin-right: 12px;
    }
    .crs__menu-list{
      background-color: #000000;
      &::-webkit-scrollbar {
        width:0px;
        border-radius:0px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: none;
        background-color: transparent;
        border-radius:0px;
      } 
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        outline: none;
        border-radius:2px;
        cursor: drag;
      }
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .date-input{
    .cdt-button{
      position: relative;
      padding-right: 48px;
      > svg{
        display: none;
      }
      > div {
        padding-left: 10px;
        > svg{
          position: absolute;
          top:9px;
          right:3px;
          pointer-events: none;
        }
      }
    }
  }
  
  .inputContainer,
  .premium-textarea{
    margin-bottom: 15px;
  }
  .inputContainer{
    height: auto !important;
    padding: 0;
    textarea{
      background-color: transparent;
      border:0;
      width: 100%;
      resize: vertical;
      padding: 10px;
      @include ptype;
      @include placeholder($grey-10,0.5);
      color: $white;
    }
  }
  .custom-select-brand{
    margin-bottom: 15px;
    width: 100%;
  }
  .date-input{
    margin-bottom: 15px;
    .cdt-button{
      &:hover,
      &:active{
        border-color: $accent-2;
      }
    }
    .cdt-main{
      top:unset;
      bottom:50px;
      
    }
  }
  .col-6{
    + .col-6{
      .date-input{
        .cdt-main{
          right: 0;
        }
      }
    }
  }

  .radio-group-wrap{
    display: flex;
    flex-direction: column;
    .label{
      @include ptype;
      margin: 0 0 5px;
      display: flex;
    }
  }

  .radio-group{
    display: flex;
    flex-wrap: wrap;
    margin:0 -4px;
    .radio-btn{
      border-radius: 6px;
      overflow: hidden;
      width: 85px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      margin: 4px;
      transition: all 0.25s ease-out;
      >input[type="radio"]{
        position: absolute;
        z-index: 3;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width: 85px;
        height: 85px;
        display: flex;
        opacity: 0;
        cursor: pointer;
      }
      img{
        object-fit: cover;
      }
      .active-show{
        position: absolute;
        z-index: 4;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width: 85px;
        height: 85px;
        display: flex;
        pointer-events: none;
        box-shadow: 0 0 0 42px $white inset;
        border-radius: 6px;
        overflow: hidden;
        opacity: 0;
        transition: all 0.15s ease-out;
      }
      &.active{
        .active-show{
          box-shadow: 0 0 0 3px $white inset;
          opacity: 1;
        }
      }
    }
  }

  .buttons{
    background: transparent;
    margin-top: 20px;
    &.right{
      padding: 0;
      justify-content: space-between;
      .magic-button-container{
        padding-right: 17px;
        padding-left: 17px;
        border-radius: 4px;
        flex:0 1 60px;
        &.white{
          background: transparent !important;
          color:$white;
        }
        &.primary{
          background: $grad-good;
        }
      }
    }
  }
}