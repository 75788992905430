html,body{
  background-color: $black;
}

body{
  @include vertical-scrollbar($auto:auto);
  &.locked{
    overflow:hidden;
    height: 100vh;
    .bm-header,.leftmenu{
      pointer-events: none;
    }
  }
  &.freeze{
    overflow:hidden;
    user-select: none;
  }
}

#root{
  background-color: $black;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100vh;
  color: $white;
  --gutter: 12px;
}


.responsive-grid-form{
  @include responsiveFormGrid(300px);
}

.pointer {
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-center{
  text-align: center;
}

.brand-minds-app{
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  // @include min-sm{
  //   padding-left: 96px;
  // }
  
  // background-color:$black;
  // padding-top: 80px;

  &.grid-special{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    grid-template-areas: 
      "header header"
      "menu main"  ;
    >header{
      grid-area: header;
    }
    >nav{
      grid-area: menu;
    }
    >main{ 
      grid-area: main;
      flex:1;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      &::-webkit-scrollbar {
        width:4px;
        border-radius:2px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: none;
        background-color: #000000;
        border-radius:2px;
      } 
      &::-webkit-scrollbar-thumb {
        background-color: #333333;
        outline: none;
        border-radius:2px;
        cursor: drag;
      }
      &.modal-opened {
        overflow-y: hidden;
      }
    }
    h1, h2, h3,
    h4, h5, h6 {
      margin:0;
      }
    }
  // .bread-crumb{
    // padding-top: 12px;
    // + .header-section{
    //   padding-top: 0;
    // }
  // }
  .header{
    width: 100%;
    display: flex;
    height: 80px;
    background-color: $grey-85;
  }
  .brand-main{
    width: calc(100% - 96px);
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 96px;
    margin-top: 80px;

  }
}
.d-flex{
  display: flex !important;
  &.no-wrap{
    flex-wrap: nowrap;
  }
}

.container-fluid{
  &.padding{
    &-double{
      @include min-sm{
        padding:0 calc(#{$gutter} * 2); 
      }
    }
    &-16{
      padding:0 16px;
    }
    &-32{
      padding:0 32px;
    }
  }
}
