.chat-card{
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	padding: 8px 16px 8px 10px;
	transition: all 0.25s ease-out;
	border-radius: 5px;
	&:hover,
	&.active{
		background: $grey-75;
	}
	&.empty{
		.bt{
			display: flex;
			background: $grad-btn;
			height: 30px;
			border-radius: 15px;
			padding: 0 $gutter*2 ;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			@include max-xs{
				margin-left: auto;
			}
		}
	}
	.top{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-top: 2px;
		.fullname{
			font-family: $Grotesque;
			font-size: 16px;
			font-weight: 700;
			line-height: 19px;
			cursor: pointer;
		}
	}
	.avatar{
		width: 64px;
		height: 64px;
		min-width: 64px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		overflow: hidden;
		margin-right: $gutter;
		cursor: pointer;
		@include max-xs{
			width: 36px;
			height: 36px;
			min-width: 36px;
			margin-bottom: auto;
		}
		img{
			object-fit: cover;
			max-width: 64px;
			max-height:64px;
			min-width: 64px;
			@include max-xs{
				max-width: 36px;
				max-height: 36px;
				min-width: 36px;
			}
		}
	}
	.content-wrap{
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		flex-grow: 1;
		.top{
			@include max-xs{
				.fullname{
					display: none;
				}
			}
		}
		p{
			margin:4px 0 0;
			font-family: $Grotesque;
			font-size: 14px;
			font-weight: 400;
			line-height: 17px;
			opacity: 0.6;
		}
	}
}