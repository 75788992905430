.wrapper{
  padding: 0;
}
.head{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  @include min-sm{
    flex-direction: row;
  }
  .heading{
    padding-right: $gutter*2;
    @include etype(3);
    min-height: 46px;
    display: flex;
    align-items:center;
    margin: 0;
    order:2;
    @include min-sm{
      order:1;
    }
  }
  .btn{
    margin-left: auto;
    order:2;
    @include max-sm{
      order:1;
    }
  }
}