&.iconpage{
  color: $black;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  gap: 1px 1px; 
  grid-template-areas: 
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."; 
  @media (max-width:1550px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;  
    grid-template-areas: 
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . .";
  }
  @media (max-width:1300px) {
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-areas: 
    ". . ."
    ". . ."
    ". . ."
    ". . ."
    ". . ."
    ". . ."
    ". . ."
    ". . .";
  }
  @media (max-width:1000px) {
    grid-template-columns: 1fr 1fr; 
    grid-template-areas: 
    ". ."
    ". ."
    ". ."
    ". ."
    ". ."
    ". ."
    ". ."
    ". .";
  }
  @media (max-width:767px) {
    grid-template-columns: 1fr; 
    grid-template-areas: 
    "."
    "."
    "."
    "."
    "."
    "."
    "."
    ".";
  }
  &.names{
    .icon{
      code{
        transform: translateY(0);
      }
      @for $i from 1 through 60 {
        &:nth-child(#{$i}n) code {
          transition-delay: #{$i * 0.04}s;
        }
      }
    }
  }
  .dark-btn{
    background-color: #313131;
    color: $white;
    border: 0;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  .icon{
    background:#efefef;
    height:140px;
    display:flex;
    align-items:center;
    justify-content:space-around;
    flex-direction:column;
    padding-top: 35px;
    padding-bottom: 10px;
    overflow:hidden;
    transition: all 0.5s ease-in-out;
    position: relative;
    .letter{
      position: absolute;
      top:0;
      left:0;
      padding: 14px;
      opacity: 0.5;
    }
    &.length{
      font-size: 80px;
      font-weight: 900;
      padding-bottom: 0;
      background-color: lightgray;
      span{
        font-size:18px;
        font-weight: 400;
      }
    }
    .ico{
      background-color: $white;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      box-shadow: 0 1px 3px rgba(0,0,0,.15), 0 0 0 0 rgba(255,255,255,1);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in-out;
      svg{
        width: 32px;
        height: 32px;
      }
    }
    code{
      font-weight: 900;
      transform: translateY(70px);
      transition: all 0.5s ease-in-out;
    }
    &:hover{
      .ico{
        box-shadow:0 1px 3px rgba(0,0,0,0), 0  0 0 200px rgba(255,255,255,1); 
      }
      code{
        transform: translateY(0);
      }
    }
  }
}

