.ticket{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #1A1A1A;
  border-radius: 8px;
  padding: 24px;
  border:solid 1px rgba(255,255,255,.15);
  color: $white;
  &.primary{
    background: $grad-btn;
  }
  &.large{
    .ticket-head{
      &.with-image{
        min-height: 632px;
      }
    }
  }
  &.ticket{
    padding:16px 16px 0;
  }
  .ticket-head{
    &.with-image{
      border-radius:8px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      min-height: 256px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .wrapper{
      padding: 0 24px;
      position: relative;
      .heading-6{
        padding: auto 16px 16px;
        display: flex;
      }
      .heading-2{
        display: flex;
        margin-bottom: 2px;
      }
      .content{
        @include ptype-1("bold");
        color: $white;
        margin:12px 0 20px;
        padding-right: 290px;
      }
    }
    @import "locked";
  }
  .top-section{
    display:flex;
    justify-content: space-between;
    padding:20px 16px 20px 24px;
    .profiles{
      display: flex;
      margin-right: auto;
      .profile{
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow:hidden;
        border-style: solid;
        border-width: 2px;
        border-color: transparent;
        margin-right: 2px;
        &.border{
          border-color: #F537B9;
        }
        >img{
          max-width: 40px;
          max-height: 40px;
        }
      }
    }
    
  }
  .heading{
    &-6{
      color:$white;
      @include ptype-1("base-strong","normal");
      font-size: 20px;
      margin-top: -3px;
      span + span{
        font-weight: 400;
      }
    }
    &-2{
      color:$white;
      @include etype(5);
    }
  }
  .saving-banner{
    position: absolute;
    z-index: 1;
    right:0;
    bottom:0;
    background-color: $white;
    color:#121619;
    padding: 6px 12px;
    border-radius: 8px 0;
    @include ptype-1("base-strong");
    display: flex;
    justify-content: space-between;
    &.uppercase{
      text-transform: uppercase;
    }
    .counter{
      display: flex;
      justify-content: flex-end;
      span{
        width: 100px;
        &.no-offer{
          width: auto;
          padding-right: 4px;
        }
      }
    }
    &.primary{
      background: $grad-btn;
      color: $white;
    }
    &.secondary{
      background:$grad-secondary;
      color: $white;
    }
    &.alert{
      background: $alert;
      color: $white;
    }
  }
  .savings{
    .btn{
      &.btn{
        &-primary,
        &-secondary{
          &.disabled{
            background: $black;
            pointer-events: none;
            >div{
              background: $black;
              opacity: 0.5;
            }
          }
        }
        &-secondary{
          &.upgrade{
            background:$grad-secondary;
            color: $white;
            .pill{
              background:$accent-4-80;
              border-radius: 11px;
              color: $black;
              >svg{
                display:flex;
              }
            }
          }
        }
      }
    }
  }
}

.green-ticket{
  background-color: $all-good;
  color:$white;
  height: 32px;
  @include ptype-2("base-strong");
  line-height: 12px;
  padding:0 12px;
  border-radius: 8px;
  align-items: center;
  display: flex;
  &.vip{
    >svg{
      margin-right: 6px;
      path{
        fill:#B010FF
      }
    }
    background-color: $white;
    >span{
      background:$grad-btn;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color:#B010FF;
    }
  }
  >svg{
    margin-right: 4px;
  }
}