.write-form{
	background: $black;
	width: 100%;
	min-height: 80px;
	display: flex;
	flex-direction: row;
	border-top:solid 1px #353A3E;
	position: relative;
	border-radius: 0 0 8px 8px;
	.inputContainer{
		background-color: transparent;
		border:0;
		border-radius:0 0 0 8px;
		overflow: hidden;
		.form-control{
			input{
				height: 78px;
			}
		}
	}
	.btn-wrapper{
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-right: $gutter;
		.btn{
			margin: 0;
			+ .btn{
				margin: 0;
			}
			&.btn-link{
				width: 48px;
				min-width: 48px;
				margin-right: $gutter;
				.left{
					padding: 0;
					min-width: 48px;
					.ico{
						margin: 0;
						svg{
							height:24px;
						}
					}
					.label{
						display: none;
					}
				}
			}
		}
	}
}

.custom-uploader{
	position: absolute;
	z-index: 10;
	bottom:78px;
	left:0;
	right: 0;
	width: 100%;
	background:$grey-75 ;
	input[type="file"]{
		position: absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		width: 100%;
		opacity: 0;
	}
	.fake-uploader{
		width: 100%;
		min-height: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
		color:#fff5;
		text-transform: uppercase;
		&::after{
			content: attr(data-text);

		}
	}
}