.btn{
  display: inline-flex;
  justify-self: baseline;
  color: $white;
  stroke: $white;
  background-color:$black;
  padding:1px;
  border-style: solid;
  border-width: 0;
  border-color: transparent;
  text-decoration: none;
  outline: none;
  border-radius: 8px;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  height: 48px;
  color:$white;
  @include ptype-1(medium,normal);
  @include main-transition;
  &.w-100{
    width: 100%;
    flex: 1;
    justify-self:auto;
  }
  &:not(.no-margins){

    margin-right: 16px;
    margin-bottom: 16px;
  }
  svg{
    height: 16px;
  }
  &:hover{
    >div{
      .ico,
      .label{
        opacity: 0.75;
      }
      .label{
        color: $white;
      }
    }
  }
  >div{
    width: 100%;
    background-color: $black;
    padding: 0 24px;
    border-radius: 8px;
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    min-width: 98px;
    &.left{
      flex-direction: row;
      .ico{
        margin-right: 8px;
      }
    }
    &.right{
      flex-direction: row-reverse;
      .ico{
        margin-left: 8px;
      }
    }
  }

  &.secondary{
    .pill{
      stroke: $black;
    }
  }
  &.btn{
    &-active{
      background: $grad-btn;
      >div{
        background: $grad-btn;
      }
      &:hover{
        background: $grad-btn-hover;
        >div{
          background: $grad-btn-hover;
        }
      }
    }
    &-primary{
      background: $grad-btn;
      &:hover{
        background: $grad-btn-hover;
      }
    }
    &-secondary{
      background: $grad-good;
      &:hover{
        background: $grad-good-hover;
      }
    }
    &-secondary-active{
      background: $grad-good;
      >div{
        background: $grad-good;
      }
      &:hover{
        background: $grad-good-hover;
      }
    }
    &-white{
      background: $white;
      >div{
        background: $white;
        color:$black;
        svg{
          path{
            stroke: $black;
          }
        }
      }
      &:hover{
        >div{
          .label{
            color:$black;
          }
        }
      }
    }
    &-grey{
      background: $grey-75;
      padding: 0;
      >div{
        background: $grey-75;
        color:$white;
        svg{
          path{
            stroke: $white;
          }
        }
      }
      &:hover{
        background: $grey-85;
        >div{
          .label{
            color:$white;
          }
        }
      }
    }
    &-link{
      background:transparent;
      >div{
        background:transparent;
        color:$white;
        svg{
          path{
            stroke: $white;
          }
        }
      }
      &:hover{
        background:transparent;
        >div{
          background:transparent;
          color:#ffffff75;
        }
      }
    }
    &-grad-warn{
      background: $grad-warn;
      transition: all 0.25s ease-out;
      >div{
        background: $grad-warn;
        transition: all 0.25s ease-out;
      }
      &:hover{
        background: $grad-warn;
      }
    }
    &-grad-warn-outline{
      background: $grad-warn;
      transition: all 0.25s ease-out;
      >div{
        background: #1A1A1A;
        transition: all 0.25s ease-out;
      }
      &:hover{
        background: $grad-warn;
        >div{
          background:#1A1A1A;
        }
      }
    }
  }
  .ico{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.disabled{
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
}