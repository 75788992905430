$separator :'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAACCAYAAABR7VzxAAAAI0lEQVQImWOUl5f/z8HBwYAL/Pz5k+HBgweMuOSZcOokEgAAw0AHBM8A3xAAAAAASUVORK5CYII=';
&.card-form{
  justify-content: flex-start;
  padding-bottom: calc( #{$gutter}*2 - 1rem);
  .wrapper{
    @include max-sm{
      padding: 0;
    }
  }
  .separator{
    margin-top:$gutter;
    margin-right: - $gutter*2;
    margin-left: - $gutter*2;
    margin-bottom: $gutter*2;
    width: calc(100% + #{$gutter}*4);
    height: 1px;
    background:transparent url($separator) 0 0 repeat-x;
    position: relative;
  }
}