@import 'styleBM/abstracts.scss';

@mixin roundedImage ($w:100%, $h:100%, $br:8px) {
    height: $h;
    width: $w;
    border-radius: $br;
    object-fit: cover;
};

@mixin itemColor ($primaryColor: $gray-20) {
    color:$primaryColor;
    stroke:$primaryColor;
    &:hover {
        color:  $white;
        stroke: $white;
    }
    &.active {
        color: $accent-3-80;
        stroke: $accent-3-80;
        cursor: auto;
    }
}

$buttonWidth: 96px;
.menu-main-buttons{
    grid-area: menu;
    display: flex;
    flex-direction: column;
    @include vertical-scrollbar("auto");
    width: $buttonWidth;
    background: $grey-90;
    .back-button{
        border-bottom: 2px solid $grey-70;
    }
    // +.side-menu-open-panel{
    //     border-left: solid;
    //     border-width: 1px;
    //     border-image : $grad-btn;

    // }
    button, a{
        transition: all ease-in-out 200ms;
        background: $grey-90;
        &:hover{
            background: $grey-80;
        }
        flex: 0 0 $buttonWidth;
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-items: center;
        border: none;
        color: $white !important;
        stroke: $white;
    }
    a:hover{
        color: $white;
    }
    button.active{
        background: $grad-btn;
    }
    a.active{
        background: $grad-good;
    }
}
// .side-menu-bm-wrapper{
//     ///temp for working on it
//     position: absolute;
//     top: 0;
//     height: calc(100vh - 4rem);
//     //////////////////////////


.side-menu-open-panel{
    grid-area: main;
    height: calc(100vh - 80px);
    display: flex;
    // width: 100vw;
    // width: 100%;
    z-index: 1000;
    .panel-top{
        >img{
            @include roundedImage(100%, 200px);
            margin-bottom: 16px;
        }
    }
        .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 55px;
        }
        .spacer{
            //we have a SVG here but it's a bit 2 short
            transform: scaleX(1.22);
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .left{            
            width: 593px;
            padding-left: 40px;
            padding-right: 16px;
            border-left: solid;
            border-right: solid;
            border-width: 1px;
            border-image: $grad-btn 1;
            background-color: $black;
            display: flex;
            flex-direction: column;
            &__scroll-wrapper{
                @include vertical-scrollbar(scroll);
                padding-right: 40px;
                padding-top: 34px;
                padding-bottom: 34px;
                margin-top: 16px;
                margin-bottom: 16px;
            }
        }
        .right{
            flex: 1;
            background: rgba(0, 0, 0, 0.6);
            backdrop-filter: blur(4px);
        }
    

    .side-menu-bottom-section{
        text-decoration: none;
        a{
            display: inline-block;
        }
    }
    .a-container{
        display: flex;
        position: relative;
        align-items: center;
        svg:last-of-type{
            margin-left: auto;
        }
        .dot{
            position: absolute;
            left: 0;
            transform: translate(calc(-100% - 8px));
            @include circle(8px);
            background-color: $accent-3-80;
        }
    }
    .menu-level{
        &__1{
            margin-bottom: 16px;
            >.a-container{
                margin-bottom: 16px;
            }
            >.a-container, .a-container>a{
            @include ptype(1, 'strong');
            @include itemColor($white);
            .dot{top: calc(50% - 1px)};
            }
        }
        &__2{
            margin-left: 40px;
            >.a-container, .a-container>a{
                @include ptype(1, 'strong');
                @include itemColor($grey-30);
                .dot{top: calc(50% - 6px)};
                margin-bottom: 8px;
            }
        }
        &__3{
            margin-left: 24px;
            >.a-container, .a-container>a{
                @include ptype(1);
                @include itemColor($grey-20);
                margin-bottom: 8px;
            }
        }
    }

}