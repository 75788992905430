.central-modal-overlay{
  backdrop-filter: blur(5px);
  z-index: 90;
  background: rgba(0,0,0, 0.8);
  &.big-modal{
    .list-modal-wrapper{
      max-width: 446px;
      background: $grey-85;
      border: 1px solid #333333;
      border-radius: 8px;
      padding: 38px;
      box-shadow: 0 0 40px #A530F675;
      max-height: calc(100vh - #{$gutter*4});
      .header{
        padding: 0;
        height: auto;
        .title{
          @include etype(3);
          margin:0;
          color:$white;
        }
        .close{
          svg{
            user-select: none;
            path{
              fill: $black;
              ~ path{
                fill:none;
                stroke: $white;
              }
            }
            
          }
        }
      }
      .modal-body{
        padding: 0;
        &::-webkit-scrollbar {
          width:2px;
          border-radius:0px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: none;
          background-color: transparent;
          border-radius:0px;
        } 
        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          outline: none;
          border-radius:2px;
          cursor: drag;
        }
        overflow-x: hidden;
        overflow-y: auto;
        @import "FormStyles";
        @import "AddBookForm";
      }
      .big-modal-footer{
        .buttons{
          background: transparent;
          &.right{
            padding: 0;
            justify-content: space-between;
            .magic-button-container{
              padding-right: 17px;
              padding-left: 17px;
              border-radius: 4px;
              flex:0 1 60px;
              &.white{
                background: transparent !important;
                color:$white;
              }
              &.primary{
                background: $grad-good;
              }
            }
          }
        }
      }
    }

    &.add-book-modal{
      .big-modal-footer{
        background: $black;
        padding: 39px;
        position: relative;
        margin: 0 -39px -39px;
        border-radius: 0 0 8px 8px;
        border:solid 1px  #353A3E;
        .buttons.right{
          .magic-button-container{
            &.white{
              background-color: #1A1A1A !important;
            };
          }
        }
      }
    }
  }
}