.bottom-line{
  display: flex;
  padding-top: 8px;
  p{
    margin: 0;
  }
  .pill{
    display: inline-flex;
    @include max-md{
      margin-bottom: auto;
    }
  }
}

.person{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  @include max-md{
    flex-direction: column;
    .pill-wrapper{
      display: flex;
      justify-content: flex-end;
    }
    .pill.large{
      margin-bottom: 1rem;
      margin-left:auto;
    }
  }
  .name{
    color:$white;
    @include etype(2);
    margin-bottom: 8px;
    ~ .name{
      margin-top: -8px;
    }
  }
  .position-held{
    margin-bottom: 17px;
    color:$white;
    @include ptype-2("base");
    strong{
      color:$white;
      @include ptype-2("base-strong");
    }
  }
}