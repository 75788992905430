.invoice-counter{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:$gutter * 2 0 0;
  >div:not(.dotted-line){
    padding: 0 $gutter * 2;
  }
  .heading{
    &-3{  
      @include etype(3);
      text-align: center;
      padding: 0 $gutter * 2;
    }
  }
  .content{
    @include ptype-1("regular");
    text-align: center;
    margin:16px 0 24px;
  }
  .counter{
    display: flex;
    @include etype(6);
    align-items: center;
    margin-bottom: $gutter * 2;
    .count-cell{
      width: 80px;
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
      background:$grad-btn;
      color:$white;
      border-radius: 8px;
      overflow:hidden;
    }
    .sep{
      width: 32px;
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
      color:$grey-75;
    }
    &.expired{
      .count-cell{
        background: $grey-60;
      }
    }
  }
  .dotted-line{
    background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAABCAYAAADXeS5fAAAAFklEQVQImWM0Njb+z4AHPH/+HJ80AwC21wRPVEKf9wAAAABJRU5ErkJggg==') 0 0 repeat-x;
    width: 100%;
    height: 1px;
    display: flex;
    margin-bottom: $gutter * 2;
  }
  

  &.completed{
    user-select: none;
    padding-top: 0;
  }
}

.reserved-tickets{
  width: 100%;
  display: flex;
  flex-direction: column;
  .head{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 $gutter * 2 8px;
    @include ptype-1("medium");
  }
  .footer{
    display: flex;
    flex-direction: column;
    .btn.btn-primary{
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 64px;
      margin-bottom: 16px;
      >div{
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;
        @include etype(2);
        line-height: 1;
        display: flex;
        margin: auto;
      }
      svg{
        width: 32px;
        height: 32px;
        path{
          stroke: $white;
        }
      }
    }
    .security{
      display: flex;
      align-items: center;
      justify-content: center;
      @include ptype-1("regular");
      margin-bottom: 6px;
      >span{
        transform: translateY(1px);
        margin-right: 8px;
        svg{
          height: 17px;
        }
      }
    }
  }
}