.magic-input{
  &.search{
    margin-bottom: 20px !important;
    .inputContainer{
      background: $black;
      svg{
        display: flex;
        margin-right: 5px;
        order: 2;
        path{
          stroke: $white;
        }
      }
      input{
        display: flex;
        order:1
      }
    }
  }
}

.top-row{
  display: none;
}
.list-container{
  margin-bottom: 0;
  max-height: 40vh;
  background: transparent;
  @include vertical-scrollbar;
  padding: 8px 0;

  .list-modal-card-1-wrapper{
    border:0;
    margin: 0;
    color: $white;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0;
    svg{
      fill:$white !important;
    }
    &.selected{
      background: $black;
    }
    .rectangle{
      width: 31px;
      height: 46px;
      border-radius: 0;
      >img{
        object-fit: cover;
      }
    }
    .company{
      color: $white;
      strong{
        font-family: $Grotesque;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        margin-bottom: 4px;
      }
      span{
        color:#747689;
        font-family: $Grotesque;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
      }
    }
  }
}
.selection-indicator{
  display: none;
}