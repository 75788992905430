&.education{
  padding:20px $gutter*2 $gutter*2;
  @import "edit-head";
  .edu-out{
    position: relative;
    @include min-sm{
      &:after{
        content:"";
        position: absolute;
        top:0;
        right:0;
        bottom:24px;
        width: 150px;
        height: calc(100% - 24px);
        background: linear-gradient(to right,  #1A1A1A00 0%,#1A1A1A 100%);
      }
    }
  }
  .edu-list{
    display: flex;
    @include horizontal-scrollbar("auto");
    position: relative;
    .inner{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
  .edu-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: $gutter*2;
    background: $grey-75;
    border-radius: 8px;
    overflow:hidden;
    width: 338px;
    padding:$gutter*2;
    &:not(:last-child){
      margin-right: $gutter*2;
    }
    &:last-child{
      margin-right: 100px;
    }
    .title{
      @include ptype(0,"base");
      color:$white;
      margin-bottom: 20px;
    }
    .text{
      margin: 0 0 8px;
      @include ptype(0,"strong");
      color:$white;
    }
    .date{
      font-family:$Grotesque;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      margin-bottom: $gutter*2;
      color:$grey-55;
    }
  }
}