@mixin safe-margins {
  //margins to get content out of gradient at extremes
  margin-top: 50px;
  margin-bottom: 50px;
}

.agenda{
  margin-bottom: 0;
  .heading{
    &-2{
      @include etype(5);
      margin-bottom:$gutter + 5;
    }
  }

  .agenda-filter{
    padding-left: 0;
    .hfsv2{
      padding: 0;
      .btn-dashed{
        &.more{
          margin-top:8px;
          margin-right: 0;
        }
        &.less{
          margin-top:8px;
          margin-right: 0;
        }
      }  
    }
    .filter-wrapper > div{
      margin-top:8px;
      margin-right: 8px;
    }
    + .agenda-card{
      margin-top: 24px;
    }
  }

  .agenda-card{
    padding-top: 40px;
    flex-direction: column;

    .date{
      &-filter{ 
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 18px;
        .filter-inner{
          display: flex;
          flex-wrap:wrap;
          align-items: center;
          justify-content: center;
          max-width: 760px;
          .btn{
            margin-left:13px;
            margin-right: 13px;
            margin-bottom: 16px;
            height: 40px;
            border-radius: 20px;
            background-color: #888D95;
            padding: 1px;
            @include ptype(0,"base");
            &.btn-active{
              background: $grad-btn;
              >.inner{
                background: $grad-btn;
                height: 38px;
                min-height: 38px;
                border-radius: 19px;
              }
            }
            >.inner{
              height: 38px;
              min-height: 38px;
              border-radius: 19px;
              background: linear-gradient(266.25deg, #000000 6.3%, #272729 93.57%);
              padding:0 $gutter * 2;
              >img{
                margin-right: 10px;
              }
            }
            ~ .btn{
              margin-left:13px;
              margin-right: 13px;
              min-height: 40px;
              >.inner{
                height: 38px;
                min-height: 38px;
                border-radius: 19px;
              }
            }
          }
        }
      }
      &-content{
        display: flex;
        position: relative;
        .timer{
          @include safe-margins;
          display: flex;
          flex-direction: column;
          width: 50px;
          position: relative;
          z-index: 11;
          .time{
            display: flex;
            justify-content: flex-end;
            @include etype(1);
            color:$grey-65;
            margin-bottom: 18px;
            &:last-child{
              margin-bottom: 40px;
            }
            &.odd{
              @include etype(2);
              color:$white;
            }
            &.active-time{
               color: #6DB4C4;
            }
          }
        }
        .btn-scroll-right{
          width: 96px;
          height: 96px;
          display: flex;
          position: absolute;
          z-index: 3;
          right:28px;
          top:50%;
          transform: translateY(-50%);
          cursor: pointer;
          border:0;
          outline: none;
          border-radius: 50%;
          overflow: hidden;
          background: transparent;
        }
      }
    }

    &.new-style{
      position: relative;
      >.wrapper{
        &::before{
          content:'';
          position: absolute;
          z-index: 5;
          top:0;
          right:8px;
          bottom:0;
          left:0;
          width:calc(100% - 8px);
          height: calc(100% - 8px);
          pointer-events: none;
          background: linear-gradient(to right,  rgba(26,26,26,1) 0%,rgba(26,26,26,0) 15%,rgba(26,26,26,0) 85%,rgba(26,26,26,1) 100%);
        }
        &::after{
          content: "";
          position: absolute;
          z-index: 1;
          right: 0;
          bottom: 0;
          width: 8px;
          height: 8px;
          display: flex;
          background: $grey-90;
          pointer-events: none;
        }
        > .inner{
          &::before{
            content:'';
            position: absolute;
            z-index: 10;
            top:0;
            left:0;
            right:8px;
            background: linear-gradient(180deg, #1A1A1A 0%, rgba(26, 26, 26, 0) 100%);
            width: calc(100% -8px);
            height: 56px;
          }
          &::after{
            content:'';
            position: absolute;
            z-index: 10;
            bottom:8px;
            left:0;
            right:8px;
            background: linear-gradient(0deg, #1A1A1A 3%, rgba(26, 26, 26, 0) 100%);
            width: calc(100% - 8px);
            height: 56px;
          }
        }
      }
      .wrapper{
        position: relative;
        margin-right: 50px;
        .timer-line{
          width: calc(100% - 15px);
          height: 2px;
          display: flex;
          position: absolute;
          z-index: 11;
          top:12px;
          left:15px;
          background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAACCAYAAABsfz2XAAAAPUlEQVQImWM8rXfvPyMjI8PlH+cYEm4EMTKgAQ85n/+tQpMZ/v//z8DIyMjAxMDAwPD//390dRiAkRFiFgBPeg/cGfCG0QAAAABJRU5ErkJggg==') 0 0 repeat-x;
          transition: all 0.25s ease-out;
        }
      }
      .date-content{
        overflow: auto;
        height:654px;
        position: relative;
        overflow: scroll;
        scrollbar-gutter:auto;
        &::-webkit-scrollbar {
          height:8px;
          width:8px;
          border-radius:4px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: none;
          background: black;
          border-radius:4px;
        } 
        &::-webkit-scrollbar-thumb {
          background:$grey-75;
          outline: none;
          border-radius:4px;
        }
        
        
        .appointments{
          position: relative;
          display: flex;
          height: 2754px;
          width: 100%;
          @include safe-margins;
          .wrapper{
            display: flex;
            flex-wrap: nowrap;
            padding-right: 150px;
            background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAESCAYAAAA8IL8IAAAAKUlEQVQ4je3SMREAMAwDMV0ohT82dyyFDP5BCN7uZkASg1Ju4s/a2u0e4IQHPrPiUyQAAAAASUVORK5CYII=') 24px 12px repeat repeat;
            min-width: 100%;
            &::before{
              content:'';
              background:#1A1A1A;
              width:15px;
              min-width: 15px;
              height: 100%;
              position: relative;
              left: 0;
            }
          }
        }
        .timer{
          min-width: 56px;
          .time{
            margin-bottom: 115.5px;
          }
        }
      }
    }
  }

  
}

// @import "agenda/schedule-item";