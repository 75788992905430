.invoice-card{
  padding:32px 24px;
  width: 100%;
  min-height:144px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow:hidden;
  margin-bottom:$gutter * 2 ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  &.status{
    &-paid{
      background-color: $all-good;
    }
    &-pending{
      background-color: $danger;
    }
    &-expired{
      background-color: $grey-60;
    }
    &-overdue{
      background-color: $alert;
    }
  }
  .heading{
    &-3{
      @include etype(4)
    }
  }
  .date{
    @include ptype-1("base-strong");
    color:$white;
  }
  .btn.btn-plain{

    >.left{
      padding:0 24px;
    }
    svg{
      width: 32px;
      height: 32px;
      transform: translate(-8px,-2px);
      path{
        stroke: $white;
      }
    }
  }
  .content{
    padding-right: 16px;
    min-width:175px;
  }
  .content,
  .btn.btn-plain{
    margin: 8px 0;
  }
}