&.fail-page{
	.header-section{
		padding-bottom: $gutter;
	}
	.success-header{
    display: flex;
    img{
      margin-right: 8px;
    } 
  }
	.header-section{
		padding-top: 24px;
		.fail-img,
		.heading{
			margin-top:$gutter;
			margin-bottom: $gutter*2 ;
		}
		.subtitle{
			font-family:$Grotesque;
			font-size: 20px;
			font-weight: 400;
			line-height: 24px;
			margin: 0;
		}
		.title{
			margin:4px 0 $gutter*2 0;

		}
	}
	.invoice-detail{
		.heading-3{
			@include etype(2);
			margin-bottom:16px;
		}
    .detail{
      display: flex;
      flex-direction: column;
    }
    .buttons{
      margin-top: 8px;
    }
  }
	.bank-transfer{
    display: flex;
		margin-bottom: $gutter*2;
		justify-content: flex-start;
    .transfer-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $gutter * 2;
      .heading{
        &-3{
          @include etype(3);   
        }
      }
      .info{
        .info-btn{
          svg{
            path{
              fill:$white;
            }
          }
        }
        &.opened{
          .tooltip{
            right: 30px;
          }
        }
      }
    }
    .thick-line{
      width: calc(100% + #{$gutter*4});
      margin-left:  - $gutter*2;
      margin-top: $gutter*2 ;
      margin-bottom: $gutter;
    }
    .heading{
      &-5{
        @include etype(2);
        margin-bottom: $gutter*2;
        margin-top: $gutter*2;
      }
    }
    .content{
      margin: 0 ;
      @include ptype(0,"base");
      color:$white;

    }
		.btn{
			&.btn-link{
				margin:$gutter 0 0;
				height: auto;
				>div{
					padding: 0;
					min-height: auto;
					.ico{
						width: 22px;
            margin-right: 8px;
					}
					svg{
						height: 22px;
					}
				}
				
			}
      &.btn-grad-warn-outline{
        margin-top: $gutter*2;
        margin-bottom: 0;
        svg{
          height: 32px;
          path{
            stroke: $white;
          }
        }
      }
		}
  }
	.card{
    &.count-down{
      padding:0 0 $gutter * 2;
      margin-bottom: $gutter * 2;
      &.no-tickets {
        .reserved-tickets .head {
          span {
            &.total {
              margin-top: 16px;
            }
            @include etype(2);
            + span {
            display: none;
          }
          } 
        }
      }
      .counter-wrapper{
        display: flex;
        flex-wrap: nowrap;
        justify-content:space-between;
        align-items: center;
        background: $grey-75;
        margin-bottom:10px;
        border-radius: 8px;
        padding: 16px $gutter*2;
        border:0;
        box-shadow: 0 0 0 1px #fff2 inset;
        overflow: hidden;
        position: relative;
        &::before,
        &::after{
          content:"";
          position:absolute;
          z-index: 1;
          background: $grey-85;
          width: 10px;
          height: 10px;
          display: flex;
          box-shadow: 0 0 0 1px #fff4 inset;
          border-radius: 50%;
          top:50%;
        }
        &::before{
          left:0;
          transform: translate(-4px,-4px);
        }
        &::after{
          right:0;
          transform: translate(4px,-4px);
        }
        &:last-child{
          margin-bottom: 8px;
        } 
        .ticket-counter {
          justify-content: flex-end;
          .c-btn {
            display: none;
          }
        }
      }
      .left-side{
      display: flex;
      flex-direction: column;
      >.title{
        text-transform: uppercase;
        @include etype(2);
        margin: 0 0 9px;
      }
      .prices{
        align-items: flex-start;
        min-height: auto;
        .line{
          &-1{
            font-family:$Grotesque;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;

          }
        }
        .ticket-price{
          .amount{
            @include etype(7);
          }
          .currency{
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
          }
        }
      }
      .btn-ticket{
        width: auto;
        height: 44px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 22px;
        background: $danger;
        color: $white;
        overflow: hidden;
        border:0;
        outline: none;
        padding: 12px;
        font-family:$Grotesque;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        margin-top: 9px;
        margin-right: auto;
        svg{
          margin-right: 8px;
        }
        &.sold{
          text-transform: uppercase;
          background: #454961;
        }
      }
      }
    }
    &.invoice-detail{
      display: flex;
      margin-bottom: $gutter * 2;
      .heading-3{
        @include etype(2);
        margin-bottom:16px;
      }
    }
  }
	.ticket.reserved{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    min-height: 80px;
    align-items: center;
    padding:$gutter * 2;
    background-color: $black;
    .pill{
      margin-left: 0;
      margin-top: 4px;
      text-transform: capitalize;
    }
    .amount{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      padding-left:$gutter;
      text-align: center;
    }
  }
	
	.invoice-card.status-overdue.modified{
		margin-bottom: $gutter*2;
		min-height: 205px;
    margin-top: 40px;
		.content{
			max-width: 70%;
		}
	}
	.card-type{
    .heading-5{
      @include etype(3);
    }
    .content{
      p{
        @include ptype-1("base");
        font-size: 14px;
        line-height: 18px;
        &:last-child{
          margin-bottom: 0;
        }
        strong{
          @include ptype-1("base-strong");
          font-size: 14px;
          line-height: 18px;
        }
      }
      a{
        color: $white;
        &:hover{
          color: $white;
          text-decoration: underline;
        }
      }
    }
    &.card-2{
      justify-content: center;
      padding-top: 64px;
      padding-bottom: 64px;
    }

    .top-line{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .date{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 0;
        > span{
          &:first-child{
            color: $white;
          }
          + span{
            margin-top: 2px;
          }
        }
      }
    }

    .top-section{
      padding: 24px 24px 16px;
      // border-bottom: 1px dashed $grey-75;
      min-height: 135px;
      .due-date, .expired, .cancelled {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $grey-75;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px;
        margin-top: 40px;
      }
      .due-date {
        background: $danger;
      }
      .expired, .cancelled {
        background: $alert;
      }
    }
    .middle{
      &-1,
      &-2{
        padding:16px 24px;
        display: flex;
        flex-direction: column;
        min-height: 97px;
        .subtitle{
          font-weight: 700;
        }
        .tag{
          margin-top: 4px;
        }
      }
      &-1{
        margin-bottom: auto;
        font-size: 16px;
        line-height: 19px;

        .subtitle{
          margin-bottom: 8px;
        }
        .tranzaction{
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .right{
            >div:not(.tag){
              text-align: right;
              padding-right: 2px;
              margin-right: 0;
            }
          }
        }
      }
      &-2{
        background-color: $black;
        .name{
          display: flex;
          align-items: center;
          .initials{
            margin-right: 8px;
            margin-top: 10px;
          }
        }
      }
    }
    &.order,
    &.invoice{
      justify-content: space-between;
      .heading-5{
        @include etype(2);
      }
    }
    &.invoice{
      .thick-line{
        margin-top: $gutter;
        margin-bottom: $gutter;
      }
      .middle{
        &-1{
          .price-vat{
            span{
              + span{
                color:#999999;
              }
            }
          }
        }
        &-2{
          >.content{
            display: flex;
            align-items: center;
            margin-top: 17px;
          }
          svg{
            margin-right: 4px;
            path{
              stroke:$white;
            }
          }
        }
      }
			.bottom-section.buttons{
				padding:$gutter 0 0;
			}
    }
  }
  .thick-line{
    width: 100%;
    height: 2px;
    display: flex;
    background-size: 20px;
    background-repeat:repeat-x;
  }
  @import './order-status.scss';
}