.magic-input {
  stroke: $white;
  transition: all 0.2s ease-out;
  width: 100%;
  .label {
    color:$white;
    font-family: "Basis Grotesque Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 12px;
    &.is-valid {
      color:$all-good;
    }
  }
  &.error {
    stroke: $alert;
    .label {
      color: $alert;
    }
    &.error {
      stroke: $alert;
      .label {
        color: $alert;
      }
      input,
      textarea {
        color: $alert;
      }
    }
  }
  .inputContainer {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.5rem;
    color: $white;
    background-color: $grey-75;
    border: 2px solid $grey-75;
    border-radius: 8px;
    overflow:hidden;
    &:focus-within {
      border: 2px solid $accent-2;
      &.error {
        border: 2px solid $alert;
      }
      &.is-valid {
        border: 2px solid $all-good;
      }
    }
    &:focus-within {
      border: 2px solid $accent-2;
    }
    &.error {
        border: 2px solid $alert;
    }
    &.is-valid {
      border: 2px solid $all-good;
    }
    &.outline {
      border: 2px solid $grey-75;
      &:focus-within {
        border: 2px solid $accent-2;
      }
      &.error {
        border-color: $alert;
      }
      &.is-valid {
        border-color: $all-good;
      }
    }
    &.dark {
      background-color: $black;
      border: 2px solid $grey-75;
      &:focus-within {
        border: 2px solid $accent-2;
      }
      &.error {
        border-color: $alert;
      }
      &.is-valid {
        border-color: $all-good;
      }
    }
    &.disabled {
      background-color: $black;
      pointer-events: none;
      user-select: none;
      svg{
        margin-left: 12px;
        margin-right: 4px;
      }
      input{
        @include placeholder($grey-10,0.25);
      }
      .actionIcon{
        background-color: inherit;
      }
    }
    &.with-flag{
      .form-control{
        padding-left: 50px;
      }
    }
    &.card{
      position: relative;
      .form-control{
        padding-left: 50px;
      }
      svg{
        position: absolute;
        top:0;
        left:0;
        stroke: transparent;
        transform: translate(15px,12px);
      }
    }
    .actionIcon{
      border: none;
      stroke: #005CB9; //TODO: replace w color code;
      justify-content: flex-end;
      &:hover {
        border: none;
        stroke-width: 1.5;
      }
    }

    .form-control{
      position: relative;
      display: flex;
      width: 100%;
      input{
        display: flex;
        padding-right: 48px;
        + svg{
          position: absolute;
          top:0;
          right:0;
          stroke: transparent;
          transform: translate(-10px,15px);
        }
        + .flag{
          position: absolute;
          top:11px;
          left:14px;
        }
      }
    }

    input {
      border: none;
      margin-left: 0.5rem;
      color:$white;
      @include ptype;
      background-color: transparent;
      width: 100%;
      height: 54px;
      border-radius: 8px;
      @include placeholder($grey-10,0.5);

      &:disabled {
        color: $white;
      }
      &:hover,&:focus {
        outline: none;
      }
      &.small {user-select: none;}
    }
  }

  &.textarea{
    .inputContainer{
      height: auto !important;
    }
  }
}