.badge--user{
  user-select: none;
  display: flex;
  position: relative;
  .nr{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 2;
    @include etype(1);
    text-align: center;
    padding-top: 40px;
  }
  .date{
    position: absolute;
    top:91px;
    right:55px;
    z-index: 1;
    color:#fff5;
    text-align: right;
    @include etype(1);
    transform: rotate(-30deg);
  }
  &.badge-1{
    .nr{
      @include etype(4);
      text-align: center;
    }
    .date{
      top: 90px;
      right: 46px;
    }
  }

  + .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    @include min-lg{
      align-items: flex-start;
      transform: translateX(-70px);
      margin-top: unset;
    }
    @include min-xl{
      align-items: center;
      transform: translateX(0);
      margin-top: 32px;
    }
    .message{
      @include etype(2);
    }
    .date{
      font-family:$Grotesque;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color:$grey-55;
    }
  }
}