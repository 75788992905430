

.tabel-title{
  padding:12px 0;
  color:$white;
  @include ptype-1("regular","normal")
}

.filter-row{  
  width: 100%;
  background-color: $black;
  &.bg-white{
    background-color:$black;
    .hfsv2{
      background-color:$black;
    }
  } 
  .filter-wrapper{
    display: flex;
    flex-grow: 1;
    gap: 16px;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    @media (max-width:780px) {
      flex-wrap: wrap;
    }
    >div{
      margin: 0;
      height: 48px;
      // flex-grow: 1;
    };
    >button{
      margin-left: auto;
      margin-top: 8px;
    }
  }

  .btn-holder{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-right: 0;
    @media (max-width:780px) {
      width: 100%;
      align-items: flex-end;
    }
    >button{
      margin-right: 12px;
    }
  }

  .hfsv2{
    display: flex;
    flex-wrap: wrap;
    background-color: $black;
    margin-bottom: 0;
    .search{
      flex-basis: 300px;
      .inputContainer {
        border-radius: 4px;
        background-color: white;
        height: 48px!important;
      }
    }
    >div{
      margin-top: 0;
      margin-right: 0;
      &:first-child{
        margin-left: 0;
      }
    };
    >button{
      margin-left: auto;
      margin-top: 8px;
    }
    .btn-holder{
      .btn-dashed{
        margin-top: 16px;
        background: $grey-85;
        border: 1px solid $grey-85;
        border-radius: 4px;
        padding:14px 52px 14px 16px;
        height: 48px;
        min-width: 150px;
        color:#B3B3B3;
        @include ptype-1("regular","normal");
        cursor: pointer;
        transition: all 0.25s ease-out;
        display: inline-flex;
        position: relative;
        transform: translateY(0);
        &:hover,
        &:active{
          background:$accent-3;
          border: 1px dashed $accent-3;
          color:$white;
          border-radius: 4px;
        }
        svg{
          position: absolute;
          z-index: 1;
          top:50%;
          right:0;
          transform: translateY(-50%);
          margin-right: 8px;
          path{
            stroke:$white;
          }
        }
        &.less{
          transform: translateY(10px);
          svg{
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
  }

  &.filter-active{
    .hfsv2{
      width: 100%;
      display: contents;
    }
    .filter-wrapper{
      flex-wrap: wrap;
      >div{
        &:last-child{
          margin-right:160px;
        }
      }
    }
    .btn-holder{
      height: 0;
      transform: translateY( calc(-100% + -12px));
      align-items: flex-end;
    }
  }

  @media (max-width:780px){
    .hfsv2{
      width: 100%;
      display: contents;
    }
    .filter-wrapper{
      flex-wrap: wrap;
      >div{
        &:last-child{
          margin-right:160px;
        }
      }
    }
    .btn-holder{
      height: 0;
      transform: translateY( calc(-100% + -12px));
      align-items: flex-end;
    }
  }
}
