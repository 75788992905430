.achievement{
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto auto $gutter*4;
  .add-btn{
    background: transparent;
    border:0;
    outline: none;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.25s ease-out;
    margin-bottom: 12px;
    &:hover{
      svg{
        rect:first-child{
          opacity: 0.09;
        }
      }
    }
  }
  .label{
    @include ptype-1("base-strong");
    color: $white;
    margin-bottom:20px;
  }
  .pill{
    min-height: 40px;
    border-radius: 20px;
    padding-right: $gutter*2;
    padding-left: $gutter*2;
    @include ptype(0,"strong");
    line-height: 1;
    svg{
      margin-right: 8px;
    }
  }
}