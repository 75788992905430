&.profile-contacts-page{
  .container-fluid.padding-double.followers{
    @include max-sm{
      padding-top: $gutter*2;
    }
  }
  .card{
    &.networking{
      margin-bottom: $gutter*2;
      .wrapper{
        @include max-sm{
          padding: 0;
        }
        .btn{
          margin-top: 16px;
          margin-bottom: 0;
          &-wrapper{  
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}