//////////////////////////
/////// UNIPREST COLORS
//////////////////

$grey-50: #f3f3f3;
$grey-40: #e6e6e6;
$grey-30: #D9D9D9;
$grey-20: #cccccc;
$grey-10: #f3f3f3;
$grey-16: #161616;

$blue-10: #004386;
$blue-30: lighten($blue-10, 20%);
$blue-40: #86c2ff;
$blue-50: #b9dcff;
$blue-60: #ecf5ff;
$white: #ffffff;
$black: #000000;
$red-10: #b70020;
$steel-grey: #b3b3b3;
$text-light-01: #333333;
$text-light-02: #666666;
$text-light-03: #b3b3b3;
$text-link: #005cb9;

$button-prime: #005cb9;
$hover-button-prime: #0075ec;
$click-button-prime: #004386;
$button-secondary: #b3b3b3;
$hover-button-secondary: #c0c0c0;
$click-button-secondary: #999999;
$button-focus: #ea0029;
$hover-button-focus: #ea0029;
$click-button-focus: #b70020;
$button-inactive: #e0e0e0;
$hover-button-inactive: #c6c6c6;
$table-tag-blue: #86c2ff;

$alert-red: #da1e28;
$alert-danger: #ff832b;
$alert-warning: #f1c21b;

$placeholderBlue: #edf5ff;
$green-40: #42be65;
$green-60: #198038;
$green2: #27ae60;
$all-good: #24a148;

$magenta-60: #d02670;
$teal-60: #007d79;
$yellow-60: #e5be30;
$purple-60: #8a3ffc;
$purple-1: #9b51e0;
// $blue-50: #B9DCFF;
// $ilustrationBlue:

//K3 colors
$bluebkg: #edf5ff;
$cyan: #0072c3;
$cyan-1: #1192e8;
$cyan-2: #33b1ff;
$cyan-3: #82cfff;
$cyan-4: #bae6ff;
$cyan-5: #e5f6ff;
$cyan1: #00539a;
$cyan2: #003a6d;
$cyan3: #012749;
$cyan4: #061727;
$dark-base-1: #262626;
$dark-base-2: #393939;
$dark-base-3: #525252;
$dark-base-4: #6f6f6f;
$darkbkg: #000000;
//TODO: update full color palette
$blue-1: #4589ff;
$blue-3: #a6c8ff;
$blue1: #0043ce;
$blue2: #002d9c;
$green: #198038;
$green-1: #24a148;
$green-2: #42be65;
$green-3: #6fdc8c;
$green-4: #a7f0ba;
$green-5: #defbe6;
$green1: #0e6027;
$green2: #044317;
$green3: #022d0d;
$green4: #071908;
$greybkg: #f4f4f4;
$light-base: #f4f4f4;
$light-base1: #e0e0e0;
$light-base2: #c6c6c6;
$light-base3: #a8a8a8;
$light-base4: #8d8d8d;
$light-purewhite: #ffffff;
$lighterdarkbkg: #333333;
$magenta: #d02670;
$magenta-1: #ee5396;
$magenta-2: #ff7eb6;
$magenta-3: #ffafd2;
$magenta-4: #ffd6e8;
$magenta-5: #fff0f7;
$magenta1: #9f1853;
$magenta2: #740937;
$magenta3: #510224;
$magenta4: #2a0a18;

$primary1: #0043ce;
$primary2: #002d9c;
$primary3: #001d6c;
$primary4: #001141;
$primary-1: #4589ff;
$primary-2: #78a9ff;
$primary-3: #a6c8ff;
$primary-4: #d0e2ff;
$primary-5: #edf5ff;
$primary: #0f62fe;

$purple: #8a3ffc;
$purple-1: #a56eff;
$purple-2: #be95ff;
$purple-3: #d4bbff;
$purple-4: #e8daff;
$purple-5: #f6f2ff;
$purple1: #6929c4;
$purple2: #491d8b;
$purple3: #31135e;
$purple4: #1c0f30;
$purple-80:#454961;
$red: #da1e28;
$red-1: #fa4d56;
$red-2: #ff8389;
$red-3: #ffb3b8;
$red-4: #ffd7d9;
$red-5: #fff1f1;
$red1: #a2191f;
$red2: #750e13;
$red3: #520408;
$red4: #2d0709;

$selectionbkg: #e0e0e0;
$selectiondarkbkg: #393939;
$teal: #007d79;
$teal-1: #009d9a;
$teal-2: #08bdba;
$teal-3: #3ddbd9;
$teal-4: #9ef0f0;
$teal-5: #d9fbfb;
$teal1: #005d5d;
$teal2: #004144;
$teal3: #022b30;
$teal4: #061727;
$whitebkg: #ffffff;

$purewhite: #ffffff;
$inactive: #c6c6c6;
$detailstype: #8d8d8d;
$darkesttype: #333333;
$actiontype: #0043ce;

$all-good: #24a148;
$system-all-good: #24a148;
$alert-danger: #ff832b;
$alert-critical: #da1e28;
$system-alert: #da1e28;
$accent-1:#A430F4;
$accent-4-80:#FFCD33;

$grey-90:#1A1A1A;
$maib-primary: #036646;
$maib-secondary: #61a60e;
//eof K3 colors



/////////////////////
////////////////////
$white:#ffffff;
$black:#000000;

//Purple
$dark-purple   :#171B3A;
$dark-purple-80:#454961;
$dark-purple-60:#747689;
$dark-purple-40:#A2A4B0;
$dark-purple-20:#D1D1D8;

$green-3: #6FDC8C;
// Accent 1
$accent-1   :#A430F4;
$accent-1-80:#B659F6;
$accent-1-60:#C883F8;
$accent-1-40:#DBACFB;
$accent-1-20:#EDD6FD;

// Accent 2
$accent-2   :#DF4AB7;
$accent-2-80:#E56EC5;
$accent-2-60:#EC92D4;
$accent-2-40:#F2B7E2;
$accent-2-20:#F9DBF1;

// Accent 3
$accent-3   :#49A1B5;
$accent-3-80:#6DB4C4;
$accent-3-60:#92C7D3;
$accent-3-40:#B6D9E1;
$accent-3-20:#DBECF0;

// Accent 4
$accent-4   :#FFC100;
$accent-4-80:#FFCD33;
$accent-4-60:#FFDA66;
$accent-4-40:#FFE699;
$accent-4-20:#FFF3CC;

// GREY
$grey :#000000;
$grey-100:#000000;
$grey-90:#1A1A1A;
$grey-85:#333333;
$grey-80:#333333;
$grey-75:#4D4D4D;
$grey-70:#4D4D4D;
$grey-65:#666666;
$grey-60:#666666;
$grey-55:#808080;
$grey-50:#808080;
$grey-45:#999999;
$grey-40:#999999;
$grey-30:#B3B3B3;
$grey-20:#CCCCCC;
$grey-10:#E6E6E6;

// Interests
$interest-low:    linear-gradient(  87deg, #4BBCA8 0%,  #6EDCAE 100%);
$interest-mid:    linear-gradient(  87deg, #FF9900 0%,  #F99F5F 100%);
$interest-high:   linear-gradient(  87deg, #F6604D 0%,  #FC7E6D 100%);
$interest-na:     linear-gradient(  266deg, $black   6%,  #272729 95% );

// GRADIENTS
$grad-main:         linear-gradient(282deg, $dark-purple  0%, $accent-3 100%);
$grad-secondary:    linear-gradient(279deg, $accent-2     7%, $accent-3 100%);
$grad-warn:         linear-gradient(266deg, #4A7DDF     0%, #B56949 100%);
$grad-warning:         linear-gradient(266deg, #4A7DDF     0%, #B56949 100%);
$grad-good:         linear-gradient(266deg, #4A7CDF     0%, #49B595 100%);
$grad-good-reverse: linear-gradient(266deg, #49B595     0%, #4A7CDF 100%);
$grad-good-active:  linear-gradient(266deg, darken (#4A7CDF, 10%) 0%, darken (#49B595, 20%) 100%);
$grad-good-hover:   linear-gradient(266deg, lighten(#4A7CDF, 10%) 0%, lighten(#49B595, 10%) 100%);
$grad-btn:          linear-gradient( 90deg, #B010FF     0%, #F537B9 100%);
$grad-btn-hover:    linear-gradient( 90deg, lighten(#B010FF, 10%)  0%, lighten(#F537B9, 10%) 100%);
$grad-btn-active:   linear-gradient( 90deg, darken (#B010FF, 10%)  0%, darken (#F537B9, 10%) 100%);
$grad-test:         linear-gradient( 91deg, $black        0%, $accent-3 216%);
$grad-bad:				  linear-gradient(	180deg,rgba(218,30,40,.45) 4.16%, $black 70%);


// SYSTEM
$alert:   #DA1E28;
$danger:  #FF832B;
$warning: #F1C21B;
$all-good:#24A148;
$primary: #0F62FE;


$text-light-01: #333333;
$text-light-02: #666666;
$text-light-03: #b3b3b3;

$allBmColors : (
    white:#ffffff,
    black:#000000,

    text-light-01: #333333,
    text-light-02: #666666,
    text-light-03: #b3b3b3,
    //Purple
    dark-purple   :#171B3A,
    dark-purple-80:#454961,
    dark-purple-60:#747689,
    dark-purple-40:#A2A4B0,
    dark-purple-20:#D1D1D8,

    green-3: #6FDC8C,
    // Accent 1
    accent-1   :#A430F4,
    accent-1-80:#B659F6,
    accent-1-60:#C883F8,
    accent-1-40:#DBACFB,
    accent-1-20:#EDD6FD,

    // Accent 2
    accent-2   :#DF4AB7,
    accent-2-80:#E56EC5,
    accent-2-60:#EC92D4,
    accent-2-40:#F2B7E2,
    accent-2-20:#F9DBF1,

    // Accent 3
    accent-3   :#49A1B5,
    accent-3-80:#6DB4C4,
    accent-3-60:#92C7D3,
    accent-3-40:#B6D9E1,
    accent-3-20:#DBECF0,

    // Accent 4
    accent-4   :#FFC100,
    accent-4-80:#FFCD33,
    accent-4-60:#FFDA66,
    accent-4-40:#FFE699,
    accent-4-20:#FFF3CC,

    // GREY
    grey-100:#000000,
    grey-90:#1A1A1A,
    grey-85:#333333,
    grey-80:#333333,
    grey-75:#4D4D4D,
    grey-70:#4D4D4D,
    grey-65:#666666,
    grey-60:#666666,
    grey-55:#808080,
    grey-50:#808080,
    grey-45:#999999,
    grey-40:#999999,
    grey-30:#B3B3B3,
    grey-20:#CCCCCC,
    grey-10:#E6E6E6,

    // Interests
    // interest-low:    linear-gradient(  87deg, #4BBCA8 0%,  #6EDCAE 100%),
    // interest-mid:    linear-gradient(  87deg, #FF9900 0%,  #F99F5F 100%),
    // interest-high:   linear-gradient(  87deg, #F6604D 0%,  #FC7E6D 100%),
    // interest-na:     linear-gradient(  266deg, $black   6%,  #272729 95% ),

    // GRADIENTS
    // grad-main:       linear-gradient(282deg, $dark-purple  0%, $accent-3 100%),
    // grad-secondary:  linear-gradient(279deg, $accent-2     7%, $accent-3 100%),
    // grad-warn:       linear-gradient(266deg, #4A7DDF     0%, #B56949 100%),
    // grad-good:       linear-gradient(266deg, #4A7CDF     0%, #49B595 100%),
    // grad-good-active:linear-gradient(266deg, darken (#4A7CDF, 10%) 0%, darken (#49B595, 20%) 100%),
    // grad-good-hover: linear-gradient(266deg, lighten(#4A7CDF, 10%) 0%, lighten(#49B595, 10%) 100%),
    // grad-btn:        linear-gradient( 90deg, #B010FF     0%, #F537B9 100%),
    // grad-btn-hover:  linear-gradient( 90deg, lighten(#B010FF, 10%)  0%, lighten(#F537B9, 10%) 100%),
    // grad-btn-active: linear-gradient( 90deg, darken (#B010FF, 10%)  0%, darken (#F537B9, 10%) 100%),
    // grad-test:       linear-gradient( 91deg, $black        0%, $accent-3 216%),
    // grad-bad:				linear-gradient(	180deg,rgba(218,30,40,.45) 4.16%, $black 70%),

    // SYSTEM
    alert:   #DA1E28,
    danger:  #FF832B,
    warning: #F1C21B,
    all-good:#24A148,
    primary: #0F62FE,

);


$allUniColors: (
  grey-50: #f3f3f3,
  grey-40: #e6e6e6,
  grey-30: #D9D9D9,
  grey-20: #cccccc,
  grey-10: #f3f3f3,
  grey-16: #161616,
  blue-10: #004386,
  blue-30: lighten($blue-10, 20%),
  blue-40: #86c2ff,
  blue-50: #b9dcff,
  blue-60: #ecf5ff,
  white: #ffffff,
  black: #000000,
  red-10: #b70020,
  steel-grey: #b3b3b3,
  text-light-01: #333333,
  text-light-02: #666666,
  text-light-03: #b3b3b3,
  text-link: #005cb9,
  button-prime: #005cb9,
  hover-button-prime: #0075ec,
  click-button-prime: #004386,
  button-secondary: #b3b3b3,
  hover-button-secondary: #c0c0c0,
  click-button-secondary: #999999,
  button-focus: #ea0029,
  hover-button-focus: #ea0029,
  click-button-focus: #b70020,
  button-inactive: #e0e0e0,
  hover-button-inactive: #c6c6c6,
  alert-red: #da1e28,
  alert-danger: #ff832b,
  alert-warning: #f1c21b,
  placeholderBlue: #edf5ff,
  green-40: #42be65,
  green2: #27ae60,
  all-good: #24a148,
  magenta-60: #d02670,
  teal-60: #007d79,
  yellow-60: #e5be30,
  purple-60: #8a3ffc,
  purple-1: #9b51e0,
  //=== K3 colors ===
  green4: #071908,
  cyan4: #061727,
  teal4: #061727,
  magenta4: #2a0a18,
  red4: #2d0709,
  dark-base-2: #393939,
  // green2 : #044317, TODO: duplicate w diff colorCode
  cyan2: #003a6d,
  blue2: #002d9c,
  blue-1: #4589ff,
  blue-3: #a6c8ff,
  teal2: #004144,
  magenta2: #740937,
  red2: #750e13,
  dark-base-4: #6f6f6f,
  green: #198038,
  cyan: #0072c3,
  teal: #007d79,
  magenta: #d02670,
  red: #da1e28,
  light-base3: #a8a8a8,
  green-2: #42be65,
  cyan-2: #33b1ff,
  teal-2: #08bdba,
  magenta-2: #ff7eb6,
  red-2: #ff8389,
  light-base1: #e0e0e0,
  green-4: #a7f0ba,
  cyan-4: #bae6ff,
  teal-4: #9ef0f0,
  magenta-4: #ffd6e8,
  red-4: #ffd7d9,
  light-purewhite: #ffffff,
  dark-base-1: #262626,
  green3: #022d0d,
  cyan3: #012749,
  teal3: #022b30,
  magenta3: #510224,
  red3: #520408,
  dark-base-3: #525252,
  green1: #0e6027,
  cyan1: #00539a,
  teal1: #005d5d,
  magenta1: #9f1853,
  red1: #a2191f,
  light-base4: #8d8d8d,
  green-1: #24a148,
  cyan-1: #1192e8,
  teal-1: #009d9a,
  magenta-1: #ee5396,
  red-1: #fa4d56,
  light-base2: #c6c6c6,
  green-3: #6fdc8c,
  cyan-3: #82cfff,
  teal-3: #3ddbd9,
  magenta-3: #ffafd2,
  red-3: #ffb3b8,
  light-base: #f4f4f4,
  primary1: #0043ce,
  primary2: #002d9c,
  primary3: #001d6c,
  primary4: #001141,
  primary-1: #4589ff,
  primary-2: #78a9ff,
  primary-3: #a6c8ff,
  primary-4: #d0e2ff,
  primary-5: #edf5ff,
  primary: #0f62fe,
  purple4: #1c0f30,
  purple2: #491d8b,
  purple: #8a3ffc,
  purple-2: #be95ff,
  purple-4: #e8daff,
  purple3: #31135e,
  purple1: #6929c4,
  // purple-1 : #a56eff, TODO: duplicate w diff colorCode
  purple-3: #d4bbff,
  purple-5: #f6f2ff,
  green-5: #defbe6,
  cyan-5: #e5f6ff,
  teal-5: #d9fbfb,
  magenta-5: #fff0f7,
  red-5: #fff1f1,
  selectionbkg: #e0e0e0,
  selectiondarkbkg: #393939,
  whitebkg: #ffffff,
  darkbkg: #000000,
  greybkg: #f4f4f4,
  bluebkg: #edf5ff,
  // lighterdarkbkg: #161616, //care e bun?
  lighterdarkbkg: #333333,
  blue1: #0043ce,
  purewhite: #ffffff,
  inactive: #c6c6c6,
  detailstype: #8d8d8d,
  // darkesttype: #161616,// care e bun?
  darkesttype: #333333,
  actiontype: #0043ce,
  // all-good: #24A148, TODO: duplicate
  // alert-danger: #FF832B, TODO: duplicate
  alert-critical: #da1e28,
  maib-primary: #036646,
  accent-1:#A430F4,
  grey-90:#1A1A1A,
  purple-80:#454961,
  accent-4-80:#FFCD33,

  // eof === K3 colors ===
);


$allBmGradients : (
    // Interests
    interest-low:    linear-gradient(  87deg, #4BBCA8 0%,  #6EDCAE 100%),
    interest-mid:    linear-gradient(  87deg, #FF9900 0%,  #F99F5F 100%),
    interest-high:   linear-gradient(  87deg, #F6604D 0%,  #FC7E6D 100%),
    interest-na:     linear-gradient(  266deg, $black   6%,  #272729 95% ),

    // GRADIENTS
    grad-main:         linear-gradient(282deg, $dark-purple  0%, $accent-3 100%),
    grad-secondary:    linear-gradient(279deg, $accent-2     7%, $accent-3 100%),
    grad-warn:         linear-gradient(266deg, #4A7DDF     0%, #B56949 100%),
    grad-good:         linear-gradient(266deg, #4A7CDF     0%, #49B595 100%),
    grad-good-reverse: linear-gradient(266deg, #49B595     0%, #4A7CDF 100%),
    grad-good-active:  linear-gradient(266deg, darken (#4A7CDF, 10%) 0%, darken (#49B595, 20%) 100%),
    grad-good-hover:   linear-gradient(266deg, lighten(#4A7CDF, 10%) 0%, lighten(#49B595, 10%) 100%),
    grad-btn:          linear-gradient( 90deg, #B010FF     0%, #F537B9 100%),
    grad-btn-hover:    linear-gradient( 90deg, lighten(#B010FF, 10%)  0%, lighten(#F537B9, 10%) 100%),
    grad-btn-active:   linear-gradient( 90deg, darken (#B010FF, 10%)  0%, darken (#F537B9, 10%) 100%),
    grad-test:         linear-gradient( 91deg, $black        0%, $accent-3 216%),
    grad-bad:				   linear-gradient(	180deg,rgba(218,30,40,.45) 4.16%, $black 70%),

);

$allSimpleColors : map-merge($allUniColors, $allBmColors)