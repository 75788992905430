.select-brand.dark {
  display: flex;
  width:100%;
  color:$white;
  flex-direction: column;
  .label {
    display: flex;
    color:$white;
		font-family: Basis Grotesque Pro;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
		letter-spacing: 0em;
		text-align: left;
		margin-bottom: 12px;
  }
	.select-bm {
		display: flex;
		flex-direction: column;
		border-radius: 5px;
		border: 2px solid #4d4d4d;
		background: black;
		padding: 0;
		&:focus-within {
			border-color: transparent;
      background-image: linear-gradient($black, $black), 
                        $grad-good;
      background-origin: border-box;
      background-clip: content-box, border-box;
		}

		&.small-list {
			.crs__menu-list {	
				max-height: 120px;
			}
		}
	}

	.crs{
		&__value-container {
			padding-left: 0;
		}
		&__control{
			flex:1;
		}
		&__placeholder,
		&__single-value{
			font-family: "Basis Grotesque Pro";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: 0em;
			text-align: left;
		}
		&__placeholder{
			color:#B3B3B3;
		}

		&__menu {
			top: 64px;
			left: 0;
    	right: 0;
			width: 100%;
			border: 2px solid $grey-70;
    	border-radius: 5px;
			padding: 0;
			background: $black;
		}

		&__menu-list {
			background: $black !important; 
			padding: 0;
			border-radius: 3px;
			@include vertical-scrollbar;
		}

		&__input {
			opacity: 1;
			color: white;
		}

		&__option {
			background: $black;
			border-bottom: 1px solid $grey-70;
			height: 40px;
			display: flex;
			align-items: center;
			padding: 0 16px;
			&:hover {
				background: $grey-90;
				background-color: $grey-90;
				border-color: transparent;
				border-image: $grad-good;
				border-image-slice: 1;
			}

			&--is-selected {
				background: $grad-good;

				&:hover {
					background: $grad-good;
					border-color: $grey-70;
					border-image: none;
				}
			}

			&:last-of-type() {
				border-bottom: none;
			}
		}
		
	}

	.fake-input {
		font-family: "Basis Grotesque Pro";
    font-style: normal;
    letter-spacing: 0em;
    font-size: 16px;
    font-weight: 400;
    padding: 0 16px;

		display: flex;
    align-items: center;
    color: $grey-30;
    background-color: $grey-80;
    border: 2px solid $grey-80;
    border-radius: 5px;
    overflow:hidden;
    min-height: 58px;

		svg {
			stroke: $grey-30;
			margin-right: 4px;
		}
	}
	
	&.disabled {
		.select-bm {
			border-color: $grey-80;
			.crs{
				&__control{
					background: $grey-80;
				}
			}
		}
	}
}