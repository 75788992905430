@import "styleBM/abstracts.scss";

$padding : 24px;
$paddingTight : 16px;
$verticalSpacing: 24px;
$widths: (
  1040: 1040px,
  //...
);

@mixin full-width($padding) {
  width: calc(100% + 2 * #{$padding});
  margin-left: -$padding;
}

@mixin default-element-top-bottom-margins {
  &:not(.no-margins){
    margin-top: $verticalSpacing;
    margin-bottom: $verticalSpacing;
  }
}

.simple-card-wrapper {
  &:not(.tight){
    padding: $padding;
    .spacer,.separator,.dark-zone{
      @include full-width($padding);
    }
    .dark-zone{
      padding: 16px $padding 16px $padding;
      }
  }
  &.tight{
    padding: $paddingTight;
    .spacer,.separator,.dark-zone{
      @include full-width($paddingTight);
    }
    .dark-zone{
      padding: 16px $paddingTight 16px $paddingTight;
    }
  }

  border-radius: 8px;
  &:not(.has-grey-80-bg-prop){
    background: $grey-90;
    border:solid 1px $grey-80;
  }

  &.has-grey-80-bg-prop{
    background: $grey-80;
  }
  color: $white;
  width:100%;
  &.center-content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .separator {
    background: transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAABCAYAAADXeS5fAAAAFklEQVQImWM0Njb+z4AHPH/+HJ80AwC21wRPVEKf9wAAAABJRU5ErkJggg==") 0 0 repeat-x;
    height: 1px;
  }
  .spacer,.dark-zone{
    @include default-element-top-bottom-margins;
  }
  .dark-zone{
    background-color: $black;
  }
}

//loop
@each $w, $value in $widths {
  .simple-card-wrapper[class*="width-#{$w}"] {
    max-width: $value;
  }
}
