
.bold{
  font-weight: bold;
}

.js-center{
  justify-self: center;
}
.js-right{
  justify-self: right;
}
.as-center{
  align-self: center;
}
.as-right{
  align-self: right;
}
.flex{
  display: flex;
  &-col{
    display: flex;
    flex-direction: column;
  }
  &.alignCenter, .ac{
    align-items: center;
  }
  &.alignStart, .as{
    align-items:flex-start;
  }
  &.alignEnd, .ae{
    align-items:flex-end;
  }
  &.justifyCenter, .jc{
    justify-content:centerend;
  }
  &-sb{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.sb{
    justify-content: space-between;
  }
  &.aifs{
    align-items: flex-start;
  }
  &.equalColl{
    width: 100%;
    flex: 1;
    >div{
      flex: 1;
    }
  }
  &.equalColl-col{
    width: 100%;
    >div{
      flex: 1;
    }
  }
  &.column {
    flex-direction: column;
  }
}

.uppercase{
  text-transform: uppercase;
}

.w100{
  width: 100%;
}

.h100{
  height: 100%;
}

.fill-darkest{
  fill:  $text-light-01;
}
.no-wrap{
  white-space: nowrap;
}
.btn-stroke-text-light-01{
  stroke: $text-light-01;
  cursor: pointer;
  &:hover{
    stroke-width: 1.5;
  }
}
.ta-center{
  text-align: center;
}
