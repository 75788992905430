.date-input {
  .cdt-button{
    border-radius: 5px;
    background-color: $grey-80;
    border: 2px solid $grey-70;
    height: 58px;
    @include ptype;
    color:$grey-30;
    padding: 0;
    transition: none;
    &.dark{
      background-color: $black;

      > div {
        padding-left: 16px;
      }

      > svg {
        padding-right: 16px;
        margin: 0;
      }
    }
    &:hover {
      border-color: $grey-70;
    }
    
    &.open {
      border: 2px solid $accent-2;
      color:$white;
    }
    &.dark.open{
      border-color: transparent;
      background-image: linear-gradient($black, $black), 
                        $grad-good;
      background-origin: border-box;
      background-clip: content-box, border-box;
     
    }
    svg{
      fill: $white;
    }
  }

  &.disabled {
    .cdt-button{
      background: $grey-80;
      border-color: $grey-80;
    }
  }
}
.cdt-main{
  margin-top: 1px;
  background-color:$grey-75;
  border-color: $grey-80;
  border-radius: 8px;
  .line{
    background-color: rgba(255,255,255,.25);
  }

  .top-buttons{
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    flex-grow: 1;
    >div{
      width: auto;
      @include ptype;
      padding: 9px 18px;
      border-radius: 8px;
      width: auto;
      background-color: $grey-85;
      color:$white;
      font-size: 13px;
      margin-left: 1px;
      cursor: pointer;
      &:hover{
        background: $grad-btn;
        @include ptype;
        font-size: 13px;
        padding: 9px 18px;
      }
    }
  }
  .month-line{
    color: $white;
    @include ptype;
    >div{
      &:first-child,
      &:last-child{
        border-radius: 8px;
        background-color: $grey-85;
        color:$white;
        cursor: pointer;
        &:hover{
          background: $grad-btn;
        }
        svg{
          width: 24px;
          height: 24px;
          path{
            stroke:$white;
          }
        }
      }
    }
  }
  .type-in{
    input{
      border-radius: 8px;
      background-color: $grey-85;
      border:solid 1px $grey-85;
      color:$white;
      &:hover,
      &:focus{
        border-color: $accent-3;
      }
    }
  }
  .planningCalendar{
    background-color: $grey-85;
    border-top: 1px solid rgba(255,255,255,.15);
    > div{
      color: $white;
      + div{
        border-right: 1px solid rgba(255,255,255,.25);
        border-bottom: 1px solid rgba(255,255,255,.25);
      }
      &.disabled {
        background-color: $grey-75;
        color: rgba(255,255,255,.5);
      }
      &:nth-child(7n + 1) {
        background: $black;
        color: $white;
        border-left: 1px solid rgba(255,255,255,.25);
        border-bottom: 1px solid rgba(255,255,255,.25);
      }
      &:nth-child(7n) {
        background: $black;
        color: $white;
      }
      &.today{
        background: $grad-btn;
        color: $white !important;
        border-radius: 0;
      }
      &.selected{
        &--start,
        &--mid,
        &--end{
          background-color: $accent-3;
        }
      }
    }
  } 
  .filter-buttons{
      button{
        color:$white;
        border-radius: 8px;
        @include ptype;
        cursor: pointer;
        &:hover{
          background-color: $grey-85;
        }
        + button{
          background: $grad-good;
          &:hover{
            background: $grad-btn;
          }
        }
      }
    }
}