.bottom-section{
  display: flex;
  align-items: center;
  justify-content: center;
  padding:24px;
  min-height: 68px;
  &.paid{
    >div{
      margin: 0 auto;
      width: auto;
      display: inline-flex;
      svg{
        margin-right: 4px;
      }
    }
  }
  &.pending{
    padding:0;
    .btn-pending{
      width: 100%;
      min-height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      border:0;
      outline: none;
      border-radius: 0 0 8px 8px;
      //@include ptype-1("base");
      font-size: 14px;
      color:$white;
      background-color: $danger;
      cursor: pointer;
    }
  }
  &.buttons{
    padding:0 0 12px 0;
    .btn{ 
      background-color: transparent;
      > div{
        background-color: transparent;
        svg{
          width: 32px;
          height: 32px;
          path{
            stroke:$white;
          }
        }
      }
      ~ .btn{
        margin-left: 10px;
      }
    }
  }
  &.custom{
    flex-wrap: wrap;
    padding-bottom: $gutter;
    .btn{
      padding: 2px;
      margin: 0 $gutter $gutter;
      >div{
        min-height: 44px;
        min-width: 196px;
        .ico{
          height: 32px;
          svg{
            height: 32px;
            margin-left: -8px;
            path{
              stroke: $white;
            }
          }
        }
      }
    }
  }
}