@mixin fixed-width{
  width: 100%;
  @include min-lg{
    max-width: 560px;
  }
}  

.payment-order-summary{
  display: flex;
  flex-direction: column;
  background-color: $grey-85;
  padding: 40px $gutter*2 $gutter*2;
  border-radius: 8px;
  border: solid 1px #fff3;
  @include min-lg{
    position: fixed;
    top:80px;
    right:0;
    bottom:0;
    left:auto;
    z-index: 20;
    padding: 40px $gutter*2 170px;
    border-radius: 0;
    border-color: transparent;
    border-left: solid 1px #fff2;
  }
  @include fixed-width;
  .title{
    margin:0 0 $gutter*2;
    text-transform: uppercase;
    @include etype(3);
  }
  .content-wrap{
    @include fixed-width;
    height: auto;
    @include min-lg{
      height: calc(100% - 60px + #{$gutter*2} + 170px) ;
    }
    background: transparent;
    &::-webkit-scrollbar {
      width:0px;
      border-radius:1px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: none;
      background-color: transparent;
      border-radius:1px;
    } 
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      outline: none;
      border-radius:1px;
      cursor: drag;
    }
    overflow-x: hidden;
    overflow-y: auto;
  }

  .toggle-wrapper{
    background: $black;
    border-radius: 8px;
    padding:16px $gutter*2;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    .head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .heading{
        margin: 0;
        @include etype(3);
      }
      .expander{
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: 0;
        outline: none;
        padding: 0;
        margin-left: 16px;
        cursor: pointer;
        svg{
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          transform: translateY(4px);
          path{
            stroke:$white;
          }
        }
      }
    }
    .toggle-body{
      height: 0;
      overflow: hidden;
      &.open{
        height: auto;
        margin-top: 16px;
      }
      .btn{
        &.btn{
          &-secondary{
            display: flex;
            width: 100%;
            margin:0 0 10px;
            .right{
              padding-right: 10px;
              width: 100%;
              justify-content: space-between;
            }
            svg{
              width: 30px;
              height: 30px;
              path{
                stroke: $white;
              }
            }
          }
        }
      }
      .name-wrapper{
        position: relative;
        &.hide-label{
          .magic-input{
            .label{
              display: none;
            }
          }
        }
        &.not-disabled{
          .magic-input{
            .inputContainer{
              &.disabled{
                background: $grey-85; 
              }
              svg{
                width: 30px;
                height: 30px;
                path{
                  stroke: $white;
                }
              }
            }
          }
        }
        .btn.btn-plain{
          position: absolute;
          z-index: 1;
          top:0;
          right:0;
          margin: 1px;
          background-color: transparent;
          >div{
            background-color: transparent;
            min-width:46px;
            &.left{
              padding: 0;
            }
            .label{
              display: none;
            }
            svg{
              width: 30px;
              height: 30px;
              path{
                stroke:$white;
              }
            }
          }
        }  
      }
    }
    .counter-wrapper{
      display: flex;
      flex-wrap: wrap;
      justify-content:space-between;
      align-items: center;
      background: $grey-85;
      margin-bottom:10px;
      border-radius: 8px;
      padding: 16px $gutter*2;
      border:0;
      box-shadow: 0 0 0 1px #fff2 inset;
      overflow: hidden;
      position: relative;
      &::before,
      &::after{
        content:"";
        position:absolute;
        z-index: 1;
        background:$black;
        width: 10px;
        height: 10px;
        display: flex;
        box-shadow: 0 0 0 1px #fff4 inset;
        border-radius: 50%;
        top:50%;
      }
      &::before{
        left:0;
        transform: translate(-4px,-4px);
      }
      &::after{
        right:0;
        transform: translate(4px,-4px);
      }
      &:last-child{
        margin-bottom: 8px;
      } 
    }
    .left-side{
      display: flex;
      flex-direction: column;
      >.title{
        text-transform: uppercase;
        @include etype(2);
        margin: 0 0 9px;
      }
      .prices{
        align-items: flex-start;
        min-height: auto;
        .line{
          &-1{
            font-family:$Grotesque;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;

          }
        }
        .ticket-price{
          .amount{
            @include etype(3);
          }
          .currency{
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
          }
        }
      }
      .btn-ticket{
        width: auto;
        height: 44px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 22px;
        background: $danger;
        color: $white;
        overflow: hidden;
        border:0;
        outline: none;
        padding: 12px;
        font-family:$Grotesque;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        margin-top: 9px;
        margin-right: auto;
        svg{
          margin-right: 8px;
        }
        &.sold{
          text-transform: uppercase;
          background: #454961;
        }
      }
    }
    &.total{
      .toggle-body.open{
        margin: 0;
      }
    }
    &.voucher{
      
      .toggle-body{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .magic-input{
          + .btn.btn-secondary{
            margin-top: $gutter*2;
            margin-right: auto;
            display: inline-flex;
            min-width: auto;
            width: auto;
            &.disabled{
              pointer-events: none;
              background: #4D4D4D;
              opacity: 1;
              >div{
                background: #4D4D4D;
                color:#808080;
              }
            }
          }
        }
      }
      
    }
    &.valid-voucher{
      background: $grad-good;
      .inputContainer {
        background: $black;
      }
    }
  }

  .fixed-button{
    height: auto;
    min-height: 170px;
    background-color: $black;
    padding: $gutter*2;
    position: fixed;
    z-index: 21;
    right:0;
    bottom:0;
    left:96px;
    @include fixed-width;
    width: calc(100% - 96px);
    display: flex;
    flex-direction: column;
    @include min-lg{
      width: 100%;
      left:unset;
      border-left: solid 1px #fff3;
    }
    .btn{
      height: auto;
      margin-bottom: 16px;
      &.btn{
        &-active{
          display: flex;
          width: 100%;
          position: relative;
          > div{
            width: 100%;
            min-height: 64px;
            padding-left: 44px;
            padding-right: 44px;
            svg{
              position: absolute;
              top:14px;
              right:15px;
              width: 36px;
              height: 36px;
              path{
                stroke: $white;
              }
            }
            .label{
              @include etype(3);
            }
          }
        }
      }
      ~ .btn{
        margin-left: 0;
      }
    }
    .content{
      display: flex;
      align-items: center;
      justify-content: center;
      >.inner{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg{
        margin-right: 10px;
      }
      span{
        font-family: $Grotesque;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        display: flex;
        + button{
          display: inline-flex;
          background: transparent;
          border:0;
          outline: none;
          font-family: $Grotesque;
          font-size: 14px;
          font-weight: 400;
          color: $white;
        }
      }
    }
  }
}