&.invoice-detail-page{
  .card{
    &.count-down{
      padding:0 0 $gutter * 2;
      margin-bottom: $gutter * 2;
    }
    &.invoice-detail{
      display: flex;
      margin-bottom: $gutter * 2;
      .heading-3{
        @include etype(2);
        margin-bottom:16px;
      }
    }
  }
  .ticket.reserved{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 8px;
    min-height: 80px;
    align-items: center;
    padding:$gutter * 2;
    background-color: $black;
    .pill{
      margin-left: 0;
      margin-top: 4px;
      text-transform: capitalize;
    }
    .amount{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      padding-left:$gutter;
      text-align: center;
    }
  }
  .invoice-detail{
    .detail{
      display: flex;
      flex-direction: column;
    }
    .buttons{
      margin-top: 8px;
    }
  }
  .bank-transfer{
    display: flex;
    .transfer-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $gutter * 2;
      .heading{
        &-3{
          @include etype(3);   
        }
      }
      .info{
        .info-btn{
          svg{
            path{
              fill:$white;
            }
          }
        }
        &.opened{
          .tooltip{
            right: 30px;
          }
        }
      }
    }
    .heading{
      &-5{
        @include etype(2);
        margin-bottom: 8px;
      }
    }
    .content{
      margin: 0;
      @include ptype(0,"base");
      color:#fff8;
    }
  }
}