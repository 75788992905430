&.certificate{
  padding: 0;
  width: 100%;
  max-width: 100%;
  margin:0 12px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include min-sm{
    max-width: 492px;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include min-sm{
      min-height: 700px;
    }
    .top{
      padding: $gutter*2 $gutter*2 0;
      .img{
        width: 100%;
        height: 226px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: $gutter*2-4;
        img{
          object-fit: cover;
        }
      }
      .heading{
        &-3{
          @include etype(1);
          color:$grey-55;
          margin: 0 0 $gutter/3;
          text-transform: uppercase;
        }
        &-2{
          @include etype(3);
          color:$white;
          margin:0 0 $gutter;
          text-transform: uppercase;
        }
      }
      .date{
        font-family: $Grotesque;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color:$grey-55;
        margin:0 0 $gutter;
      }
    }
    .middle{
      padding: $gutter $gutter*2 $gutter*2-4;
      background-color: $black;
      margin-top: $gutter;
      .content{
        font-family: $Grotesque;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        strong{
          font-weight: 700 ;
        }



      }
      img{
        max-width: 198px;
      }
    }
    .bottom{
      padding: $gutter*2;
      .btn > div.left .ico svg{
        height: 26px;
        margin-left: -5px;
        path{
          stroke:$white;
        }
        
      }
    }
  }
}