.ticket-alert{
	width: auto;
	height: 48px;
	padding: 16px 24px 16px 16px;
	border-radius: 24px ;
	font-family: $Grotesque;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	&.bg{
		&-grey{
			background: #393939;
		}
	}

	svg{
		margin-right: 8px;
	}
}