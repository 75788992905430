.card{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  background: #1A1A1A;
  border-radius: 8px;
  padding: 24px;
  border:solid 1px rgba(255,255,255,.15);
  color: $white;
  width:100%;
  &.lighter{
    background: $grey-90;
    border:solid 1px $grey-80;
  }
  &.primary{
    background: $grad-btn;
  }
  &.ticket{
    padding:16px 16px 0;
  }
  .heading{
    &-6{
      color:$white;
      @include ptype-1("base-strong","normal");
      margin-top: -3px;
    }
    &-2{
      color:$white;
      @include etype(5);
    }
  }
  @import "certificate";
}