.venue{
  .heading{
    &-2{
      @include etype(5);
      margin-bottom:$gutter + 5;
    }
  }
  .venue-card{
    flex-direction: column;
  }
  .holder{
    display: flex;
    flex-direction: column;
    position: relative;

    .image{
      &-large{
        width: 100%;
        display: flex;
        background-color: $black;
        border-radius:8px;
        overflow:hidden;
        position: relative;
        margin-bottom: 170px;
        flex-wrap: wrap;
        &::before{
          content: "";
          width: 100%;
          height: 100%;
          display: flex;
          position: absolute;
          z-index: 1;
          top:0;
          right:0;
          bottom:0;
          left:0;
          background: linear-gradient(90deg, #0007 0%, #0000 100%);
        }
        img{
          width:100%;
          height: 636px;
          object-fit: cover;
        }
      }
      &-small{
        width: 100%;
        max-width: 590px;
        height: auto;
        min-height: 170px;
        border-radius:12px;
        overflow:hidden;
        box-shadow: 0 4px 80px rgba(0,0,0,.6);
        >img{
          width: 100%;
          object-fit: cover;
        }
      }
      &-small-wrap{
        width: 100%;
        position: absolute;
        z-index: 2;
        bottom: $gutter * 2;
        left: $gutter * 2;
        display: flex;
        align-items: flex-end;
        .location{
          width: auto;
          height: auto;
          min-height: 145px;
          display: flex;  
          flex-direction: column;
          justify-content: center;
          padding-left: 36px;
          .heading{
            &-3{
              @include etype(9)
            }
            &-5{
              font-family: $Grotesque;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              letter-spacing: -0.02em;
              text-align: left;
              color:$grey-45;
            }
          }
        }
      }
    }
  }
}