&.personal-quote{
  @extend .pt13;
  @extend .px12;
  @extend .pb4;
  
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 320px;
  position: relative;
  justify-content: space-between;
  border:0;
  // &::before,
  // &::after{
  //   content:"";
  //   position: absolute;
  //   z-index: 0;
  //   top:0;
  //   right:0;
  //   bottom:0;
  //   left:0;
  //   width: 100%;
  //   height: 100%;
  // }
  // &::before{
  //   background: linear-gradient(205deg, #000000 0%, rgba(0,0,0,0) 65%);
  // }
  // &::after{
  //   background: linear-gradient(1deg, rgba(0,0,0,.95) 15%, rgba(0,0,0,0) 95%);
  // }
  .wrapper{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  @import "edit-head";
  .content{
    display:flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding-bottom: 65px;
    .inner{
      width: 100%;
      max-width: 460px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p{
        margin: 0 0 8px;
        @include etype(5);
      }
      .author{
        display: flex;
        align-items: flex-start;
        @include ptype(0,"base");
        color:$white;
      }
      .socials{
        margin-top: 30px;
        display: flex;
        .social{
          background: $black;
          color: $white;
          width: auto;
          height: 40px;
          padding: 0 16px;
          border-radius: 20px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            margin-right: 8px;
            transform: translateY(-1px);
          }
          + .social{
            margin-left:8px;
            svg{
              transform: translateY(-2px);
            }
          }
        }
      }
    }
  }
}