&.invitations-page {
  margin-bottom: 40px;

  // .bread-crumb + .header-section {
  //   padding-top: 0;
  // }

  .header-section {
    margin-bottom: 1.5rem;
    padding: 0px 24px 12px 0px;
  }

  .table-wrapper-down{
    .header-section{
      padding:0px 24px 12px 0px;
    }
  }
  .left-card-wrapper {
    display: flex;
    margin-bottom: 2.5rem;
    min-height: 506px;

    .card.mind-tools {
      height: 100%;
    }
    .invite-right-cards-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .right-cards-item-wrapper {
      .share-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 14.5px 0px 14.5px 24px;
        background: #000000;
        height: 48px;
        border-radius: 8px;
        margin-bottom: 1.5rem;

        text {
          margin-right: auto;
        }
      }

      .copy-button {
        padding: 14px 24px;
        background: $grad-btn;
        border-radius: 8px;
        @include ptype(0,"base");
        font-weight: bold;
        color: white;
      }

      .black-button {
        padding: 9px 16px;
        background: black;
        border-radius: 8px;
        margin-right: 1rem;
      }
      .share-card-title {
        margin-bottom: 33px;
        @include etype(5);

        &.email {
          margin-bottom: 1.5rem;
        }
      }
      .share-card-top-title {
        @include ptype(0,"base");
      }
    }
  }
  .table-wrapper > div:not(.multy-select-modal) > div.cell {
    &-1 {
      .table-default-cell .avatar {
        border: solid 1px $accent-3;
      }
    }
    &-4 {
      .table-default-cell {
        background: black;
        min-width: 362px;
        align-items: center;
        justify-content: space-between;
        > div {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          .title {
            font-weight: normal;
          }
          .tag-keeper {
            margin-right: 1rem;
            background: $black;
            .tag-wrapper {
              justify-content: center;
              align-items: center;
              width: 34px;
              height: 34px;
              border-radius: 50%;
              &.inactive {
                border: solid 1px $grey-60;
              }
            }
          }
        }
      }
    }
    &-5 {
      .pill {
        height: 43px;
        width: 160px;
        border-radius: 21px;
        padding: 0;

        .pill-content {
          height: 43px;
          width: 100%;
          border: 0;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .mind-tools {
    min-width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .card-head {
      &:empty {
        + .wrapper {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }
    }
    .mind-tool {
      .brand-tool-wrapper {
        .brand-tool--item .text{
          line-height: 1;
        }
        display: flex;
        &:last-child {
          .brand-tool--item {
            padding-bottom: 20px;
            &::after {
              content: none;
            }
          }
        }
      }
      .bm-button-wrapper {
        display: flex;
        width: 185px;
        height: 40px;
        border-radius: 120px;
        justify-content: center;
        align-items: center;
        background: $accent-4-80;

        .bm-button-text {
          color: black;
          @include ptype(0,"base");
          font-weight: bold;
        }

        &.black {
          background: black;
          border: 1px solid #ffffff;

          .bm-button-text {
            color: #ffffff;
            @include ptype(0,"base");
            font-weight: bold;
          }
        }
        .point-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;

          &.black > Svg Path {
            stroke: white;
          }
        }
      }
      .heading {
        &-6 {
          @include ptype(0,"base");
          font-weight: 700;
        }
        &-2 {
          @include etype(5);
          margin-bottom: 30px;
        }
      }
    }
  }
}
