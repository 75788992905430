.ticket-flow{
  display: flex;
  align-items: center;
  justify-content: center;
  .item{
    @include ptype(0,"base");
    line-height: 16px;
    color:#666666;

    ~ .item{
      padding-left: 17px;
    }

    > svg{
      width: 16px;
      height: 16px;
      transform: translateY(2px);
      margin-right: 7px;
      path{
        fill:#666666;
      }
    }
    &:not(:last-child)::after{
      content: ' > ';
    }

    &.active{
      color:$accent-3;
      >svg{
        path{
          fill:$accent-3;
        }
      }
    }
  }
}