&.single-event-page{
  .hero-section{
    margin-top: - $gutter * 3;
  }
  .table-page.my-orders{
    margin-bottom: 40px;
    .header-section{
      padding:0;
    }
    .table-wrapper > div:not(.multy-select-modal) > div.cell{
      &-0{
        padding:7px 0;
        .table-default-cell{
          background-color: #353A3E;
          width: 82px;
          height: 74px;
          flex-direction: column;
          margin: auto;
          padding-left: 5px;
          padding-right: 5px;
          padding-bottom: 8px;
          border-radius: 4px;
          text-align: center;
          @include ptype-2("base-strong");
          .stroke-white{
            svg{
              path{
                stroke: $white;
              }
            }
          }
        }
      }
      &-1{
        .pill{
          background: transparent;
        }
      }
      &-4{
        .table-default-cell .avatar{
          border:solid 1px $accent-3;
        }
      }
      &-5{
        .tag-wrapper{
          &.tag-bg-magenta{
            background: $grad-btn;
            padding-right:24px;
            padding-left:24px;
            @include ptype-1("regular");
            min-height: 40px;
            border-radius: 8px;
          }
        }
      }
      &-6{
        .table-default-cell .tag-keeper{
          background: $grad-warn;
          height: 40px;
          .tag-wrapper{
            height: 38px;
          }
        }
      }
      &-7{
        padding: 0;
      }
    }
  }
}