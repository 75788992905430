&.locked{
  display: flex;
  align-items: center;
  justify-content: center;
  .centered{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-10px);
    .pay{
      &-unlock{
        margin-top: 13px;
        @include etype(2);
        color:$white;
      }
      &-price{
        display: flex;
      }
      &-amount{
        @include etype(7);
        color:$white;
        text-align: right;
      }
      &-currency{
        @include ptype-1('base-strong');
        color:$white;
        margin-top:5px;
        margin-left: 5px;
      }
    }
  }
}