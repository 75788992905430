&.personal-experience{
  padding:20px $gutter*2 $gutter*2;
  @import "edit-head";
  .exp-out{
    position: relative;
    @include min-sm{
      &:after{
        content:"";
        position: absolute;
        top:0;
        right:0;
        bottom:24px;
        width: 150px;
        height: calc(100% - 24px);
        background: linear-gradient(to right,  #1A1A1A00 0%,#1A1A1A 100%);
      }
    }
  }
  .exp-list{
    display: flex;
    @include horizontal-scrollbar;
    position: relative;
    .inner{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .exp-item{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: $gutter*2;
      background: $grey-75;
      border-radius: 8px;
      overflow:hidden;
      width: 338px;
      padding:$gutter*2;
      &:not(:last-child){
        margin-right: $gutter*2;
      }
      &:last-child{
        margin-right: 150px;
      }
      >.top,.bottom{
        display:flex;
        flex-direction: column;
      }
      .title{
        @include ptype(0,"strong");
        margin-bottom:0;
      }
      .company{
        font-family: $Grotesque;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        color:$grey-55;
        margin-bottom: $gutter*2;
      }
      .company-dates{
        display: flex;
        margin-bottom: 30px;
        align-items: center;
        .circle{
          width: 40px;
          height: 40px;
          display: flex;
          border-radius: 50%;
          overflow: hidden;
          background-color: $accent-1-40;
          margin-right: $gutter;
        }
        .company-date{
          display: flex;
          flex-direction: column;
          .company{
            @include ptype(0,"strong");
            line-height: 1;
            color: $white;
            margin: 0;
          }
          .date{
            font-family:$Grotesque;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            color:$grey-55;
          }
        }
      }
      
    }
  }
}


.experiences-buttons{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .claps{
    width: auto;
    min-height: 36px;
    border-radius: 18px;
    margin-right: 10px;
    padding: 0 16px 3px;
    background: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    @include ptype(0,"base");
    strong{
      font-weight: 700;
    }
    svg{
      margin-right: 9px;
    }
  }
  .btn-edit{
    width: auto;
    min-height: 36px;
    border-radius: 18px;
    margin-right: 10px;
    padding: 0 16px 3px;
    background: $grey-65;
    color:$white;
    display: flex;
    align-items: center;
    justify-content: center;
    @include ptype(0,"base");
    border:0;
    outline:none;
    cursor: pointer;
    transition: all 0.25s ease-out;
    &:hover{
      background: $grad-good;
    }
  }
}