.counter {
  display: flex;
  @include etype(6);
  align-items: center;
  margin-bottom: $gutter * 2;
  .count-cell {
    width: 80px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $grad-btn;
    color: $white;
    border-radius: 8px;
    overflow: hidden;
  }
  .sep {
    width: 32px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey-75;
  }
  &.expired {
    .count-cell {
      background: $grey-60;
    }
  }
}
