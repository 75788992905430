
.redux-toastr{
  z-index: 1;
  .top-left, 
  .top-right, 
  .top-center, 
  .bottom-left, 
  .bottom-right, 
  .bottom-center {
    padding: 0;
		display: flex;
		flex-direction: column;
    gap: 4px;
		// pointer-events: none;
		> div > div{
			// pointer-events:all;
		}
    @include max-sm {
      max-width:calc(100% - 20px);
    }
  }
  .bottom-left{
    left:116px;
    bottom:10px;
    @include max-sm {
      left:10px;
      bottom:5px;
    }
  }

  .toastr{
    min-height:32px;
    max-height: 32px;
    padding: 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
		>div{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
    &.rrt{
      &-success {
        background-color:$all-good;
        border: 1px solid $all-good;
        border-radius: 360px;
      }
      &-warning {
        background-color: $danger;
        border: 1px solid $danger;
        border-radius: 360px;
      }
      &-error {
        background-color: $alert;
        border: 1px solid $alert;
        border-radius: 360px;
      }
    }

    .rrt{
      &-left-container{
        display: none;
      }
      &-middle-container{
        display: flex;
        flex-direction: column;
        padding:0 8px 0 5px;
        width: auto;
        margin:0;
        min-height: 32px;
        box-sizing: border-box;
        align-items:flex-start;
        justify-content:center;
        .rrt-title{
          display: flex;
          font-family: 'Open Sans';
					font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color:$white;
        }
        .rrt-text{
          display: flex;
          margin: 0;
          font-family: 'Open Sans';
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color:$white;
        }
        .rrt-date{
          display: flex;
          margin-top: 8px;
          font-family: 'Open Sans';
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: 12px;
          letter-spacing: -0.02em;
          text-align: left;
          color:#B3B3B3;
        }
      }
      &-right-container{
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 32px;
        .close-toastr{
          width: 24px;
          height: 24px;
          background-color:transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 14px;
          opacity: 1;
          color:$white;

          >span{
						background-color: transparent;
            display: flex;
            width:24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 24px;
            color:$white;
					}
        }
      }
    }

    &.fadeIn {
      animation-name: fadeIn;
      animation-duration: .7s;
    }
  
    &.fadeOut {
      animation-name: fadeOut;
      animation-duration: .3s;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}