&.example-page{
  .header-section{
    padding-top:35px;
    .btn{
      &.btn-grey{
        color:$white;
        @include etype(3);
        text-transform:initial;
        min-height: 60px;
        margin-bottom: 45px;
        svg{
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  .section-title{
    @include etype(5);
    color: $white;
    margin:0;
    padding:20px 0;
  }
}
