&.card-stat{
  padding:16px;
  .wrapper{
    flex-direction: row;
    padding: 0;
  }
  @include min-lg{
    justify-content: center;
  }
  .wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .stat{
    &-ico{
      position: relative;
      margin-right: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 76px;
      height: 88px;
    }
    &-value{
      position: absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include etype(4);
    }
    &-content{
      p{
        margin: 0;
        font-family: $Grotesque;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color:$grey-55;
      }
      .text{
        @include etype(2);
        color:$white;
      }
    }
  }
}