.row-line{
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - #{$gutter} * 2);
  margin-left: #{$gutter};
  margin-right: #{$gutter};
  &.double-padding{
    width: calc(100% - #{$gutter} * 4);
    margin-left: calc( #{$gutter} * 2);
    margin-right: calc( #{$gutter} * 2);
  }
  .card{
    width: calc(33.3333% - #{$gutter} * 2);
    margin-left: #{$gutter};
    margin-right: #{$gutter};
    margin-bottom:calc( #{$gutter} * 2);
    min-height: 220px;
    @include max-sm{
      width: calc(100% - #{$gutter} * 2);
      .wrapper{
        padding: 0;
      }
    }
    &.intro{
      width: calc(66.6667% - #{$gutter} * 2);
      @include max-md{
        width: calc(58.3333% - #{$gutter} * 2);
      }
      @include max-sm{
        width: calc(100% - #{$gutter} * 2);
      }
    }
    &.user{
      @include max-md{
        width: calc(41.6667% - #{$gutter} * 2);
      }
      @include max-sm{
        width: calc(100% - #{$gutter} * 2);
      }
    }
    &.fullwidth{
      width: calc(100% - #{$gutter} * 2);
    }

  }

  @import "filter";
}