.invoice-line{
  &.card{
    background-color: $black;
    margin-bottom: 8px;
    position: relative;
    .heading{
      &-4{
        @include ptype-1("base-strong");
        margin-bottom: 4px;
      }
    }
    &.with-payment{
      padding-right:90px;
      .visa-detail{
        display: flex;
        align-items: center;
        margin-top: 4px;
        @include ptype(0,"base");
        .visa{
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .content{
      &.plain{
        @include ptype-1("base-strong");
        font-weight: 400;
        color:$grey-45;
        strong{
          color: $white;
        }
      }
      .overdue{
        color: $alert;
        margin-left: 5px;
      }
    }
    .contactless{
      position: absolute;
      z-index:1;
      top:50%;
      right:$gutter * 2;
      transform: translateY(-50%);
    }
    .icon-content{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      svg{
        margin-right:8px;
        path{
          stroke: $white;
        }
      }
      .content{
        &.plain{
          @include ptype-1("base-strong");
          font-weight: 400;
          color: $white;
        }
      }
    }
  }
}