&.personal-book{
  padding:20px $gutter*2 $gutter*2;
  user-select: none;
  @import "edit-head";
  .outer{
    position: relative;
    @include min-sm{
      &:after{
        content:"";
        position: absolute;
        top:0;
        right:0;
        bottom:24px;
        width: 100px;
        height: calc(100% - 24px);
        background: linear-gradient(to right,  #1A1A1A00 0%,#1A1A1A 100%);
        pointer-events: none;
      }
    }
  }
  .content{
    display: flex;
    @include horizontal-scrollbar;
    position: relative;
    .inner{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}