.card-type{
  width: 100%;
  background: $grey-85;
  border: 1px solid $grey-75;
  box-sizing: border-box;
  border-radius: 8px;
  padding:24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  color:$white;

  &.primary{
    background: $grad-btn;
  }
  &.secondary{
    background: $grad-good;
  }
  &.no-padding{
    padding: 0;
  }
}