.prices{
  min-width: 195px;
  min-height: 70px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  &.yellow{
    .line{
      &-1{
        color:$accent-4;
        svg{
          path{
            fill:$accent-4;
          }
        }
      }
    }
  }
  .line-1{
    display: flex;
    margin-top: 5px;
    @include ptype-2("base");
    color: #fff6;
    span{
      display: inline-flex;
      margin-right: 4px;
    }
    &.yellow{
      color: #FFC100;
    }
    
    .price-cut {
      text-decoration: line-through;
      margin: 0px 0px 0px 4px;
    }
  }
  .info{
    margin-left: 8px;
  }
  .ticket-price{
    display: flex;
    .amount{
      @include etype(7);
    }
    .currency{
      @include ptype-1("base-strong");
      margin: 5px 0 0 5px;
    }
  }
}