.bread-crumb{
  display: flex;
  // padding:0 24px;
  ul{
    list-style: none;
    outline: none;
    padding:0;
    margin: 0;
    display: flex;
    justify-content: center;
    li{
      display: inline-flex;
      align-items: center;
      a{
        color:$accent-3;
      }
      &:last-child{
        a{
          color:$white;
          pointer-events: none;
        }
      }
    }
    a{
      @include ptype;
      text-transform: capitalize;
    }
  }
}