&.frameworks-browse-page{
  .header-section{
    padding-bottom: $gutter;
  }
  .bg-white.filter-row{
    padding: 0;
    &.filter-active{
      padding:8px 0 30px;
    }
  }
  .container{
    &.left-align{
      margin-left: $gutter;
    }
  }
  @import "browse-card";
}