.profile-form-wrapper{
  .heading-2{
    @include etype(3);
    margin: 0 0 $gutter*2;
  }
  .col-12{
    margin-bottom: 16px;
  }
  &.edit{
    .inputContainer{
      background: $black;
    }
  }
}