.section-brand-slider{
	position: relative;
}

.slick-slider{
	border-radius: 40px;
	overflow: hidden;

	&.brand-slider{
		background: $black;
		.slick-slide{
			.slide-image{
				position: absolute;
				top:0;
				right:0;
				left:0;
				bottom:0;
				object-fit: cover;
				z-index: -1;
				display: flex;
				width: 100%;
				height: 100%;
				align-items: center;
				justify-content: center;
				transform: scale(1);
			}
			.slide{	
				&-item{
					position: relative;
					width: 100%;
					display: flex;
				}
				&-inner{
					background-size:100%;
					background-position: center;
					background-repeat: no-repeat;
					transition: all 0s ease-out;
					min-height: 768px;
					position: relative;
					color:$white;
					padding: 40px;
					width: 100%;
					opacity:0;
					&::before,
					&::after{
						content:"";
						position:absolute;
						left:0;
						right:0;
						z-index: 1;
					}
					&::before{
						top:0;
						background: linear-gradient(189deg, #171B3A 6%, rgba(23,27,58,0) 55%);
						height: 380px;
					}
					&::after{
						bottom:0;
						background: linear-gradient(10.75deg, rgba(0,0,0,.7) 45%, rgba(0,0,0,0) 94%);
						height: 100%;
					}
					.slider{
						&-top,
						&-middle,
						&-bottom{
							display: flex;
							width: 100%;
							position: relative;
							z-index: 2;
						}
						&-top{
							justify-content: space-between;
							.top{
								&-left{
									@include etype(2);
									line-height: 40px;
									margin-bottom: 8px;
									img{
										max-width: 160px;
									}
								}
								&-right{
									display: flex;
									flex-direction: column;
									align-items: flex-end;
								}
							}
							@import "connection";
							@import "ticket-alert";
						}
						&-middle{
							flex-direction: column;
							justify-content: center;
							flex-grow: 1;
							.content-wrap{
								display: flex;
								flex-direction: column;
								max-width: 70vw;
								.action-buttons{
									max-width: 460px;
									.bm-btn+.bm-btn{
										margin-top: 16px;
									}								}
							}
						}
						&-bottom{
							flex-direction: column;
						}
					}
					&.center{
						.slider-middle{
							align-items: center;
							.bm-btn{
								margin-left: auto;
							}
							.content-wrap{
								justify-content: center;
								align-items: center;
								.main-title{
									text-align: center;
								}
							}
						}
					}
					&.left{
						.slider-middle{
							align-items:flex-start;
						}
					}
					.content-wrap{
						margin-bottom: 48px;
						.subtitle{
							&-1{
								font-family: $Grotesque;
								font-size: 24px;
								font-style: normal;
								font-weight: 400;
								line-height: 26px;
								letter-spacing: -0.02em;
								text-transform: uppercase;
								margin: 0 0 8px;
								display: flex;
								opacity: 0;
							}
							&-2{
								display: flex;
								font-family: $Grotesque;
								font-size: 20px;
								font-style: normal;
								font-weight: 400;
								line-height: 24px;
								letter-spacing: 0em;
								margin: 0 0 40px;
								transform: translateY(-35px);
								opacity: 0;
							}
						}
						.main-title{
							@include etype(8);
							margin: 0 0 8px;
							transform: translateY(-35px);
							opacity: 0;
						}
						.bm-btn{
							width: 100%;
							max-width: 460px;
							margin: 0 0 inherit;
							margin-right: auto;
							transform: translateY(-35px);
							opacity: 0;
						}
					}
				}	
			}
		
			&.slick-active{
				.slide-image{
					animation-name:scaling;
					animation-duration:6s;
					animation-fill-mode: forwards;
				}
				.slide{
					&-inner{
						animation-name:fading;
						animation-duration:6s;
						animation-fill-mode: forwards;
						display: flex;
						flex-direction: column;
						transform-origin: center;
						&.center-bottom{
							justify-content: flex-end;
						}
						.content-wrap{
							position: relative;
							z-index: 2;
							.subtitle{
								&-1{
									animation-name:just-fade;
									animation-delay: 1s;
									animation-timing-function: ease-out;
									animation-fill-mode: forwards;
									animation-duration: 0.5s;
								}
								&-2{
									animation-name:slide-down ;
									animation-delay: 1.4s;
									animation-timing-function: ease-out;
									animation-fill-mode: forwards;
									animation-duration: 0.5s;
								}
								
							}
							.main-title{
								animation-name:slide-down;
								animation-delay: 1.2s;
								animation-timing-function: ease-out;
								animation-fill-mode: forwards;
								animation-duration: 0.5s;
							}
							.bm-btn{
								animation-name:slide-down;
								animation-delay: 1.6s;
								animation-timing-function: ease-out;
								animation-fill-mode: forwards;
								animation-duration: 0.5s;

								+ .bm-btn{
									animation-delay: 1.7s;
								}
							}
						}
					}
				}
			}

			.bar{
				&-wrap{
					width: 100%;
					height: 4px;
					display: flex;
					margin: 0 0 12px;
					background: $black;
					align-items: flex-start;
					justify-content: flex-start;
					&.primary{
						>.bar{
							background: $grad-btn;
						}
					}
					>.bar{
						background: $grad-good;
						height: 4px;
						display: flex;
					}
				}
			}

		} 
	}
	
	&.brand-slider-nav{
		position: absolute;
		z-index: 1;
		left:40px;
		bottom:110px;
		.slick-track{
			width: 100% !important;
			transform: translate3d(0,0,0) !important;
			display: flex;
			.slick-slide{
				display: inline-flex;
				width: 64px;
				height: 64px;
				margin-right: 8px;
				&.slick-cloned{
					display: none;
				}
				&.slick-active{
					.timer-item{
						svg{
							circle{
								stroke-dashoffset: 0;
								transition-duration: 6s;
							}
						}
					}
				}
			}
		}
		.slider-dotnav{
			position: relative;
			width: 64px;
			height: 64px;
			border-radius: 50%;
			overflow: hidden;
			>img{
				width: 64px;
				height: 64px;
				object-fit: cover;
			}
			.timer-item{
				position: absolute;
				z-index: 10;
				display: flex;
				width: 64px;
				height: 64px;
				top:0;
				left:0;
				transform: rotate(-90deg);
				svg{
					display: flex;
					circle{
						stroke-dashoffset: 188;
						stroke-dasharray: 188;
						stroke-opacity: 1;
						stroke:#F537B9;
					}
				}

			}
		}
	}
}

@keyframes scaling {
	0%{
		transform: scale(1.2);
		opacity:0;
	}
	5%,92%{
		opacity:1;
	}
	100%{
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes fading {
	0%,5%{
		opacity: 0;
	}
	25%,100%{
		opacity:1;
	}
}

@keyframes slide-down {
	0%{
		transform: translateY(-35px);
		opacity: 0;
	}
	100%{
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes just-fade {
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}