&.user{
  &-profile{
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%; 
    .wrapper{
      display: flex;
      flex-direction: row;
      
      flex-grow: 1;
      padding: 0;
      @include max-sm{
        flex-wrap: wrap;
        flex-direction: column;
      }
    }
  }
  &-points{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    .wrapper{
      display: flex;
      flex-direction: column;
      flex-grow:1;
      @include min-lg{
        flex-direction: row;
        align-items: center;
      }
      @include min-xl{
        flex-direction: column;
      }
      .points{
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        .pill{
          height: 64px;
          border-radius: 32px;
          min-width: 174px;
          margin: 0;
          @include min-lg{
            min-width: 64px;
          }
          @include min-xl{
            min-width: 174px;
          }
          svg{
            @include min-lg{
              display: none
            }
            @include min-xl{
              display: flex
            }
          }
          span{
            @include etype(4);
            padding-bottom: 3px;
            margin-left: 8px;
            @include min-lg{
              margin-left: 0;
            }
            @include min-xl{
              margin-left: 8px;
            }
          }
        }
      }
      .content{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        @include min-sm{
          margin-top: 0;
        }
        @include min-lg{
          padding-left: 20px;
        }
        @include min-xl{
          padding-left: 0;
        }
        .message{
          @include etype(2);
        }
        .date{
          font-family:$Grotesque;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color:$grey-55;
        }
      }
    }
  }
  &-badge{
    min-height: 320px;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    @include min-lg{
      min-height: auto;
      height: 160px;
    }
    @include min-xl{
      height: auto;
    }
    .wrapper{
      @include min-lg{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 88px;
        .badge--user{
          transform: scale(0.65) translateX(-35px);
          transform-origin: 0 center;
        }
      }
      @include min-xl{
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        .badge--user{
          transform: scale(1) translateX(0);
        }
      }
    }
  }
}