.header-section{
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding:$gutter*4 $gutter*2 $gutter*4;
  .heading{
    &-5{
      color:$white;
      @include etype(6);
    }
    &-1{
      color:#b3b3b3;
      @include etype(8);
    }
  }
}